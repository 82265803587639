<ng-container *ngIf="(selectedCompanyLoading$ | async) === false; else showLoader">
	<ng-container *ngIf="selectedCompany$ | async as selectedCompany">
		<div
			[ngStyle]="{ 'background-image': 'url(' + selectedCompany.styles[customizationKeys.LoginPageBg] + ')' }"
			class="auth expired-password"
		>
			<div class="auth-container">
				<div
					[style.backgroundImage]="
						selectedCompany.styles[customizationKeys.LoginPageLogo] || selectedCompany.logoUrl | cssSrc
					"
					class="company-logo"
				></div>
				<div class="auth-content">
					<div class="expired-password-title">{{ 'expiredPassword.title' | translate }}</div>

					<app-expired-password-form
						(updatePassword)="onUpdatePassword($event)"
						[email]="email"
					></app-expired-password-form>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>

<ng-template #showLoader>
	<app-waiting-spinner></app-waiting-spinner>
</ng-template>
