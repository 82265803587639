import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { StorageKeys, StorageService } from '@shared/services/storage.service';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CompanyApiService } from '../../services/company-api.service';
import { loadCompany, loadCompanyFail, loadCompanySuccess } from '../actions/company.actions';

@Injectable()
export class CompanyEffects {
	getCompany$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadCompany),
			mergeMap((action) =>
				this.companyService.getCompany(action.payload).pipe(
					map((company) => {
						this.storageService.set(StorageKeys.CachedCompanyTime, Date.now());
						return loadCompanySuccess({ payload: company });
					}),
					catchError((error: Error) => {
						this.router.navigate(['/', 'not-found']);
						return of(loadCompanyFail({ error }));
					})
				)
			)
		)
	);

	setCompany$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(loadCompanySuccess),
				tap((action) => {
					this.storageService.set(StorageKeys.CompanyInfo, action.payload);
				})
			),
		{ dispatch: false }
	);

	constructor(
		private actions$: Actions,
		private companyService: CompanyApiService,
		private storageService: StorageService,
		private router: Router
	) {}
}
