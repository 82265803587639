import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { BankIdErrorsDictionary } from '@shared/dictionaries/bankid-errors.dictionary';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';

@Component({
	selector: 'app-bankid-login',
	templateUrl: './bankid-login.component.html',
	styleUrls: ['./bankid-login.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BankIdLoginComponent {
	@Input()
	selectedCompany: CompanyInterface;

	@Input()
	qrCodePath: SafeResourceUrl;

	@Input()
	actionResultAvailable: boolean;

	@Input()
	showGoBack = true;

	@Input()
	qrCodeErrorCode = null;

	@Output()
	signInWithTheSameDevice = new EventEmitter<number>();

	@Output()
	signInWithQr = new EventEmitter<number>();

	@Output()
	cancel = new EventEmitter<void>();

	@Output()
	goBack = new EventEmitter<void>();

	readonly bankIdErrorsDictionary = new BankIdErrorsDictionary();
}
