import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChangePasswordDto } from '@auth/interfaces/expired-password.interface';
import { ForgotPasswordDto, ForgotPasswordInitializeDto } from '@auth/interfaces/forgot-password.interface';
import { RegistrationDto } from '@auth/interfaces/registration-dto.interface';
import { Token } from '@auth/interfaces/token.interface';
import { AuthService } from '@auth/services/auth.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '@shared/services/navigation.service';
import { StorageKeys, StorageService } from '@shared/services/storage.service';
import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { from, Observable, of } from 'rxjs';
import { UserInfo } from '../../models/user-info.model';
import { BankIdInitResult } from '../interfaces/bankid-init-result.interface';
import { LoginCredentials } from '../interfaces/login-credentials.interface';
import { BankIdGetQrResult } from '@auth/interfaces/bankid-get-qr-result.interface';
import { BankIdCheckStatusResult } from '@auth/interfaces/bankid-check-status-result.interface';
import { BankIdCompletionData } from '@auth/interfaces/bankid-completion-data.interface';

@Injectable()
export class AuthApiService {
	baseUrl = environment.baseURI;

	constructor(
		private authService: AuthService,
		private oauthService: OAuthService,
		private http: HttpClient,
		private translateService: TranslateService,
		private storageService: StorageService,
		private notificationService: NotificationService,
		private navigationService: NavigationService
	) {}

	registerByOrderRef(body: RegistrationDto): Observable<string> {
		return this.http.post<string>(`${this.baseUrl}/user/RegisterByPersonalNumberOrOrderRef`, body);
	}

	login(credentials: LoginCredentials): Observable<any> {
		this.authService.removeUserInfo();
		this.clearQueryParams();

		return from(
			this.oauthService
				.fetchTokenUsingPasswordFlowAndLoadUserProfile(credentials.email, credentials.password)
				.then(() => {
					this.authService.init();
					this.authService.scheduleRefresh();
				})
		);
	}

	initForBankIdLogin(useSameDevice: boolean, useQrCode: boolean, redirectUrl: string): Observable<BankIdInitResult> {
		const postData = {
			useSameDevice,
			useQrCode,
			redirectUrl,
		};

		return this.http.post<BankIdInitResult>(`${environment.identityURI}/api/bankId/initialize`, postData);
	}
	getQR(qrStartState: string): Observable<BankIdGetQrResult> {
		const postData = {
			qrStartState,
		};
		return this.http.post<BankIdGetQrResult>(`${environment.identityURI}/api/bankId/getQR`, postData);
	}
	checkStatus(orderRef): Observable<BankIdCheckStatusResult> {
		const postData = {
			orderRef,
		};
		return this.http.post<BankIdCheckStatusResult>(`${environment.identityURI}/api/bankId/CheckOrderRef`, postData);
	}
	cancelBankIdLogin(orderRef: string): Observable<void> {
		this.clearQueryParams();
		const postData = {
			orderRef,
		};
		return this.http.post<void>(`${environment.identityURI}/api/bankId/cancel`, postData);
	}

	bankIdLogin(
		companyId: number,
		orderRef: string,
		redirectUrl: string,
		completionData?: BankIdCompletionData
	): Observable<Token> {
		this.authService.removeUserInfo();
		this.oauthService.customQueryParams = {
			grant_type: 'bank_id',
			tenant_id: companyId,
			order_ref: orderRef,
			comp_data: completionData ? JSON.stringify(completionData) : null,
			redirectUrl,
		};
		if (!completionData) {
			delete this.oauthService.customQueryParams['comp_data'];
		}
		return from(
			this.oauthService
				.fetchTokenUsingPasswordFlow('', '')
				.then((res: TokenResponse) => {
					this.oauthService.customQueryParams = null;
					this.authService.init();
					this.authService.scheduleRefresh();

					return res;
				})
				.catch((err) => {
					this.clearQueryParams();
					if (err.error?.error_description === 'User not registered') {
						this.notificationService.error({
							message: this.translateService.instant('login.error.userNotRegistered'),
						});

						this.navigationService.navigateTo(['auth', 'registration']);
					}
					return err.error;
				})
		);
	}

	getUserInfo(): Observable<UserInfo> {
		if (this.storageService.get(StorageKeys.UserInfo)) {
			return of(this.storageService.get(StorageKeys.UserInfo));
		} else {
			return this.http.get<UserInfo>(`${this.baseUrl}/user/getuserinfo`);
		}
	}

	logout(): void {
		this.authService.logout();
	}

	sendEmailToResetPassword(body: ForgotPasswordInitializeDto): Observable<void> {
		return this.http.post<void>(`${this.baseUrl}/user/forgotpasswordinitialize`, body);
	}

	resetPassword(body: ForgotPasswordDto): Observable<void> {
		return this.http.post<void>(`${this.baseUrl}/user/forgotpassword`, body);
	}

	updateExpiredPassword(body: ChangePasswordDto): Observable<void> {
		return this.http.post<void>(`${this.baseUrl}/user/changepassword`, body);
	}

	private clearQueryParams(): void {
		this.oauthService.customQueryParams = null;
	}
}
