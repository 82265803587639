<div class="modal-title" mat-dialog-title>{{ data.title }}</div>

<div mat-dialog-content>
	<div class="confirmation-text">{{ data.text }}</div>

	<app-bankid-login
		(cancel)="onCancel()"
		(signInWithQr)="onSignInWithQr()"
		(signInWithTheSameDevice)="onSignInWithTheSameDevice()"
		[actionResultAvailable]="actionResultAvailable$ | async"
		[qrCodePath]="qrCodePath$ | async"
		[showGoBack]="false"
		[qrCodeErrorCode]="qrCodeErrorCode$ | async"
	></app-bankid-login>
</div>

<button (click)="onClose()" class="close-modal" mat-flat-button>
	<mat-icon>close</mat-icon>
</button>
