import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeSv from '@angular/common/locales/sv';
import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth/auth.module';
import { CacheInterceptor } from '@core/interceptors/cache.interceptor';
import { GoogleAnalyticsService } from '@core/services/google-analytics-service';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NguCarouselModule } from '@ngu/carousel';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UrlParserService } from '@shared/services/url-parser.service';
import { SharedModule } from '@shared/shared.module';
import { OAuthConfig } from '@shared/utilities/oauth-config';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgxMaskModule } from 'ngx-mask';
import { LayoutModule } from '../layout/layout.module';
import { AdminModule } from '../modules/admin/admin.module';
import { CompanyModule } from '../modules/company/company.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CustomTranslateHttpLoader } from './services/custom-translate-loader';
import { ErrorHandlerService } from './services/error-handler.service';
import { NotificationService } from './services/notification.service';

export function initOAuth(oAuthConfig: OAuthConfig): Function {
	return () => oAuthConfig.load();
}

export function createTranslateLoader(http: HttpClient, urlParserService: UrlParserService) {
	return new CustomTranslateHttpLoader(http, urlParserService);
}

registerLocaleData(localeEn, 'en');
registerLocaleData(localeSv, 'sv');

@NgModule({
	declarations: [NotificationComponent, NotFoundComponent],
	imports: [
		CommonModule,
		MatSnackBarModule,
		MatIconModule,
		BrowserModule,
		BrowserAnimationsModule,
		NguCarouselModule,
		MatToolbarModule,
		MatExpansionModule,
		MatTooltipModule,
		MatTableModule,
		LayoutModule,
		AuthModule,
		CompanyModule,
		AdminModule,
		HttpClientModule,
		NgxMaskModule.forRoot(),
		OAuthModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient, UrlParserService],
			},
			isolate: false,
		}),
		EffectsModule.forRoot([]),
		StoreModule.forRoot(
			{},
			{
				runtimeChecks: {
					strictStateImmutability: true,
					strictActionImmutability: true,
				},
			}
		),
		environment.production ? [] : StoreDevtoolsModule.instrument(),
		SharedModule,
	],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		if (parentModule) {
			throw new Error('Core module is already loaded. Import only in AppModule');
		}
	}

	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				{ provide: ErrorHandler, useClass: ErrorHandlerService },
				{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
				{ provide: APP_INITIALIZER, useFactory: initOAuth, deps: [OAuthConfig], multi: true },
				OAuthConfig,
				NotificationService,
				GoogleAnalyticsService,
			],
		};
	}
}
