<form [errorMessages]="errorMessages" [formGroup]="form" appFormValidation class="form">
	<mat-form-field
		appearance="outline"
		class="form-field"
		#passTooltip="matTooltip"
		(click)="passTooltip.toggle()"
		matTooltip="{{ 'registration.form.passwordHint' | translate }}"
		matTooltipPosition="above"
	>
		<mat-icon class="form-icon prefix-icon" matPrefix>lock</mat-icon>
		<mat-label>{{ 'registration.form.password' | translate }}</mat-label>
		<input [type]="hidePassword ? 'password' : 'text'" formControlName="password" matInput />
		<button
			(click)="hidePassword = !hidePassword"
			[attr.aria-label]="'Hide password'"
			[attr.aria-pressed]="hidePassword"
			mat-icon-button
			matSuffix
			type="button"
		>
			<mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
		</button>
		<mat-error>{{ form | error : 'password' | translate }}</mat-error>
	</mat-form-field>

	<mat-form-field appearance="outline" class="form-field">
		<mat-icon class="form-icon prefix-icon" matPrefix>lock</mat-icon>
		<mat-label>{{ 'registration.form.passwordConfirmation' | translate }}</mat-label>
		<input [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="confirmPassword" matInput />
		<button
			(click)="hideConfirmPassword = !hideConfirmPassword"
			[attr.aria-label]="'Hide password'"
			[attr.aria-pressed]="hideConfirmPassword"
			mat-icon-button
			matSuffix
			type="button"
		>
			<mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
		</button>
		<mat-error>{{ form | error : 'confirmPassword' | translate }}</mat-error>
	</mat-form-field>

	<div class="form-actions">
		<a [routerLink]="['auth', 'login'] | routerLink" class="large" mat-raised-button>
			<mat-icon>chevron_left</mat-icon>
			{{ 'login.back' | translate }}
		</a>
		<button
			(click)="submit()"
			[disabled]="form.invalid || form.pristine"
			class="large"
			color="primary"
			mat-raised-button
			type="submit"
		>
			{{ 'registration.form.confirm' | translate }}
			<mat-icon>login</mat-icon>
		</button>
	</div>
</form>
