import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
	constructor(private translateService: TranslateService, private decimalPipe: DecimalPipe) {}

	transform(value: any, digitsInfo?: string): string {
		if (!value) {
			return;
		}

		const decimal = this.decimalPipe.transform(value, digitsInfo) + '';
		const removeCommas = decimal.replace(/\,/g, ' ');
		const format = removeCommas.replace(/(?!^)(?=(?:\d{3})+$)/g, ' ');
		return format.replace(/\./g, ',');
	}
}
