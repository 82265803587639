import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, share, tap } from 'rxjs/operators';
import { CompanyInterface } from '../interfaces/company.interface';
import { State } from '../store';
import { loadCompany, loadCompanySuccess } from '../store/actions/company.actions';
import { selectCompany } from '../store/selectors/company.selectors';

@Injectable()
export class CompanySandboxService {
	constructor(private store: Store<State>) {}

	loadCompany(companyName: string) {
		return this.store.select(selectCompany).pipe(
			tap(() => {
				this.store.dispatch(loadCompany({ payload: companyName }));
			}),
			filter((company) => !!company),
			map((company) => company)
		);
	}

	getCompany(): Observable<CompanyInterface> {
		return this.store.select(selectCompany).pipe(
			filter((company) => !!company),
			map((company) => company),
			share()
		);
	}

	setCompany(company: CompanyInterface) {
		this.store.dispatch(loadCompanySuccess({ payload: company }));
	}
}
