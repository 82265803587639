import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
	public static englishLettersOnlyValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const value = control.value;

			if (!value) {
				return null;
			}

			const valid = regex.test(control.value);
			return valid ? null : error;
		};
	}

	public static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;

			if (!value) {
				return null;
			}

			const valid = regex.test(value);

			return valid ? null : error;
		};
	}

	public static clearingNumberValidator(clearingNumberFormat: number): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } => {
			const value = control.value;
			const isNumber = /^\d+$/.test(value);

			if (!isNumber) {
				return { format: { value } };
			}

			if (value.startsWith('8') && clearingNumberFormat === 5) {
				if (value.length !== 5) {
					return { maxlength: { value } };
				}
			} else {
				if (value.length !== 4) {
					return { maxlength: { value } };
				}
			}

			return null;
		};
	}

	static passwordMatchValidator(control: AbstractControl): void {
		const password = control.get('password').value;
		const confirmPassword = control.get('confirmPassword').value;

		if (password === confirmPassword) {
			control.get('confirmPassword').setErrors(null);
		}

		if (password !== confirmPassword) {
			control.get('confirmPassword').setErrors({ noPasswordMatch: true });
		}
	}
}
