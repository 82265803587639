import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private oauthService: OAuthService, private navigationService: NavigationService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = this.oauthService.getAccessToken();
		let headers = {};
		if (token) {
			headers = {
				Authorization: `Bearer ${token}`,
			};
		}
		const authReq = req.clone({ setHeaders: headers });
		return next.handle(authReq).pipe(
			filter((e) => e.type !== 0),
			tap(
				() => {},
				(event) => {
					//   // check for the HttpErrorResponse event.
					if (event instanceof HttpErrorResponse && event.status === 401) {
						this.navigationService.navigateTo(['auth']);
					}
				}
			)
		);
	}
}
