import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authReducer, AuthState } from './reducers/auth.reducer';

export const authFeatureKey = 'auth';

export interface State {
	auth: AuthState;
}

export const reducers = {
	auth: authReducer,
};

export const selectAuthDetails = createFeatureSelector<State>(authFeatureKey);
export const selectAuthState = createSelector(selectAuthDetails, (state: State) => state && state.auth);
