import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BankIdLoginComponent } from '@shared/components/bankid-login/bankid-login.component';
import { ContractStatusesComponent } from '@shared/components/contract-statuses/contract-statuses.component';
import { WaitingSpinnerComponent } from '@shared/components/waiting-spinner/waiting-spinner.component';
import { FormValidationDirective } from '@shared/directives/form-validatiom.directive';
import { MarkAllAsTouchedOnSubmitDirective } from '@shared/directives/mark-all-as-touched-on-submit.directive';
import { NumberDirective } from '@shared/directives/numbers-only.directive';
import { ErrorPipe } from '@shared/pipe/error.pipe';
import { NotificationsDate } from '@shared/pipe/notifications-date.pipe';
import { SafePipe } from '@shared/pipe/safe.pipe';
import { TrustHtmlPipe } from '@shared/pipe/trust-html.pipe';
import { ColorsService } from '@shared/services/colors.service';
import { FileService } from '@shared/services/file.service';
import { NavigationService } from '@shared/services/navigation.service';
import { StorageService } from '@shared/services/storage.service';
import { UrlParserService } from '@shared/services/url-parser.service';
import { ChartsModule } from 'ng2-charts';
import { BackgroundVideoComponent } from './components/background-video/background-video.component';
import { BackgroundComponent } from './components/background/background.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DocumentsTableComponent } from './components/documents-table/documents-table.component';
import { EmptyComponent } from './components/empty/empty.component';
import { LoadMoreComponent } from './components/load-more/load-more.component';
import { SingleVideoComponent } from './components/single-video/single-video.component';
import { SupportEmailComponent } from './components/support-email/support-email.component';
import { TreesDoughnutChartComponent } from './components/trees-doughnut-chart/trees-doughnut-chart.component';
import { WINDOW_TOKEN } from './constants/injection-tokens';
import { HeadingColorDirective } from './directives/heading-color.directive';
import { ViewportWatchDirective } from './directives/viewport-watch.directive';
import { ConditionalNumberPipe } from './pipe/conditional-number.pipe';
import { CssSrcPipe } from './pipe/css-src.pipe';
import { FormatNumberPipe } from './pipe/format-number.pipe';
import { RouterLinkPipe } from './pipe/router-link.pipe';

@NgModule({
	declarations: [
		SupportEmailComponent,
		BackgroundComponent,
		BackgroundVideoComponent,
		TreesDoughnutChartComponent,
		EmptyComponent,
		ConfirmationModalComponent,
		LoadMoreComponent,
		DocumentsTableComponent,
		FormatNumberPipe,
		CssSrcPipe,
		RouterLinkPipe,
		ConditionalNumberPipe,
		ViewportWatchDirective,
		HeadingColorDirective,
		WaitingSpinnerComponent,
		SafePipe,
		SingleVideoComponent,
		FormValidationDirective,
		ErrorPipe,
		ContractStatusesComponent,
		BankIdLoginComponent,
		TrustHtmlPipe,
		NotificationsDate,
		MarkAllAsTouchedOnSubmitDirective,
		NumberDirective,
	],
	imports: [
		CommonModule,
		MatCardModule,
		MatGridListModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		MatTableModule,
		MatPaginatorModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		MatTabsModule,
		MatSelectModule,
		MatChipsModule,
		MatDialogModule,
		ChartsModule,
		MatProgressBarModule,
		MatTooltipModule,
		MatExpansionModule,
		MatDatepickerModule,
		MatNativeDateModule,
	],
	exports: [
		CommonModule,
		MatCardModule,
		MatGridListModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		MatMenuModule,
		MatTableModule,
		MatPaginatorModule,
		MatDatepickerModule,
		MatNativeDateModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		MatTabsModule,
		MatSelectModule,
		SupportEmailComponent,
		BackgroundComponent,
		BackgroundVideoComponent,
		TreesDoughnutChartComponent,
		EmptyComponent,
		ConfirmationModalComponent,
		LoadMoreComponent,
		DocumentsTableComponent,
		FormatNumberPipe,
		CssSrcPipe,
		RouterLinkPipe,
		ConditionalNumberPipe,
		ViewportWatchDirective,
		HeadingColorDirective,
		WaitingSpinnerComponent,
		SafePipe,
		SingleVideoComponent,
		FormValidationDirective,
		ErrorPipe,
		ContractStatusesComponent,
		BankIdLoginComponent,
		TrustHtmlPipe,
		NotificationsDate,
		MarkAllAsTouchedOnSubmitDirective,
		NumberDirective,
	],
	providers: [
		ColorsService,
		NavigationService,
		FileService,
		StorageService,
		UrlParserService,
		{
			provide: WINDOW_TOKEN,
			useValue: window,
		},
		DecimalPipe,
		FormatNumberPipe,
	],
})
export class SharedModule {}
