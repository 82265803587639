<app-user-list
	*ngIf="(users$ | async).length; else empty"
	(delegateUser)="onDelegateUser($event)"
	(deleteUser)="onDeleteUser($event)"
	(editUser)="onEditUser($event)"
	(loadUsers)="getUsers()"
	(registerUser)="onRegisterUser()"
	(syncVacsUsers)="syncVacsUsers()"
	[users]="users$ | async"
></app-user-list>

<ng-template #empty>
	<ng-container *ngIf="(loading$ | async) === false; else showLoader">
		<app-empty [value]="'noUsers' | translate"></app-empty>
	</ng-container>
</ng-template>

<ng-template #showLoader>
	<app-waiting-spinner></app-waiting-spinner>
</ng-template>
