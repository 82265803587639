import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ForgotPasswordInitializeDto } from '@auth/interfaces/forgot-password.interface';
import { TranslateService } from '@ngx-translate/core';
import { FormErrorMessages } from '@shared/interfaces/form-error-messages.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';
import { LoginCredentials } from '../../interfaces/login-credentials.interface';

@Component({
	selector: 'app-login-form',
	templateUrl: 'login-form.component.html',
	styleUrls: ['login-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
	@Input()
	errors: any[] = [];

	@Input()
	loading: boolean;

	@Input()
	selectedCompany: CompanyInterface;

	@Output()
	signIn = new EventEmitter<LoginCredentials>();

	@Output()
	goBack = new EventEmitter<void>();

	@Output()
	sendEmailToResetPassword = new EventEmitter<ForgotPasswordInitializeDto>();

	destroy$ = new Subject();
	signinForm: FormGroup;
	forgotPasswordForm: FormGroup;
	hidePassword = true;
	isForgotPassword = false;

	readonly errorMessages: FormErrorMessages = {
		email: {
			required: 'common.form.errors.required',
		},
		password: {
			required: 'common.form.errors.required',
		},
	};

	constructor(private translate: TranslateService) {}

	ngOnInit(): void {
		this.initSignInForm();
		this.initForgotPasswordForm();

		this.signinForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
			this.errors = [];
		});
	}

	keyDown(event, type: string): void {
		if (event.keyCode === 13) {
			if (type === 'login') {
				this.submit();
			}
			if (type === 'forgot-password') {
				this.submitForgotPassword();
			}
		}
	}

	submit(): void {
		if (this.signinForm.invalid) {
			return;
		}
		this.signIn.emit(this.signinForm.value);
	}

	submitForgotPassword(): void {
		if (this.forgotPasswordForm.invalid) {
			return;
		}

		const data: ForgotPasswordInitializeDto = {
			...this.forgotPasswordForm.value,
			emailOptions: {
				companyId: this.selectedCompany.id,
				companyName: this.selectedCompany.name,
				cultureCode: this.translate.currentLang || 'en',
				application: 'Stakeholder',
			},
		};

		this.sendEmailToResetPassword.emit(data);
		this.isForgotPassword = false;
		this.forgotPasswordForm.reset();
	}

	forgotPassword(): void {
		this.isForgotPassword = !this.isForgotPassword;
	}

	private initSignInForm(): void {
		this.signinForm = new FormGroup({
			email: new FormControl<string | null>(null, Validators.required),
			password: new FormControl<string | null>(null, Validators.required),
		});
	}

	private initForgotPasswordForm(): void {
		this.forgotPasswordForm = new FormGroup({
			email: new FormControl<string | null>(null, Validators.required),
		});
	}
}
