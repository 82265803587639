<h1>ANVÄNDARVILLKOR</h1>
<h2>ALLMÄNT</h2>
<p>
	Derome Skog AB, 556190-9564 tillhandahåller Stakeholder (”tjänsten”) till skogsägare (”Användare”) som är leverantör
	till Derome Skog AB.
</p>
<p>Användare måste acceptera samtycke för dessa användarvillkor för att kunna nyttja tjänsten.</p>
<h2>INLOGGNINGSUPPGIFTER</h2>
<p>
	Användarnamn och lösenord är personligt. Det åligger användaren att ansvara för att skydda sina personliga
	inloggningsuppgifter från användning av annan användare än sig själv.
</p>
<h2>FULLMAKTSHAVARE</h2>
<p>
	Användare med fullmaktshavare garanterar att Fullmaktshavaren har rätt att logga in och använda tjänsten, samt att
	utföra de åtgärder som finns att utföra i tjänsten.
</p>
<h2>TJÄNSTENS INNEHÅLL</h2>
<p>
	Detaljerad information om tjänstens innehåll finns att läsa på
	<a href="https://www.derome.se/skog-tra" target="_blank">www.derome.se/skog-tra</a>.
</p>
<p>
	Utvecklingsarbete i tjänsten förekommer i syfte att förbättra tjänsten, vilket kan medföra att innehåll i design och
	funktionalitet kan komma att ändras. Användare erbjuds möjlighet att använda aktuell version av tjänsten, med
	restriktion för att eventuella avbrott och störningar kan förekomma.
</p>
<h2>ANVÄNDNING AV TJÄNSTEN</h2>
<p>
	Tjänsten är avsedd för skogsägare som är leverantör till Derome Skog AB. Användare eller fullmaktshavare som upphör
	vara leverantör/fullmaktshavare eller skogsägare kan tjänsten komma att upphöra för.
</p>
<p>
	Medför användningen av tjänsten en risk för Derome Skog AB, användare eller någon annan äger Derome Skog AB rätt att
	avsluta eller begränsa tjänsten.
</p>
<p>Derome Skog AB äger rätt att utesluta användare som missbrukar eller inte följer användarvillkoren för tjänsten.</p>
<h2>UPPGIFTER I TJÄNSTEN</h2>
<p>
	Uppgifter för användare i tjänsten kan komma från flera ursprungskällor och/eller från Derome Skog ABs interna system.
	Strävan är att uppgifterna i tjänsten är korrekta och uppdaterade, dock tar Derome Skog AB inget ansvar utöver vad som
	följer lag. Upptäcker användare felaktiga uppgifter i tjänsten, vänligen kontakta Derome Skog AB.
</p>
<h2>KOMMUNIKATION VIA TJÄNSTEN</h2>
<p>
	Användaren av tjänsten får information om erbjudanden, evenemang och kampanjer från Derome Skog AB, samt om
	användarens affärsavtal med Derome Skog AB. Detta kan ske via e-post eller sms. Om användaren inte vill få utskick
	från Derome Skog AB kan användaren när som helst avbryta utskicken genom att gå in på avsnittet ”Aviseringar” på
	fliken ”Mina uppgifter” i tjänsten.
</p>
<h2>IMMATERIALRÄTT</h2>
<p>Derome Skog AB äger samtliga rättigheter, inklusive immateriella rättigheter, till tjänsten.</p>
<p>
	Det upphovsrättsligt skyddade material som finns tillgängligt i tjänsten får inte användas utan tillstånd från
	upphovsmannen. Användare tillåts således inte att kopiera, distribuera, kommersiellt exploatera, reproducera eller på
	annat sätt dra nytta från sådant material.
</p>
<h2>PERSONUPPGIFTER</h2>
<p>
	Derome Skog AB behandlar personuppgifter i enlighet med gällande regler om personuppgiftsbehandling.
	Personuppgiftshanteringen är en del i användarvillkor, mer information hur Derome Skog AB hanterar personuppgifter
	finns att läsa på
	<a href="https://www.derome.se/om-derome/derome-verksamheten/etik-integritet/integritetspolicy" target="_blank"
		>https://www.derome.se/om-derome/derome-verksamheten/etik-integritet/integritetspolicy</a
	>
</p>
<p>
	För avregistrering av personuppgifter, kontakta oss på:
	<a href="mailto:GDPR@derome.se" target="_blank">GDPR@derome.se</a>
</p>
<h2>ANSVARSBEGRÄNSNING</h2>
<p>
	Derome Skog AB garanterar inte att uppgifter, information eller data är fullständig, riktig eller uppdaterad.
	Information kan komma att ändras eller tas bort utan att meddelas. Det garanteras inte heller att tjänsten kommer
	fungera utan avbrott eller störningar.
</p>
<p>
	Användningen av tjänsten sker på egen risk. Derome Skog AB ansvarar inte i något fall för direkt eller indirekt skada,
	kostnad, förlust eller anspråk som kan uppkomma i anledning av användningen av tjänsten.
</p>
<h2>KONTAKT</h2>
<p>Vänligen kontakta Derome Skog AB för mer information gällande tjänsten.</p>
<p>Kontakta huvudkontoret 0340-66 64 20 eller din lokala virkesinköpare.</p>
