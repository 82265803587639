import { Pipe, PipeTransform } from '@angular/core';
import { NavigationService } from '../services/navigation.service';

@Pipe({
	name: 'routerLink',
})
export class RouterLinkPipe implements PipeTransform {
	constructor(public navigationService: NavigationService) {}

	public transform(value: string[]): string[] {
		return this.navigationService.getLinkPath(value);
	}
}
