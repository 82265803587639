import { createAction, props } from '@ngrx/store';
import { PostCategory } from '../../interfaces/post-category.interface';

export const loadAdminCategoriesList = createAction('[Admin Categories] Load admin categories list');

export const loadAdminCategoriesListSuccess = createAction(
	'[Admin Categories] Load admin categories list success',
	props<{ data: PostCategory[] }>()
);

export const loadAdminCategoriesListFail = createAction(
	'[Admin Categories] Load admin categories list fail',
	props<{ error: Error }>()
);

export const upsertAdminCategory = createAction(
	'[Admin Categories] Upsert admin category',
	props<{ data: PostCategory }>()
);

export const upsertAdminCategorySuccess = createAction(
	'[Admin Categories] Upsert admin category success',
	props<{ data: PostCategory }>()
);

export const upsertAdminCategoryFail = createAction(
	'[Admin Categories] Upsert admin category fail',
	props<{ error: Error }>()
);

export const deleteAdminCategory = createAction('[Admin Categories] Delete admin category', props<{ id: number }>());

export const deleteAdminCategorySuccess = createAction(
	'[Admin Categories] Delete admin category success',
	props<{ id: number }>()
);

export const deleteAdminCategoryFail = createAction(
	'[Admin Categories] Delete admin category fail',
	props<{ error: Error }>()
);
