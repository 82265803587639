<div class="bankid">
	<div *ngIf="!actionResultAvailable" class="bankid-actions">
		<button (click)="signInWithTheSameDevice.emit(selectedCompany?.id)" class="large" color="primary" mat-raised-button>
			{{ 'bankid-login.label.desktop' | translate }}
			<mat-icon aria-hidden="false" class="icon-color">desktop_windows</mat-icon>
		</button>
		<button (click)="signInWithQr.emit(selectedCompany?.id)" class="large" color="primary" mat-raised-button>
			{{ 'bankid-login.label.qr' | translate }}
			<mat-icon aria-hidden="false" class="icon-color">qr_code_2</mat-icon>
		</button>
		<button (click)="goBack.emit()" *ngIf="showGoBack" class="large" mat-raised-button>
			<mat-icon>chevron_left</mat-icon>
			{{ 'login.back' | translate }}
		</button>
	</div>

	<div *ngIf="actionResultAvailable && qrCodePath" class="bankid-qrcode">
		<ng-container *ngIf="!qrCodeErrorCode; else qrErrorMessage">
			<div class="bankid-qrcode-default-hint">{{ 'bankid-login.defaultQrHint' | translate }}</div>
			<img [src]="qrCodePath" alt="qr" />
		</ng-container>

		<button (click)="cancel.emit()" class="large" color="primary" mat-raised-button>
			{{ 'bankid-login.label.cancel' | translate }}
		</button>
	</div>
	<div *ngIf="actionResultAvailable && !qrCodePath" class="bankid-desktop">
		<span>{{ 'bankid-login.label.trying-to-start-app' | translate }}</span>
		<button (click)="cancel.emit()" class="large" color="primary" mat-raised-button>
			{{ 'bankid-login.label.cancel' | translate }}
		</button>
	</div>
</div>

<ng-template #qrErrorMessage>
	<div class="bankid-qrcode-error">
		{{ bankIdErrorsDictionary?.entries?.get(qrCodeErrorCode) | translate }}
	</div>
</ng-template>
