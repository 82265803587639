<div class="pagination-wrap">
	<button
		*ngIf="showPrevNext"
		mat-mini-fab
		color="primary"
		(click)="loadPrev.emit(pageNumber - 1)"
		[disabled]="pageNumber === 1"
	>
		<mat-icon>navigate_before</mat-icon>
	</button>

	<button
		mat-raised-button
		color="primary"
		class="pagination-more large"
		*ngIf="postsLength < totalItemsCount"
		[disabled]="pageNumber === pagesCount"
		(click)="loadMore.emit(postsLength + postsLength)"
	>
		{{ 'loadMore' | translate }}
	</button>

	<button
		mat-raised-button
		color="primary"
		class="pagination-more large"
		*ngIf="postsLength === totalItemsCount"
		(click)="resetPages.emit()"
	>
		{{ 'reset' | translate }}
	</button>

	<button
		*ngIf="showPrevNext"
		mat-mini-fab
		color="primary"
		(click)="loadNext.emit(pageNumber + 1)"
		[disabled]="pageNumber === pagesCount"
	>
		<mat-icon>navigate_next</mat-icon>
	</button>
</div>
