import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PostCategory } from '../../interfaces/post-category.interface';
import { FaqApiService } from '../../services/faq-api.service';
import {
	deleteAdminCategory,
	deleteAdminCategoryFail,
	deleteAdminCategorySuccess,
	loadAdminCategoriesList,
	loadAdminCategoriesListFail,
	loadAdminCategoriesListSuccess,
	upsertAdminCategory,
	upsertAdminCategoryFail,
	upsertAdminCategorySuccess,
} from '../actions/admin-categories.actions';

@Injectable()
export class AdminCategoriesEffects {
	readonly getAdminCategories$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadAdminCategoriesList),
			switchMap(() =>
				this.faqApiService.getPostCategories().pipe(
					map((data: PostCategory[]) => loadAdminCategoriesListSuccess({ data })),
					catchError((error: Error) => of(loadAdminCategoriesListFail({ error })))
				)
			)
		)
	);

	readonly upsertAdminCategory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(upsertAdminCategory),
			switchMap((payload) =>
				this.faqApiService.upsertPostCategory(payload.data).pipe(
					map((data) => upsertAdminCategorySuccess({ data })),
					tap(() =>
						this.notificationService.success({ message: this.translate.instant('notification_messages.category.save') })
					),
					catchError((error) => of(upsertAdminCategoryFail(error)))
				)
			)
		)
	);

	readonly deleteAdminCategory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteAdminCategory),
			switchMap(({ id }) =>
				this.faqApiService.deletePostCategory(id).pipe(
					map(() => deleteAdminCategorySuccess({ id })),
					tap(() =>
						this.notificationService.success({
							message: this.translate.instant('notification_messages.category.delete'),
						})
					),
					catchError((error) => of(deleteAdminCategoryFail(error)))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private faqApiService: FaqApiService,
		private notificationService: NotificationService,
		private translate: TranslateService
	) {}
}
