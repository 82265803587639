<mat-accordion>
	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>
				<mat-icon>filter_list</mat-icon>
				{{ 'contract.label.filters' | translate }}
			</mat-panel-title>
		</mat-expansion-panel-header>

		<div class="filter-wrap">
			<div [formGroup]="filtersForm" class="filter-items">
				<ng-container *ngFor="let column of resolutionFilteredColumns; let last = last; let index = index">
					<mat-form-field *ngIf="column.filter">
						<input
							formControlName="{{ column.name }}"
							matInput
							placeholder="{{ 'admin.userList.label' + '.' + column.name | translate }}"
						/>
					</mat-form-field>
				</ng-container>
			</div>
			<button (click)="clear()" class="medium" color="primary" mat-raised-button>
				{{ 'common.label.clear' | translate }}
			</button>
		</div>
	</mat-expansion-panel>
</mat-accordion>

<div class="table-wrapper">
	<div class="actions" *ngIf="isFullAdminUser()">
		<button (click)="registerNewUser()" class="medium" color="primary" mat-raised-button>
			{{ 'admin.userList.tooltip.register' | translate }}
			<mat-icon>person_add</mat-icon>
		</button>
	</div>

	<table [dataSource]="dataSource" [formGroup]="filtersForm" mat-table matSort>
		<ng-container matColumnDef="firstName">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'admin.userList.label.firstName' | translate }}</th>
			<td *matCellDef="let element" mat-cell>{{ element.firstName }}</td>
		</ng-container>
		<ng-container matColumnDef="lastName">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'admin.userList.label.lastName' | translate }}</th>
			<td *matCellDef="let element" mat-cell>{{ element.lastName }}</td>
		</ng-container>
		<ng-container matColumnDef="email">
			<th *matHeaderCellDef mat-header-cell mat-sort-header>{{ 'admin.userList.label.email' | translate }}</th>
			<td *matCellDef="let element" mat-cell>{{ element.email }}</td>
		</ng-container>
		<ng-container matColumnDef="actions">
			<th *matHeaderCellDef mat-header-cell [hidden]="!isFullAdminUser()">
				{{ 'admin.userList.label.actions' | translate }}
			</th>
			<td *matCellDef="let element" class="actions-cell" mat-cell [hidden]="!isFullAdminUser()">
				<mat-icon (click)="edit(element)" *ngIf="!element.isMoreThanOneCompany" class="row-button">edit</mat-icon>
				<mat-icon (click)="delete(element)" class="row-button">delete</mat-icon>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="resolutionFilteredColumnNames" mat-header-row></tr>
		<tr (click)="setDelegateUser(row)" *matRowDef="let row; columns: resolutionFilteredColumnNames" mat-row></tr>
	</table>
</div>

<mat-paginator
	[ngClass]="{ 'hide-paginator': !dataSource.data.length || dataSource.data.length < 5 }"
	[pageSizeOptions]="[5, 10, 15, 20, 30, 50]"
	[pageSize]="10"
	showFirstLastButtons
></mat-paginator>
