import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentTypesDictionary } from '@shared/dictionaries/document-types.dictionary';
import { DocumentTypes } from '@shared/enums/document-types.enum';
import { NotificationEventTypes } from '../../../modules/system-notificatios/enums/notification-event-types.enum';
import { SystemNotification } from '../../../modules/system-notificatios/interfaces/system-notification.interface';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';

@Component({
	selector: 'app-system-notifications-container',
	templateUrl: 'system-notifications-container.component.html',
	styleUrls: ['system-notifications-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemNotificationsContainerComponent {
	@Input()
	readonly companyInfo: CompanyInterface;

	@Input()
	readonly systemNotifications: SystemNotification[];

	@Output()
	readonly openNotification = new EventEmitter<SystemNotification>();

	@Output()
	readonly markAllAsRead = new EventEmitter<SystemNotification[]>();

	readonly notificationEventTypes = NotificationEventTypes;
	readonly documentTypes = DocumentTypes;
	readonly documentTypesDictionary = new DocumentTypesDictionary();

	readonly newContractCreatedIconDefaultColor = '#089000';
	readonly newContractStartedIconDefaultColor = '#089000';
	readonly newContractFileAddedIconDefaultColor = '#F89406';
	readonly newAvrFileAddedIconDefaultColor = '#F89406';
	readonly emptyNotificationsIconDefaultColor = '#063954';

	trackById(index: number, item: SystemNotification): number {
		return item.id;
	}
}
