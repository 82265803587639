import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { NavigationService } from '@shared/services/navigation.service';
import { MediaModalComponent } from '../../../faq/components/media-modal/media-modal.component';
import { Post } from '../../interfaces/post.interface';
import { State } from '../../store';
import { deletePost } from '../../store/actions/admin-posts.actions';

@Component({
	selector: 'app-post-list',
	templateUrl: './post-list.component.html',
	styleUrls: ['./post-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostListComponent {
	@Input()
	data: Post[] = [];

	@Input()
	loading: boolean;

	readonly postGraphicURI = environment.postGraphicURI;

	constructor(
		private navigationService: NavigationService,
		private router: Router,
		private dialog: MatDialog,
		private store: Store<State>,
		private translate: TranslateService
	) {}

	editPost(id: number, event: Event): void {
		event.stopPropagation();
		this.navigationService.navigateTo(['admin', 'post', `${id}`, 'edit']);
	}

	deletePost(id: number, event: Event): void {
		event.stopPropagation();

		this.dialog
			.open(ConfirmationModalComponent, {
				data: {
					confirmation: this.translate.instant('confirmationModal.deletePost.confirmation'),
					confirmButtonText: this.translate.instant('confirmationModal.deletePost.confirmButtonText'),
				},
				panelClass: 'confirmation-modal',
				autoFocus: false,
			})
			.afterClosed()
			.subscribe((res: boolean) => {
				if (res) {
					this.store.dispatch(deletePost({ id }));
				}
			});
	}

	openImage(type: string, src: string): void {
		this.openMedia(type, src);
	}

	openVideo({ type, src }): void {
		this.openMedia(type, src);
	}

	private openMedia(type: string, src: string): void {
		this.dialog.open(MediaModalComponent, {
			data: {
				type,
				src,
			},
			panelClass: 'media-modal',
			autoFocus: false,
		});
	}
}
