import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { State } from '@auth/store';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CustomizationKeys } from '../../../modules/admin/enums/customization-keys.enum';
import { CompanyNames } from '../../../modules/company/enums/company.enum';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';
import { selectCompany, selectCompanyLoading } from '../../../modules/company/store/selectors/company.selectors';

@Component({
	selector: 'app-privacy-page',
	templateUrl: 'privacy-page.component.html',
	styleUrls: ['privacy-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrivacyPageComponent {
	readonly selectedCompany$: Observable<CompanyInterface>;
	readonly selectedCompanyLoading$: Observable<boolean>;
	customizationKeys = CustomizationKeys;
	companyNames = CompanyNames;

	constructor(private route: ActivatedRoute, private store: Store<State>) {
		this.selectedCompany$ = this.store.pipe(select(selectCompany));
		this.selectedCompanyLoading$ = this.store.pipe(select(selectCompanyLoading));
	}
}
