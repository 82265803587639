import { Option } from '../interfaces/option.interface';

export abstract class DictionaryBase<T> {
	abstract readonly entries: Map<T, string>;
	protected options: Option<T>[];

	public optionsOf(): Option<T>[] {
		if (this.options) {
			return this.options;
		}

		this.entries.forEach((name: string, value: T) => (this.options = [...(this.options || []), { value, name }]));

		return this.options;
	}

	public valueOf(value: T): string {
		return this.entries.get(value) || null;
	}
}
