import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DelegatedUserService } from '@shared/services/delegated-user.service';
import { Observable } from 'rxjs';

@Injectable()
export class AdminInterceptor implements HttpInterceptor {
	constructor(private delegatedUserService: DelegatedUserService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const delegatedUser = this.delegatedUserService.getDelegatedUser();

		let headers = {};
		if (delegatedUser && delegatedUser.personalId) {
			headers = {
				'Personal-ID': delegatedUser.personalId,
			};
		}

		const clonedReq = req.clone({ setHeaders: headers });

		return next.handle(clonedReq);
	}
}
