<h1>ANVÄNDARVILLKOR</h1>
<h2>ALLMÄNT</h2>
<p>
	Moelven Skog AB, 556624-0957, tillhandahåller Skogsklok (”tjänsten”) till skogsägare (”användaren”) som är
	leverantörer till Moelven Skog AB.
</p>
<p>Användaren måste acceptera samtycke för dessa användarvillkor för att kunna nyttja tjänsten.</p>
<h2>INLOGGNINGSUPPGIFTER</h2>
<p>
	Tjänsten är avsedd för skogsägare och är tillgänglig så länge som skogsägaren är leverantör till Moelven Skog AB.
	Tjänsten skall kunna användas av skogsägaren och personer som representerar skogsägaren. Användarnamn och lösenord är
	personligt, och ska skyddas av användaren.
</p>
<h2>TJÄNSTENS INNEHÅLL</h2>
<p>
	Tjänsten omfattar digital åtkomst av information kopplad till användarens affärer med Moelven Skog AB, samt till
	användarens skogliga innehav. Nedan följer en detaljerad specifikation:
</p>
<ul>
	<li>Åtkomst av avräkningsdokument kopplade till användarens affärsavtal.</li>
	<li>Åtkomst av dokument kopplade till användarens affärsavtal.</li>
	<li>Visning av inmätta volymer för respektive avverkningsuppdrag och leveransvirkesavtal.</li>
	<li>Kontaktuppgifter till virkesköpare på Moelven Skog AB.</li>
	<li>Visning av Moelven Skog AB:s internt lagrade uppgifter om användaren.</li>
	<li>Visning och länkning av nyheter och information från Moelven Skog AB samt externa parter.</li>
	<li>Kartöversikt över användarens skogliga innehav.</li>
	<li>Kartöversikt över användarens skogsbruksplaner.</li>
	<li>
		Kartöversikt över aktiva avverkningsuppdrag med volymsuppgifter, samt positionering av skördaren och skotaren.
	</li>
</ul>
<p>
	Tjänsten kommer att utvecklas med syfte att förbättra den. Som en följd av detta kan innehåll vad gäller design och
	funktionalitet att ändras, vilket kan medföra avbrott och störningar i tjänsten.
</p>
<h2>UPPGIFTER I TJÄNSTEN</h2>
<p>
	Uppgifter för användare i tjänsten kan komma från flera ursprungskällor och från Moelven Skog AB:s interna system. Det
	kan förekomma att uppgifterna i tjänsten inte är fullständigt korrekta och uppdaterade. Upptäcker användare felaktiga
	uppgifter i tjänsten, vänligen kontakta Moelven Skog AB.
</p>
<h2>KOMMUNIKATION VIA TJÄNSTEN</h2>
<p>
	Användaren av tjänsten får information om erbjudanden, evenemang och kampanjer från Moelven Skog AB, samt om
	användarens affärsavtal med Moelven Skog AB. Detta kan ske via e-post eller sms. Om användaren inte vill få utskick
	från Moelven Skog AB kan användaren när som helst avbryta utskicken genom att gå in på avsnittet ”Aviseringar” på
	fliken ”Mina uppgifter” i tjänsten.
</p>
<h2>IMMATERIALRÄTT</h2>
<p>
	Moelven Skog AB äger samtliga rättigheter, inklusive immateriella rättigheter, till tjänsten, och det kan inte ges
	tillgång till tjänsten på annat sätt än genom denna tjänsten.
</p>
<h2>PERSONUPPGIFTER</h2>
<p>
	Moelven Skog AB behandlar personuppgifter i enlighet med gällande regler om personuppgiftsbehandling. Detta kommer
	endast gälla personuppgifter om kontaktpersoner hos skogsägarna. För mer information om behandling av personuppgifter,
	se Moelvens information om behandling av personuppgifter på
	<a href="https://www.moelven.com/se/behandling-av-personuppgifter" target="_blank"
		>www.moelven.com/se/behandling-av-personuppgifter</a
	>.
</p>
<h2>KONTAKT</h2>
<p>Vänligen kontakta Moelven Skog AB för mer information gällande tjänsten.</p>
<p>Kontakta huvudkontoret 010 122 65 00 eller din lokala virkesinköpare.</p>
