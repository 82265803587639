import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthApiService } from '@auth/services/auth-api.service';
import { NavigationService } from '@shared/services/navigation.service';
import { UrlParserService } from '@shared/services/url-parser.service';
import { take } from 'rxjs/operators';
import { CompanyNames } from '../enums/company.enum';
import { CompanyHelperService } from '../services/company-helper.service';
import { CompanySandboxService } from '../services/company-sandbox.service';

@Injectable()
export class CompanyInfoGuard implements CanActivate {
	constructor(
		private urlParserService: UrlParserService,
		private companySandboxService: CompanySandboxService,
		private companyHelperService: CompanyHelperService,
		private navigationService: NavigationService,
		private authService: AuthApiService
	) {}

	async canActivate() {
		try {
			const urlCompanyName = this.urlParserService.companyName;
			const { cachedCompany, isCachedCompanyOld } = this.companyHelperService;
			if (urlCompanyName) {
				if (cachedCompany) {
					if (cachedCompany.nameUrl === urlCompanyName) {
						if (isCachedCompanyOld) {
							return await this.loadCompany();
						} else {
							return this.setCachedCompany();
						}
					} else {
						this.authService.logout();
						return await this.loadCompany();
					}
				} else {
					return await this.loadCompany();
				}
			} else {
				if (cachedCompany) {
					if (isCachedCompanyOld) {
						return await this.loadCompany();
					} else {
						return this.setCachedCompany();
					}
				} else {
					return this.notFound();
				}
			}
		} catch (e) {
			return this.notFound();
		}
	}

	async loadCompany() {
		const { companyName } = this.urlParserService;
		if (
			CompanyNames.Moelven === companyName ||
			CompanyNames.JGA === companyName ||
			CompanyNames.Derome === companyName ||
			CompanyNames.KarlHedin === companyName ||
			CompanyNames.ConiferSoft === companyName
		) {
			await this.companySandboxService.loadCompany(companyName).pipe(take(1)).toPromise();
			return true;
		} else {
			this.notFound();
		}
	}

	setCachedCompany() {
		this.companySandboxService.setCompany(this.companyHelperService.cachedCompany);
		return true;
	}

	notFound() {
		this.navigationService.navigateNotFound();
		return false;
	}
}
