import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangePasswordDto } from '@auth/interfaces/expired-password.interface';
import { FormErrorMessages } from '@shared/interfaces/form-error-messages.interface';
import { CustomValidators } from '@shared/utilities/custom-validators.util';

@Component({
	selector: 'app-expired-password-form',
	templateUrl: 'expired-password-form.component.html',
	styleUrls: ['expired-password-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpiredPasswordFormComponent implements OnInit {
	@Input()
	email: string;
	@Output()
	updatePassword = new EventEmitter<ChangePasswordDto>();

	form: FormGroup;
	hidePassword = true;
	hideConfirmPassword = true;
	hideOldPassword = true;

	readonly errorMessages: FormErrorMessages = {
		email: {
			required: 'common.form.errors.required',
			pattern: 'common.form.errors.invalidEmailFormat',
		},
		oldPassword: {
			required: 'common.form.errors.required',
		},
		password: {
			required: 'common.form.errors.required',
			hasNumber: 'common.form.errors.hasNumber',
			hasCapitalCase: 'common.form.errors.hasCapitalCase',
			hasSmallCase: 'common.form.errors.hasSmallCase',
			hasSpecialCharacters: 'common.form.errors.hasSpecialCharacters',
			minlength: 'common.form.errors.passwordMinLength',
			noBlancSpace: 'common.form.errors.noBlancSpace',
			englishLettersOnly: 'common.form.errors.englishLettersOnly',
		},
		confirmPassword: {
			required: 'common.form.errors.required',
			noPasswordMatch: 'common.form.errors.noPasswordMatch',
		},
	};

	constructor(private fb: FormBuilder) {
		this.initForm();
	}

	submit(): void {
		if (this.form.invalid) {
			return;
		}

		const { email, oldPassword, password: newPassword } = this.form.getRawValue();

		const data: ChangePasswordDto = {
			email,
			oldPassword,
			newPassword,
		};

		this.updatePassword.emit(data);
	}

	private initForm(): void {
		this.form = this.fb.group(
			{
				email: new FormControl<string | null>({ value: null, disabled: true }, [
					Validators.required,
					Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
				]),
				oldPassword: new FormControl<string | null>(null, Validators.required),
				password: new FormControl<string | null>(null, [
					Validators.required,
					CustomValidators.patternValidator(/^\S*$/, {
						noBlancSpace: true,
					}),
					CustomValidators.englishLettersOnlyValidator(/^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9\s-]+$/, {
						englishLettersOnly: true,
					}),
					CustomValidators.patternValidator(/\d/, {
						hasNumber: true,
					}),
					CustomValidators.patternValidator(/[A-Z]/, {
						hasCapitalCase: true,
					}),
					CustomValidators.patternValidator(/[a-z]/, {
						hasSmallCase: true,
					}),
					CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
						hasSpecialCharacters: true,
					}),
					Validators.minLength(8),
				]),
				confirmPassword: new FormControl<string | null>(null, Validators.required),
			},
			{
				validators: CustomValidators.passwordMatchValidator,
			}
		);
	}

	ngOnInit() {
		this.form.controls['email'].setValue(this.email);
	}
}
