<div *ngIf="onlySpinner && !percentDone" class="spinner"></div>

<div *ngIf="!onlySpinner && !percentDone" class="shade">
	<div class="spinner"></div>
</div>

<div *ngIf="percentDone && onlySpinner" class="spinner-message">
	<div class="spinner"></div>
	<span>Uploading: {{ percentDone }}</span>
</div>
