import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
	name: 'error',
	pure: false,
})
export class ErrorPipe implements PipeTransform {
	transform(form: FormGroup, formControlName: string): string {
		const ctrl = form.get(formControlName);

		if (!ctrl || !ctrl.errors) {
			return null;
		}

		return ctrl.errors[formControlName] || null;
	}
}
