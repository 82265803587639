export const leadingZeroRegexp = new RegExp(/^\+*0*(?!\.|$)/);

export const amountPatterns = {
	current: {
		FORMATTERS: {
			DECIMAL_SEPARATOR: '',
			THOUSAND_SEPARATOR: '',
			SPACE_SEPARATOR: ' ',
			MODEL_DECIMAL_SEPARATOR: '',
		},
	},
	en: {
		FORMATTERS: {
			DECIMAL_SEPARATOR: '.',
			THOUSAND_SEPARATOR: ',',
			SPACE_SEPARATOR: ' ',
			MODEL_DECIMAL_SEPARATOR: '.',
		},
	},
	sv: {
		FORMATTERS: {
			DECIMAL_SEPARATOR: ',',
			THOUSAND_SEPARATOR: ' ',
			SPACE_SEPARATOR: ' ',
			MODEL_DECIMAL_SEPARATOR: '.',
		},
	},
};
