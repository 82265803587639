<header [ngStyle]="{ 'background-color': headerBgColor, color: headerTextColor }" id="header">
	<div
		(click)="navigateToCompany()"
		[style.backgroundImage]="logo | cssSrc"
		class="company-logo background-image"
	></div>
	<div [matMenuTriggerFor]="notifications" class="system-notifications">
		<mat-icon
			[matBadge]="unreadNotificationsLength$ | async"
			[matBadgeHidden]="(unreadNotificationsLength$ | async) === 0"
			matBadgeSize="small"
			matBadgePosition="below after"
		>
			notifications_active
		</mat-icon>
	</div>
	<mat-menu #notifications="matMenu" class="notifications-menu">
		<app-system-notifications-container
			[companyInfo]="companyInfo"
			[systemNotifications]="systemNotifications$ | async"
			(openNotification)="openNotification($event)"
			(markAllAsRead)="markAllAsRead($event)"
		></app-system-notifications-container>
	</mat-menu>
	<nav
		[ngClass]="{ active: isActive }"
		[ngStyle]="{ 'background-color': headerBgColor, color: headerTextColor }"
		class="navigation"
	>
		<ul>
			<li *ngIf="delegatedUserName">
				<a (click)="closeMenu()" class="nav-item user">
					<mat-icon class="icon-left">account_circle</mat-icon>
					{{ delegatedUserName }}
					<mat-icon (click)="removeDeligatedUser()" class="icon-right">clear</mat-icon>
				</a>
			</li>
			<li *ngIf="isAdmin">
				<a
					(click)="closeMenu()"
					[routerLink]="['admin'] | routerLink"
					class="nav-item"
					routerLinkActive="nav-item-active"
				>
					<mat-icon class="icon-left">contact_page</mat-icon>
					{{ 'sidenav.label.admin' | translate }}
				</a>
			</li>
			<ng-container *ngFor="let link of navLinks">
				<li *ngIf="isDeligatedUser(link.permissions)">
					<a
						(click)="closeMenu()"
						[routerLink]="[link.path] | routerLink"
						class="nav-item"
						routerLinkActive="nav-item-active"
					>
						<ng-container *ngIf="link.name">
							<mat-icon class="icon-left">{{ link.icon }}</mat-icon>
							{{ 'sidenav.label.' + link.path | translate }}
						</ng-container>
					</a>
				</li>
			</ng-container>
			<li>
				<a
					(click)="logout(); closeMenu()"
					[routerLink]="['logout'] | routerLink"
					class="nav-item"
					routerLinkActive="nav-item-active"
				>
					<mat-icon class="icon-left">logout</mat-icon>
					{{ 'sidenav.label.logout' | translate }}
				</a>
			</li>
		</ul>
	</nav>
	<div (click)="toggleMenu()" [ngClass]="{ active: isActive }" class="burger">
		<svg height="100" version="1.1" viewBox="0 0 100 100" width="100">
			<path [ngStyle]="{ stroke: headerTextColor }" class="line line1" d="M 50,35 H 30" />
			<path [ngStyle]="{ stroke: headerTextColor }" class="line line2" d="M 50,35 H 70" />
			<path [ngStyle]="{ stroke: headerTextColor }" class="line line3" d="M 50,50 H 30" />
			<path [ngStyle]="{ stroke: headerTextColor }" class="line line4" d="M 50,50 H 70" />
			<path [ngStyle]="{ stroke: headerTextColor }" class="line line5" d="M 50,65 H 30" />
			<path [ngStyle]="{ stroke: headerTextColor }" class="line line6" d="M 50,65 H 70" />
		</svg>
		<svg class="x" height="100" version="1.1" viewBox="0 0 100 100" width="100">
			<path [ngStyle]="{ stroke: headerTextColor }" class="line" d="M 34,32 L 66,68" />
			<path [ngStyle]="{ stroke: headerTextColor }" class="line" d="M 66,32 L 34,68" />
		</svg>
	</div>
</header>
