import { HttpEventType } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { FileService } from '@shared/services/file.service';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PostCategory } from '../../interfaces/post-category.interface';
import { PostSubcategory } from '../../interfaces/post-subcategory.interface';
import { Post } from '../../interfaces/post.interface';
import { State } from '../../store';
import { loadAdminCategoriesList } from '../../store/actions/admin-categories.actions';
import { upsertPost } from '../../store/actions/admin-posts.actions';
import { loadAdminSubcategoriesList } from '../../store/actions/post-subcategories.actions';
import {
	selectAdminCategoriesList,
	selectAdminCategoriesListLoading,
} from '../../store/selectors/admin-categories.selectors';
import {
	selectAdminSubcategoriesList,
	selectAdminSubcategoriesListLoading,
} from '../../store/selectors/admin-subcategories.selectors';
import { selectAdminPostsListLoading } from '../../store/selectors/admin-posts.selectors';

@Component({
	selector: 'app-post-form-page',
	templateUrl: './post-form-page.component.html',
	styleUrls: ['./post-form-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostFormPageComponent implements OnDestroy {
	readonly videoLoading$ = new BehaviorSubject<boolean>(false);
	readonly imageLoading$ = new BehaviorSubject<boolean>(false);
	readonly loading$ = new BehaviorSubject<boolean>(false);
	readonly newPostLoading$: Observable<boolean>;
	readonly selectedImages$ = new BehaviorSubject<string[]>([]);
	readonly selectedVideos$ = new BehaviorSubject<string[]>([]);
	readonly percentDone$ = new BehaviorSubject<string>('');
	readonly categoriesList$ = new Observable<PostCategory[]>();
	readonly subcategoriesList$ = new Observable<PostSubcategory[]>();

	readonly destroyed$ = new Subject<void>();

	readonly filesURI = environment.filesURI;

	postData: Post;
	mode: 'add' | 'edit';

	constructor(private route: ActivatedRoute, private store: Store<State>, private fileService: FileService) {
		this.postData = this.route.snapshot.data.postData as Post;

		merge([
			this.store.pipe(select(selectAdminCategoriesListLoading)),
			this.store.pipe(select(selectAdminSubcategoriesListLoading)),
		])
			.pipe(switchMap((loading) => loading))
			.subscribe((loading) => {
				this.loading$.next(loading);
			});
		this.newPostLoading$ = this.store.pipe(select(selectAdminPostsListLoading));
		this.loadCategories();
		this.loadSubcategories();

		this.categoriesList$ = this.store.pipe(select(selectAdminCategoriesList));
		this.subcategoriesList$ = this.store.pipe(select(selectAdminSubcategoriesList));

		if (!this.postData) {
			this.mode = 'add';
		} else {
			this.mode = 'edit';

			if (this.postData?.images.length) {
				this.selectedImages$.next(this.postData.images);
			}

			if (this.postData?.videos.length) {
				this.selectedVideos$.next(this.postData.videos);
			}
		}
	}

	onCreatePost(post: Post): void {
		this.store.dispatch(upsertPost({ data: post }));
	}

	onUpdatePost(post: Post): void {
		this.store.dispatch(upsertPost({ data: post }));
	}

	loadCategories(): void {
		this.store.dispatch(loadAdminCategoriesList());
	}

	loadSubcategories(): void {
		this.store.dispatch(loadAdminSubcategoriesList());
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
		this.loading$.complete();
	}

	onUploadImage(file: File): void {
		this.imageLoading$.next(true);
		this.fileService.uploadFile(file).subscribe((response: any) => {
			if (response.type === HttpEventType.Response) {
				if (response.body.url) {
					this.selectedImages$.next(this.selectedImages$.getValue().concat(response.body.url));
					this.imageLoading$.next(false);
				}
			}
		});
	}

	onUploadVideo(file: File): void {
		this.videoLoading$.next(true);

		this.fileService.uploadFile(file).subscribe((response: any) => {
			if (response.type === HttpEventType.UploadProgress) {
				this.percentDone$.next(Math.round((100 / response.total) * response.loaded) + '%');
			} else if (response.type === HttpEventType.Response) {
				if (response.body.url) {
					this.selectedVideos$.next(this.selectedVideos$.getValue().concat(response.body.url));
					this.videoLoading$.next(false);
				}
			}
		});
	}
}
