import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { NavigationService } from '@shared/services/navigation.service';

@Injectable()
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild {
	constructor(private authService: AuthService, private navigationService: NavigationService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const { url } = state;
		return this.isAuthenticated(url);
	}

	canLoad(): boolean {
		return this.isAuthenticated();
	}

	canActivateChild(): boolean {
		return this.isAuthenticated();
	}

	private isAuthenticated(returnUrl?: string): boolean {
		const { isAuthenticated } = this.authService;

		if (!isAuthenticated) {
			this.navigationService.navigateTo(['auth']);
		}

		if (returnUrl) {
			this.authService.returnUrl$.next(returnUrl);
		}

		return isAuthenticated;
	}
}
