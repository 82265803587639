import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { User } from '../../interfaces/user.interface';
import { loadUsersList, loadUsersListFail, loadUsersListSuccess } from '../actions/admin.actions';

export interface UserState extends EntityState<User> {
	loading: boolean;
	loaded: boolean;
	error: string;
}

export const usersAdapter: EntityAdapter<User> = createEntityAdapter<User>({
	selectId: (user: User) => user.identityId,
});

export const initialState: UserState = usersAdapter.getInitialState({
	loading: false,
	loaded: false,
	error: null,
});

const reducer = createReducer(
	initialState,

	on(loadUsersList, (state) => ({
		...state,
		loading: true,
		loaded: false,
		error: null,
	})),

	on(loadUsersListSuccess, (state, { payload }) => {
		return usersAdapter.setAll(payload || [], {
			...state,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(loadUsersListFail, (state) => ({
		...state,
		loading: false,
		loaded: false,
		error: null,
	}))
);

export function usersReducer(state: UserState | undefined, action: Action) {
	return reducer(state, action);
}
