import { OverlayContainer } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { AuthApiService } from '@auth/services/auth-api.service';
import { AuthService } from '@auth/services/auth.service';
import { GoogleAnalyticsService } from '@core/services/google-analytics-service';
import { NotificationService } from '@core/services/notification.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { amountPatterns } from '@shared/constants/amount-patterns.const';
import { NavigationService } from '@shared/services/navigation.service';
import { StorageKeys, StorageService } from '@shared/services/storage.service';
import { UrlParserService } from '@shared/services/url-parser.service';
import { HttpUtilities } from '@shared/utilities/http-utilities';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UserInfo } from './models/user-info.model';
import { CompanyNames } from './modules/company/enums/company.enum';
import { CompanyHelperService } from './modules/company/services/company-helper.service';
import { SystemNotifications } from './modules/system-notificatios/services/system-notifications.service';

declare const gtag: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'stakeholder';

	readonly activeTheme$ = new BehaviorSubject<string>(null);

	private readonly googleAnalyticsDerome = 'G-06WT39VLXE';
	private readonly googleAnalyticsABKarlHedin = 'UA-84232516-1';
	private readonly googleAnalyticsMoelven = 'G-GNCF7GPNH8';
	private readonly googleAnalyticsEvryDev = 'G-RN71VWJYHG';

	constructor(
		private translate: TranslateService,
		private authService: AuthService,
		private cdr: ChangeDetectorRef,
		private titleService: Title,
		private companyHelperService: CompanyHelperService,
		private authApiService: AuthApiService,
		private navigationService: NavigationService,
		private storageService: StorageService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private router: Router,
		private urlParserService: UrlParserService,
		private notificationService: NotificationService,
		private systemNotifications: SystemNotifications,
		private overlayContainer: OverlayContainer
	) {}

	ngOnInit(): void {
		const { companyName } = this.urlParserService;
		let currentCode;

		if (companyName === CompanyNames.Derome) {
			currentCode = this.googleAnalyticsDerome;
			this.applyTheme(companyName);
		}

		if (companyName === CompanyNames.KarlHedin) {
			currentCode = this.googleAnalyticsABKarlHedin;
			this.applyTheme(companyName);
		}

		if (companyName === CompanyNames.Moelven) {
			currentCode = this.googleAnalyticsMoelven;
			this.applyTheme(companyName);
		}

		if (companyName === CompanyNames.JGA) {
			this.applyTheme(companyName);
		}

		if (companyName === CompanyNames.ConiferSoft) {
			currentCode = this.googleAnalyticsEvryDev;
			this.applyTheme(companyName);
		}

		this.googleAnalyticsService.initializeGoogleAnalytics(currentCode);

		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			gtag('config', currentCode, {
				page_path: event.urlAfterRedirects,
			});
		});

		this.setUpTranslation();
		this.authService.isAuthenticated$.pipe(filter((signed) => !!signed)).subscribe(() => {
			this.authApiService.getUserInfo().subscribe(
				(userInfo: UserInfo) => {
					this.authService.checkRole(userInfo.roles);
					this.storageService.set(StorageKeys.UserInfo, userInfo);
					this.systemNotifications.initSystemNotifications();
					if (this.urlParserService.path.includes('auth')) {
						if (this.authService.returnUrl) {
							if (this.authService.returnUrl.includes('?')) {
								const mainPart = this.authService.returnUrl.split('?')[0];
								const queryPart = this.authService.returnUrl.split('?')[1];
								const params = HttpUtilities.getQueryParamsFromString(queryPart);
								this.router.navigate([mainPart], { queryParams: params }).then(() => window.location.reload());
							} else {
								this.router.navigate([this.authService.returnUrl]).then(() => window.location.reload());
							}
							this.authService.returnUrl$.next(null);
						} else {
							this.navigationService.navigateTo(['home']).then(() => window.location.reload());
						}
					}
				},
				({ error }) => {
					if (error.Message === 'No relative user companies') {
						this.notificationService.error({
							message: this.translate.instant('login.error.noRelativeUserCompanies'),
						});
					}
				}
			);

			this.cdr.markForCheck();
		});
		this.companyHelperService.companyData$.pipe(take(1)).subscribe((data) => {
			if (data.settings.websiteTitle) {
				this.titleService.setTitle(data.settings.websiteTitle);
			}
			if (data.styles.favicon) {
				let favIcon: HTMLLinkElement = document.querySelector('#appIcon');
				favIcon.href = data.styles.favicon;
			}
		});
	}

	setUpTranslation() {
		const langs = ['en', 'sv'];
		this.translate.addLangs(['en', 'sv']);
		const browserLang = this.translate.getBrowserLang();
		const cachedLang = langs.includes(this.storageService.get(StorageKeys.Language))
			? this.storageService.get(StorageKeys.Language)
			: null;
		const usedLang = cachedLang ? cachedLang : browserLang;
		const languageToSet = usedLang.match(/en|sv/) ? usedLang : 'en';
		this.storageService.set(StorageKeys.Language, languageToSet);
		this.translate.use(languageToSet);

		amountPatterns.current = amountPatterns[this.translate.currentLang];

		this.translate.onLangChange.subscribe((langEv: LangChangeEvent) => {
			amountPatterns.current = amountPatterns[langEv.lang];
		});
	}

	private applyTheme(companyName: string): void {
		const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
		const themeClassesToRemove = Array.from(overlayContainerClasses).filter((item: string) => item.includes('-theme'));

		if (themeClassesToRemove.length) {
			overlayContainerClasses.remove(...themeClassesToRemove);
		}

		this.activeTheme$.next(companyName + '-theme');
		overlayContainerClasses.add(companyName + '-theme');
	}
}
