import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsService } from './app-insights.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
	constructor(private appInsights: AppInsightsService) {
		super();
	}

	handleError(error: Error) {
		this.appInsights.logException(error);
	}
}
