import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@auth/guards/role.guard';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { PostFormPageComponent } from './pages/post-form-page/post-form-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { UserManagementPageComponent } from './pages/user-management-page/user-management-page.component';
import { PostDataResolver } from './resolvers/post-data.resolver';

export const adminRoutes: Routes = [
	{
		path: '',
		component: AdminPageComponent,
		canActivate: [RoleGuard],
		children: [
			{
				path: '',
				redirectTo: 'user-management',
				pathMatch: 'full',
			},
			{
				path: 'user-management',
				component: UserManagementPageComponent,
			},
			{
				path: 'settings',
				component: SettingsPageComponent,
			},
			{
				path: 'faq',
				component: FaqPageComponent,
			},
		],
	},
	{
		path: 'add-post',
		canActivate: [RoleGuard],
		component: PostFormPageComponent,
	},
	{
		path: 'post/:id/edit',
		component: PostFormPageComponent,
		canActivate: [RoleGuard],
		resolve: {
			postData: PostDataResolver,
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(adminRoutes)],
	exports: [RouterModule],
})
export class AdminRoutingModule {}
