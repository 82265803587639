import { createAction, props } from '@ngrx/store';
import { PostSubcategory } from '../../interfaces/post-subcategory.interface';

export const loadAdminSubcategoriesList = createAction('[Admin Subcategories] Load admin subcategories list');

export const loadAdminSubcategoriesListSuccess = createAction(
	'[Admin Subcategories] Load admin subcategories list success',
	props<{ data: PostSubcategory[] }>()
);

export const loadAdminSubcategoriesListFail = createAction(
	'[Admin Subcategories] Load admin subcategories list fail',
	props<{ error: Error }>()
);

export const upsertAdminSubcategory = createAction(
	'[Admin Subcategories] Upsert admin subcategory',
	props<{ data: PostSubcategory }>()
);

export const upsertAdminSubcategorySuccess = createAction(
	'[Admin Subcategories] Upsert admin subcategory success',
	props<{ data: PostSubcategory }>()
);

export const upsertAdminSubcategoryFail = createAction(
	'[Admin Subcategories] Upsert admin subcategory fail',
	props<{ error: Error }>()
);

export const deleteAdminSubcategory = createAction(
	'[Admin Subcategories] Delete admin subcategory',
	props<{ id: number }>()
);

export const deleteAdminSubcategorySuccess = createAction(
	'[Admin Subcategories] Delete admin subcategory success',
	props<{ id: number }>()
);

export const deleteAdminSubcategoryFail = createAction(
	'[Admin Subcategories] Delete admin subcategory fail',
	props<{ error: Error }>()
);
