export enum CustomizationKeys {
	HeaderBgColor = 'headerBgColor',
	HeaderTextColor = 'headerTextColor',
	HeaderLogo = 'headerLogo',
	HeaderNotificationNewContractCreatedIconColor = 'headerNotificationNewContractCreatedIconColor',
	HeaderNotificationNewContractStartedIconColor = 'headerNotificationNewContractStartedIconColor',
	HeaderNotificationNewContractFileAddedIconColor = 'headerNotificationNewContractFileAddedIconColor',
	HeaderNotificationNewAvrFileAddedIconColor = 'headerNotificationNewAvrFileAddedIconColor',
	HeaderNotificationNewPostAddedIconColor = 'headerNotificationNewPostAddedIconColor',
	HeaderNoNotificationsIconColor = 'headerEmptyNotificationsIconColor',
	Favicon = 'favicon',
	FooterBgColor = 'footerBgColor',
	FooterTextColor = 'footerTextColor',
	LoginPageLogo = 'loginPageLogo',
	LoginPageBg = 'loginPageBg',
	HomepageBg = 'homepageBg',
	NotStartedStatusColor = 'notStartedStatusColor',
	CompletedStatusColor = 'completedStatusColor',
	ChartColorFirst = 'chartColorFirst',
	ChartColorSecond = 'chartColorSecond',
	ChartColorThird = 'chartColorThird',
	DetailsPageIconsColor = 'detailsPageIconsColor',
	DocumentIcon = 'documentIcon',
	ContractIcon = 'contractIcon',
	PurchaserIcon = 'purchaserIcon',
	EstateIcon = 'estateIcon',
	TextColor = 'textColor',
	HeadingTextColor = 'headingTextColor',
	SupportEmail = 'supportEmail',
	WebsiteTitle = 'websiteTitle',
	TaCodesToExcludeFromAdditions = 'taCodesToExcludeFromAdditions',
	ClearingNumberFormat = 'clearingNumberFormat',
	ContractCutoffDate = 'contractCutoffDate',
}
