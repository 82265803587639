<form
	*ngIf="!isForgotPassword"
	(keydown)="keyDown($event, 'login')"
	[formGroup]="signinForm"
	class="signin-form"
	[errorMessages]="errorMessages"
	appFormValidation
>
	<div>
		<mat-form-field appearance="outline" class="form-field">
			<mat-icon class="form-icon prefix-icon" matPrefix>local_post_office</mat-icon>
			<mat-label>{{ 'login.label.email' | translate }}</mat-label>
			<input formControlName="email" matInput />
			<mat-error>{{ signinForm | error : 'email' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="form-field">
			<mat-icon class="form-icon prefix-icon" matPrefix>lock</mat-icon>
			<mat-label>{{ 'login.label.password' | translate }}</mat-label>
			<input [type]="hidePassword ? 'password' : 'text'" formControlName="password" matInput />
			<button
				(click)="hidePassword = !hidePassword"
				[attr.aria-label]="'Hide password'"
				[attr.aria-pressed]="hidePassword"
				mat-icon-button
				matSuffix
				type="button"
			>
				<mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
			</button>

			<mat-error>{{ signinForm | error : 'password' | translate }}</mat-error>
		</mat-form-field>

		<div *ngIf="errors.length > 0" class="has-error">
			<div *ngFor="let errorMessage of errors">
				<mat-error>{{ errorMessage.description }}</mat-error>
			</div>
		</div>

		<div class="form-pass" (click)="forgotPassword()">{{ 'login.label.forgotPassword' | translate }}</div>
		<br />
	</div>
	<div class="signin-form-actions">
		<button (click)="goBack.emit()" class="large" mat-raised-button type="button">
			<mat-icon>chevron_left</mat-icon>
			{{ 'login.back' | translate }}
		</button>
		<button (click)="submit()" class="large" color="primary" mat-raised-button type="submit">
			{{ 'login.label.sign_in' | translate }}
			<mat-icon>login</mat-icon>
		</button>
	</div>
</form>

<div class="forgot-password" *ngIf="isForgotPassword">
	<div class="forgot-password-text">{{ 'login.forgotPasswordText' | translate }}</div>
	<form
		(keydown)="keyDown($event, 'forgot-password')"
		[formGroup]="forgotPasswordForm"
		class="forgot-password-form"
		[errorMessages]="errorMessages"
		appFormValidation
	>
		<mat-form-field appearance="outline" class="form-field">
			<mat-icon class="form-icon prefix-icon" matPrefix>local_post_office</mat-icon>
			<mat-label>{{ 'login.label.email' | translate }}</mat-label>
			<input formControlName="email" matInput />
			<mat-error>{{ forgotPasswordForm | error : 'email' | translate }}</mat-error>
		</mat-form-field>

		<div class="forgot-password-actions">
			<button (click)="forgotPassword()" class="large" mat-raised-button type="button">
				<mat-icon>chevron_left</mat-icon>
				{{ 'login.back' | translate }}
			</button>
			<button
				(click)="submitForgotPassword()"
				[disabled]="forgotPasswordForm.invalid || forgotPasswordForm.pristine"
				class="large"
				color="primary"
				mat-raised-button
				type="button"
			>
				{{ 'login.confirmResetPassword' | translate }}
			</button>
		</div>
	</form>
</div>
