import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth/guards/auth.guard';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { UrlParserService } from '@shared/services/url-parser.service';
import { LayoutComponent } from './layout/layout.component';
import { CompanyInfoGuard } from './modules/company/guard/company.guard';

const appRoutes: Routes = [
	{
		path: '',
		canActivate: [CompanyInfoGuard],
		children: [
			{
				path: 'auth',
				loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
			},
			{
				path: '',
				component: LayoutComponent,
				canActivate: [AuthGuard],
				children: [
					{ path: '', redirectTo: 'home', pathMatch: 'full' },
					{
						path: 'home',
						loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
					},
					{
						path: 'myestate',
						loadChildren: () => import('./modules/estate/estate.module').then((m) => m.EstateModule),
					},
					{
						path: 'settings',
						loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
					},
					{
						path: 'contracts',
						loadChildren: () => import('./modules/contract/contract.module').then((m) => m.ContractModule),
					},
					{
						path: 'purchaser',
						loadChildren: () => import('./modules/purchaser/purchaser.module').then((m) => m.PurchaserModule),
					},
					{
						path: 'documents',
						loadChildren: () => import('./modules/document/documents.module').then((m) => m.DocumentsModule),
					},
					{ path: 'faq', loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FaqModule) },
					{
						path: 'admin',
						loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
					},
				],
			},
		],
	},
];

function getRoutes(): Routes {
	if (UrlParserService.isOwnOrigin(window.location)) {
		// domain.companyName.com/home
		return [
			...appRoutes,
			{ path: 'not-found', component: NotFoundComponent },
			{ path: '**', redirectTo: 'not-found', pathMatch: 'full' },
		];
	} else {
		// domain.com/companyName/home
		return [
			{ path: 'not-found', component: NotFoundComponent },
			{
				path: ':companyName',
				children: [...appRoutes],
			},
			{ path: '', redirectTo: '/not-found', pathMatch: 'full' },
			{ path: '**', redirectTo: 'not-found', pathMatch: 'full' },
		];
	}
}

const routes: Routes = getRoutes();

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
