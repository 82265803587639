import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { DelegatedUserService } from 'src/app/shared/services/delegated-user.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Injectable()
export class RoleGuard implements CanActivate {
	constructor(
		private delegatedUserService: DelegatedUserService,
		private navigationService: NavigationService,
		private authService: AuthService
	) {}

	canActivate(): boolean {
		return this.checkAccess();
	}

	private checkAccess(): boolean {
		const { isUser, isCompanyAdmin, isCompanyAdminLight, isSystemAdmin } = this.authService;

		if (isUser) {
			return true;
		}

		if (isCompanyAdmin || isSystemAdmin || isCompanyAdminLight) {
			if (this.delegatedUserService.delegatedUserIsSelected()) {
				return true;
			} else {
				return true;
			}
		}

		this.navigationService.navigateTo(['auth']);
	}
}
