import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PostCategory } from '../../interfaces/post-category.interface';
import {
	deleteAdminCategory,
	deleteAdminCategoryFail,
	deleteAdminCategorySuccess,
	loadAdminCategoriesList,
	loadAdminCategoriesListFail,
	loadAdminCategoriesListSuccess,
	upsertAdminCategory,
	upsertAdminCategoryFail,
	upsertAdminCategorySuccess,
} from '../actions/admin-categories.actions';

export interface AdminCategoriesState extends EntityState<PostCategory> {
	loading: boolean;
	loaded: boolean;
	error: Error;
}

export const adminCategoriesAdapter: EntityAdapter<PostCategory> = createEntityAdapter<PostCategory>({
	selectId: (category: PostCategory) => category.id || null,
});

const initialState: AdminCategoriesState = adminCategoriesAdapter.getInitialState({
	loading: false,
	loaded: false,
	error: null,
});

const reducer = createReducer(
	initialState,

	on(loadAdminCategoriesList, upsertAdminCategory, deleteAdminCategory, (state) => ({
		...state,
		loading: true,
		loaded: false,
		error: null,
	})),

	on(loadAdminCategoriesListSuccess, (state, { data }) => {
		return adminCategoriesAdapter.setAll(data || [], {
			...state,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(upsertAdminCategorySuccess, (state, payload) => {
		return adminCategoriesAdapter.upsertOne(payload.data, {
			...state,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(deleteAdminCategorySuccess, (state, payload) => {
		return adminCategoriesAdapter.removeOne(payload.id, {
			...state,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(loadAdminCategoriesListFail, upsertAdminCategoryFail, deleteAdminCategoryFail, (state, { error }) => ({
		...state,
		loading: false,
		loaded: false,
		error,
	}))
);

export function adminCategoriesReducer(state: AdminCategoriesState | undefined, action: Action) {
	return reducer(state, action);
}
