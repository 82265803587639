import { createAction, props } from '@ngrx/store';
import { LoginCredentials } from '../../interfaces/login-credentials.interface';
import { BankIdCompletionData } from '@auth/interfaces/bankid-completion-data.interface';

export const signIn = createAction('[Auth] Sign in', props<{ data: LoginCredentials }>());

export const signInSuccess = createAction('[Auth] Sign in success');

export const signInFail = createAction('[Auth] Sign in fail', props<{ error: any }>());

export const bankIdLogin = createAction(
	'[Auth] BankID login',
	props<{ companyId: number; orderRef: string; redirectUrl: string; completionData?: BankIdCompletionData }>()
);
