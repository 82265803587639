import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKeys, StorageService } from './storage.service';
import { UrlParserService } from './url-parser.service';

@Injectable()
export class NavigationService {
	constructor(
		private router: Router,
		private urlParserService: UrlParserService,
		private storageService: StorageService
	) {}

	navigateTo(routes: string[], params?: any) {
		const commands = this.getLinkPath(routes);
		return this.router.navigate(commands, { queryParams: params });
	}

	getLinkPath(routes: string[]): string[] {
		if (this.urlParserService.isOwnOrigin) {
			return ['/', ...routes];
		}
		const company = this.storageService.get(StorageKeys.CompanyInfo);
		if (!company || !company.nameUrl) {
			this.navigateNotFound();
		}
		return ['/', company.nameUrl, ...routes];
	}

	isRouterUrl(url: string) {
		const companyUrl = this.urlParserService.isOwnOrigin ? `/${url}` : `/${this.urlParserService.companyName}/${url}`;
		return companyUrl === this.router.url;
	}

	navigateNotFound() {
		return this.router.navigate(['/', 'not-found']);
	}
}
