import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { UrlParserService } from '@shared/services/url-parser.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class CustomTranslateHttpLoader implements TranslateLoader {
	constructor(private http: HttpClient, private urlParserService: UrlParserService) {}

	getTranslation(lang: string): Observable<any> {
		const { companyName } = this.urlParserService;
		if (companyName) {
			return this.http.get(`/assets/i18n/${lang}-${companyName.toLowerCase()}.json`).pipe(
				catchError(() => {
					return this.http.get(`/assets/i18n/${lang}.json`);
				})
			);
		}
		return this.http.get(`/assets/i18n/${lang}.json`);
	}
}
