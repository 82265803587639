import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpUtilities } from '@shared/utilities/http-utilities';
import { Observable } from 'rxjs';
import { SystemNotification } from '../interfaces/system-notification.interface';

@Injectable()
export class SystemNotificationsApi {
	baseUrl = environment.baseURI;

	constructor(private http: HttpClient) {}

	markNotificationsAsRead(notificationIds: number[]): Observable<void> {
		const ids = [...notificationIds];
		return this.http.post<void>(`${this.baseUrl}/notifications/markasread`, { ids });
	}

	getAllNotifications(): Observable<SystemNotification[]> {
		return this.http.get<SystemNotification[]>(`${this.baseUrl}/notifications/getall`);
	}

	getNotification(id: number): Observable<SystemNotification> {
		const params = HttpUtilities.genParams({ id });
		return this.http.get<SystemNotification>(`${this.baseUrl}/notifications/get`, { params });
	}
}
