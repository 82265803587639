import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDto } from '@auth/interfaces/expired-password.interface';
import { AuthApiService } from '@auth/services/auth-api.service';
import { State } from '@auth/store';
import { selectAuthLoading } from '@auth/store/selectors/auth.selectors';
import { NotificationService } from '@core/services/notification.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { responseErrorsDictionary } from '@shared/dictionaries/response-errors.dictionary';
import { NavigationService } from '@shared/services/navigation.service';
import { Observable } from 'rxjs';
import { CustomizationKeys } from '../../../modules/admin/enums/customization-keys.enum';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';
import { selectCompany, selectCompanyLoading } from '../../../modules/company/store/selectors/company.selectors';
import { signIn } from '@auth/store/actions/auth.actions';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-expired-password-page',
	templateUrl: 'expired-password-page.component.html',
	styleUrls: ['expired-password-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpiredPasswordPageComponent {
	readonly selectedCompany$: Observable<CompanyInterface>;
	readonly selectedCompanyLoading$: Observable<boolean>;
	readonly loading$: Observable<boolean>;
	readonly email: string;

	customizationKeys = CustomizationKeys;

	constructor(
		private store: Store<State>,
		private dialog: MatDialog,
		private translate: TranslateService,
		private navigationService: NavigationService,
		private authApiService: AuthApiService,
		private notificationService: NotificationService,
		private route: ActivatedRoute
	) {
		this.selectedCompany$ = this.store.pipe(select(selectCompany));
		this.selectedCompanyLoading$ = this.store.pipe(select(selectCompanyLoading));
		this.loading$ = this.store.pipe(select(selectAuthLoading));
		this.email = this.route.snapshot.paramMap.get('email');
	}

	onUpdatePassword(body: ChangePasswordDto): void {
		this.authApiService.updateExpiredPassword(body).subscribe(
			() => {
				this.notificationService.success({
					message: this.translate.instant('resetPassword.passwordSuccessfullyChanged'),
				});

				this.store.dispatch(signIn({ data: { email: body.email, password: body.newPassword } }));

				this.store.subscribe(
					(state) => {},
					(response: HttpErrorResponse) => {
						this.notificationService.error({
							message: this.translate.instant(responseErrorsDictionary.get(response.error.Code)),
						});
					}
				);
			},
			(response: HttpErrorResponse) => {
				this.notificationService.error({
					message: this.translate.instant(responseErrorsDictionary.get(response.error.Code)),
				});
			}
		);
	}
}
