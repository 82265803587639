import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserRole } from '@auth/enums/user-role.enum';
import { AuthService } from '@auth/services/auth.service';
import { DEFAULT_THEME_COLOR } from '@shared/constants/style-constants-for-components';
import { DelegatedUserService } from '@shared/services/delegated-user.service';
import { NavigationService } from '@shared/services/navigation.service';
import { Observable } from 'rxjs';
import { CustomizationKeys } from '../../../modules/admin/enums/customization-keys.enum';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';
import { NotificationEventTypes } from '../../../modules/system-notificatios/enums/notification-event-types.enum';
import { SystemNotification } from '../../../modules/system-notificatios/interfaces/system-notification.interface';
import { SystemNotifications } from '../../../modules/system-notificatios/services/system-notifications.service';

@Component({
	selector: 'app-header',
	templateUrl: 'header.component.html',
	styleUrls: ['header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnChanges, OnInit {
	@Input()
	readonly companyInfo: CompanyInterface;

	readonly systemNotifications$: Observable<SystemNotification[]>;
	readonly unreadNotificationsLength$: Observable<number>;

	navLinks = [
		{
			path: 'home',
			name: 'Home',
			permissions: [UserRole.USER, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_LIGHT],
			icon: 'home',
		},

		{
			path: 'contracts',
			name: 'Contracts',
			permissions: [UserRole.USER, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_LIGHT],
			icon: 'assignment',
		},
		{
			path: 'documents',
			name: 'Documents',
			permissions: [UserRole.USER, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_LIGHT],
			icon: 'description',
		},
		{
			path: 'myestate',
			name: 'My Estate',
			permissions: [UserRole.USER, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_LIGHT],
			icon: 'map',
		},
		{
			path: 'purchaser',
			name: 'My Timber Purchaser',
			permissions: [UserRole.USER, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_LIGHT],
			icon: 'account_box',
		},
		{
			path: 'settings',
			name: 'Settings',
			permissions: [UserRole.USER, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_LIGHT],
			icon: 'manage_accounts',
		},
		{
			path: 'faq',
			name: 'Faq',
			permissions: [UserRole.USER, UserRole.COMPANY_ADMIN, UserRole.COMPANY_ADMIN_LIGHT],
			icon: 'info',
		},
	];

	delegatedUserName: string;
	isAdmin: boolean;
	defaultColor: string = DEFAULT_THEME_COLOR;
	isActive = false;
	logo;
	headerBgColor;
	headerTextColor;
	textColor;

	constructor(
		private navigationService: NavigationService,
		private route: ActivatedRoute,
		private delegatedUserService: DelegatedUserService,
		private cdr: ChangeDetectorRef,
		private authService: AuthService,
		private systemNotifications: SystemNotifications
	) {
		this.isAdmin = this.authService.isCompanyAdmin || this.authService.isCompanyAdminLight;
		this.systemNotifications$ = this.systemNotifications.systemNotification$;
		this.unreadNotificationsLength$ = this.systemNotifications.unreadNotificationsLength$;
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { companyInfo } = changes;
		if (companyInfo?.currentValue) {
			this.logo = this.companyInfo.styles[CustomizationKeys.HeaderLogo] || this.companyInfo.logoUrl;
			this.headerBgColor =
				this.companyInfo.styles[CustomizationKeys.HeaderBgColor] || this.companyInfo.hexColor || this.defaultColor;
			this.headerTextColor = this.companyInfo.styles[CustomizationKeys.HeaderTextColor];
		}
	}

	ngOnInit(): void {
		this.delegatedUserService._delegatedUser$.subscribe((user) => {
			if (user) {
				this.delegatedUserName = `${user.firstName} ${user.lastName}`;
			} else {
				this.delegatedUserName = null;
			}
			this.cdr.detectChanges();
		});
	}

	openNotification(notification: SystemNotification): void {
		if (!notification.read) {
			this.systemNotifications.markAsRead([notification.id]);
		}

		switch (notification.class) {
			case NotificationEventTypes.NewContractCreated:
			case NotificationEventTypes.ContractStarted: {
				this.navigationService.navigateTo(['contracts', `${notification.targetEntityId}`, 'details']);
				break;
			}

			case NotificationEventTypes.AvrFileAdded:
			case NotificationEventTypes.ContractFileAdded: {
				const params = {
					notificationId: notification.id,
					docId: notification.extra1,
				};
				this.navigationService.navigateTo(['contracts', `${notification.targetEntityId}`, 'details'], params);
				break;
			}

			case NotificationEventTypes.NewInformationPosted: {
				this.navigationService.navigateTo(['faq']);
				break;
			}
			default: {
				this.navigationService.navigateTo(['contracts', `${notification.targetEntityId}`, 'details']);
				break;
			}
		}
	}

	markAllAsRead(notifications: SystemNotification[]): void {
		const notificationIds = notifications
			.filter((el: SystemNotification) => !el.read)
			.map((el: SystemNotification) => el.id);

		this.systemNotifications.markAsRead(notificationIds);
	}

	navigateToCompany(): void {
		if (this.navigationService.isRouterUrl('home')) {
			return;
		} else {
			this.navigationService.navigateTo(['home']);
		}
	}

	isDeligatedUser(tabPermissions: string[]): boolean {
		const { isUser, isCompanyAdmin, isCompanyAdminLight } = this.authService;
		if (isUser) {
			return true;
		}

		if (isCompanyAdmin || isCompanyAdminLight) {
			if (tabPermissions.includes(UserRole.USER)) {
				return !!this.delegatedUserName;
			}
			return true;
		}
	}

	removeDeligatedUser(): void {
		this.delegatedUserName = null;
		this.delegatedUserService.removeDelegatedUser();
		this.navigationService.navigateTo(['admin']);
	}

	toggleMenu(): void {
		this.isActive = !this.isActive;
	}

	closeMenu(): void {
		this.isActive = false;
	}

	logout(): void {
		this.systemNotifications.clearSystemNotifications();
		this.authService.logout();
		this.navigationService.navigateTo(['auth']);
	}
}
