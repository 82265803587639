import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { PostCategory } from '../../interfaces/post-category.interface';
import { PostSubcategory } from '../../interfaces/post-subcategory.interface';
import { State } from '../../store';
import { deleteAdminSubcategory, upsertAdminSubcategory } from '../../store/actions/post-subcategories.actions';

interface SubcategoriesData {
	categoriesList: Partial<PostCategory[]>;
	subcategoriesList: PostSubcategory[];
}

@Component({
	selector: 'app-post-subcategories-modal',
	templateUrl: 'post-subcategories-modal.component.html',
	styleUrls: ['post-subcategories-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostSubcategoriesModalComponent implements OnInit {
	displayedColumns = ['title', 'category', 'order', 'actions'];

	dataSource = new BehaviorSubject<AbstractControl[]>([]);
	rows: FormArray = this.fb.array([]);
	form: FormGroup = this.fb.group({ categories: this.rows });

	isEditingRow: boolean[] = [];

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<PostSubcategoriesModalComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: SubcategoriesData,
		private store: Store<State>
	) {}

	get categories(): FormArray {
		return this.form.get('categories') as FormArray;
	}

	ngOnInit() {
		this.data.subcategoriesList.forEach((item: PostSubcategory, index: number) => {
			this.addRow(item, false);

			this.categories.at(index).get('categoryId').disable({ emitEvent: true });
		});
		this.updateView();
	}

	addRow(item?: PostSubcategory, noUpdate?: boolean) {
		const row = this.fb.group({
			id: [item && item.id ? item.id : 0, []],
			title: [item && item.title ? item.title : null, [Validators.required]],
			categoryId: [item && item.categoryId ? item.categoryId : null, [Validators.required]],
			order: [item && item.order ? item.order : null, [Validators.required]],
		});
		this.rows.push(row);
		if (!noUpdate) {
			this.updateView();
		}
	}

	updateView() {
		this.dataSource.next(this.rows.controls);
	}

	deleteRow(row: PostSubcategory, i: number) {
		if (row) {
			this.store.dispatch(deleteAdminSubcategory({ id: row.id }));
		}

		this.rows.removeAt(i);
		this.updateView();
	}

	saveRow(index: number) {
		const controls = this.rows.controls[index];

		const formData: PostSubcategory = {
			id: controls.get('id').value,
			categoryId: controls.get('categoryId').value,
			title: controls.get('title').value,
			order: +controls.get('order').value,
		};

		if (!formData.title) {
			this.categories.at(index).get('title').setErrors({ required: true });
			this.categories.at(index).get('title').markAllAsTouched();
			return;
		}

		if (!formData.categoryId) {
			this.categories.at(index).get('categoryId').setErrors({ required: true });
			this.categories.at(index).get('categoryId').markAllAsTouched();
			return;
		}

		if (!formData.order) {
			this.categories.at(index).get('order').setErrors({ required: true });
			this.categories.at(index).get('order').markAllAsTouched();
			return;
		}

		this.categories.at(index).get('categoryId').disable({ emitEvent: true });
		this.isEditingRow[index] = false;
		this.store.dispatch(upsertAdminSubcategory({ data: formData }));
	}

	cancelEdit(row: PostSubcategory, index: number): void {
		this.isEditingRow[index] = false;

		this.categories.at(index).get('categoryId').disable({ emitEvent: true });
		this.categories.at(index).get('title').clearValidators();
		this.categories.at(index).get('categoryId').clearValidators();
		this.categories.at(index).get('order').clearValidators();
		this.categories.at(index).get('title').updateValueAndValidity();
		this.categories.at(index).get('categoryId').updateValueAndValidity();
		this.categories.at(index).get('order').updateValueAndValidity();

		if (!row?.order || !row.title || !row.categoryId) {
			const res: PostSubcategory = {
				order: null,
				title: null,
				categoryId: null,
				id: null,
			};

			this.rows.controls[index].patchValue(res);
			return;
		}

		if (row) {
			this.rows.controls[index].get('category').reset();
			this.rows.controls[index].patchValue(row);
		}
	}

	editRow(row: PostSubcategory, index: number) {
		this.categories.at(index).get('categoryId').enable({ emitEvent: true });
		this.isEditingRow[index] = true;
	}

	changeCategory(id: number, index: number): void {
		this.rows.controls[index].get('categoryId').setValue(id);
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
