import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PostSubcategory } from '../../interfaces/post-subcategory.interface';
import {
	deleteAdminSubcategory,
	deleteAdminSubcategoryFail,
	deleteAdminSubcategorySuccess,
	loadAdminSubcategoriesList,
	loadAdminSubcategoriesListFail,
	loadAdminSubcategoriesListSuccess,
	upsertAdminSubcategory,
	upsertAdminSubcategoryFail,
	upsertAdminSubcategorySuccess,
} from '../actions/post-subcategories.actions';

export interface AdminSubcategoriesState extends EntityState<PostSubcategory> {
	loading: boolean;
	loaded: boolean;
	error: Error;
}

export const adminSubcategoriesAdapter: EntityAdapter<PostSubcategory> = createEntityAdapter<PostSubcategory>({
	selectId: (subcategories: PostSubcategory) => subcategories.id || null,
});

const initialState: AdminSubcategoriesState = adminSubcategoriesAdapter.getInitialState({
	loading: false,
	loaded: false,
	error: null,
});

const reducer = createReducer(
	initialState,

	on(loadAdminSubcategoriesList, upsertAdminSubcategory, deleteAdminSubcategory, (state) => ({
		...state,
		loading: true,
		loaded: false,
		error: null,
	})),

	on(loadAdminSubcategoriesListSuccess, (state, { data }) => {
		return adminSubcategoriesAdapter.setAll(data || [], {
			...state,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(upsertAdminSubcategorySuccess, (state, payload) => {
		return adminSubcategoriesAdapter.upsertOne(payload.data, {
			...state,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(deleteAdminSubcategorySuccess, (state, payload) => {
		return adminSubcategoriesAdapter.removeOne(payload.id, {
			...state,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(loadAdminSubcategoriesListFail, upsertAdminSubcategoryFail, deleteAdminSubcategoryFail, (state, { error }) => ({
		...state,
		loading: false,
		loaded: false,
		error,
	}))
);

export function adminSubcategoriesReducer(state: AdminSubcategoriesState | undefined, action: Action) {
	return reducer(state, action);
}
