import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpUtilities } from '@shared/utilities/http-utilities';
import { Observable } from 'rxjs';
import { PostCategory } from '../interfaces/post-category.interface';
import { PostList } from '../interfaces/post-list.interface';
import { PostSubcategory } from '../interfaces/post-subcategory.interface';
import { Post } from '../interfaces/post.interface';

@Injectable()
export class FaqApiService {
	baseUrl = environment.baseURI;

	constructor(private http: HttpClient) {}

	getPost(id: number): Observable<Post> {
		return this.http.get<Post>(`${this.baseUrl}/faq/get/${id}`);
	}

	getAllPosts(
		categoryId?: number,
		subcategoryId?: number,
		pageSize?: number,
		pageNumber?: number
	): Observable<PostList> {
		const headers = new HttpHeaders().set('Range', 'bytes=0-');
		const params = HttpUtilities.genParams({ categoryId, subcategoryId, pageSize, pageNumber });
		return this.http.get<PostList>(`${this.baseUrl}/faq/getall`, { headers, params });
	}

	searchPosts(query: string, pageSize: number, pageNumber: number): Observable<PostList> {
		const params = HttpUtilities.genParams({ query, pageSize, pageNumber });
		return this.http.get<PostList>(`${this.baseUrl}/faq/search`, { params });
	}

	upsertPost(post: Post): Observable<Post> {
		return this.http.post<Post>(`${this.baseUrl}/faq/upsert`, post);
	}

	deletePost(id: number): Observable<void> {
		const params = HttpUtilities.genParams({ id });
		return this.http.delete<void>(`${this.baseUrl}/faq/delete`, { params });
	}

	getPostCategories(): Observable<PostCategory[]> {
		return this.http.get<PostCategory[]>(`${this.baseUrl}/faqcategory/getall`);
	}

	upsertPostCategory(category: PostCategory): Observable<PostCategory> {
		return this.http.post<PostCategory>(`${this.baseUrl}/faqcategory/upsert`, category);
	}

	deletePostCategory(id: number): Observable<void> {
		const params = HttpUtilities.genParams({ id });
		return this.http.delete<void>(`${this.baseUrl}/faqcategory/delete`, { params });
	}

	getPostSubcategories(): Observable<PostSubcategory[]> {
		return this.http.get<PostSubcategory[]>(`${this.baseUrl}/faqsubcategory/getall`);
	}

	upsertPostSubcategory(category: PostSubcategory): Observable<PostSubcategory> {
		return this.http.post<PostSubcategory>(`${this.baseUrl}/faqsubcategory/upsert`, category);
	}

	deletePostSubcategory(id: number): Observable<void> {
		const params = HttpUtilities.genParams({ id });
		return this.http.delete<void>(`${this.baseUrl}/faqsubcategory/delete`, { params });
	}
}
