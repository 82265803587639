import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class UrlParserService {
	constructor(private route: ActivatedRoute, private platformLocation: PlatformLocation) {}

	static isOwnOrigin(location: Location | { hostname: string }) {
		return !location.hostname.includes('azurewebsites') && !location.hostname.includes('local');
	}

	testPath(text: string) {
		const regExp = new RegExp(text.toLowerCase());
		return regExp.test(this.path.toLowerCase());
	}

	get path(): string {
		const { routeConfig } = this.route.snapshot;
		return routeConfig ? routeConfig.path : this.platformLocation.pathname;
	}

	get companyName(): string {
		if (this.isOwnOrigin) {
			return this.companyNameFromOrigin;
		} else {
			return this.companyNameFromPath;
		}
	}

	get isOwnOrigin() {
		return UrlParserService.isOwnOrigin(this.platformLocation);
	}

	get companyNameFromPath(): string {
		return this.path.split('/')[1];
	}

	get companyNameFromOrigin(): string {
		return this.platformLocation.hostname.split('.')[1];
	}
}
