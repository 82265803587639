import { Component } from '@angular/core';
import { StorageService } from '@shared/services/storage.service';
import { UrlParserService } from '@shared/services/url-parser.service';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
	companyName: string;

	constructor(private storageService: StorageService) {
		const { nameUrl } = this.storageService.get('company') as CompanyInterface;
		this.companyName = nameUrl;
	}

	back(): void {
		if (this.companyName) {
			let result;
			if (UrlParserService.isOwnOrigin(window.location)) {
				result = `/auth/login`;
			} else {
				result = `/${this.companyName}/auth/login`;
			}
			window.location.href = result;
		}
	}
}
