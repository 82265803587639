<div class="modal-title" mat-dialog-title>{{ modalContent.title }}</div>

<div mat-dialog-content>
	<p *ngFor="let text of modalContent.confirmation">{{ text }}</p>
	<p class="confirmation-question" *ngIf="modalContent.confirmationQuestion">
		{{ modalContent.confirmationQuestion }}
	</p>
</div>

<div class="actions" mat-dialog-actions>
	<button mat-stroked-button (click)="onCancel()" class="medium">{{ modalContent.cancelButtonText }}</button>
	<button color="primary" mat-raised-button (click)="onConfirm()" class="medium">
		{{ modalContent.confirmButtonText }}
	</button>
</div>

<button (click)="onClose()" class="close-modal" mat-flat-button>
	<mat-icon>close</mat-icon>
</button>
