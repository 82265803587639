import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { SystemNotification } from '../interfaces/system-notification.interface';
import { SystemNotificationsApi } from './system-notifications-api.service';

@Injectable({
	providedIn: 'root',
})
export class SystemNotifications {
	private readonly systemNotifications: BehaviorSubject<SystemNotification[]> = new BehaviorSubject<
		SystemNotification[]
	>([]);
	private readonly unreadNotificationsLength: BehaviorSubject<number> = new BehaviorSubject<number>(0);

	private initialCall = true;

	readonly systemNotification$: Observable<SystemNotification[]> = this.systemNotifications.asObservable();
	readonly unreadNotificationsLength$: Observable<number> = this.unreadNotificationsLength.asObservable();

	private destroy$ = new Subject();

	constructor(private systemNotificationsApi: SystemNotificationsApi) {}

	markAsRead(notificationIds: number[]): void {
		this.initialCall = true;
		this.systemNotificationsApi.markNotificationsAsRead(notificationIds).subscribe(() => {
			this.initSystemNotifications();
		});
	}

	getNotification(id: number): void {
		this.systemNotificationsApi.getNotification(id).subscribe((notification: SystemNotification) => {
			if (notification?.read === null) {
				this.markAsRead([notification.id]);
			}
		});
	}

	initSystemNotifications(): void {
		if (this.initialCall) {
			this.systemNotificationsApi
				.getAllNotifications()
				.pipe(takeUntil(this.destroy$))
				.subscribe((notifications: SystemNotification[]) => {
					this.setSystemNotifications(notifications);
					this.initialCall = false;
					this.initSystemNotifications();
				});
		} else {
			interval(30 * 1000)
				.pipe(
					takeUntil(this.destroy$),
					mergeMap(() => this.systemNotificationsApi.getAllNotifications())
				)
				.subscribe((notifications: SystemNotification[]) => {
					this.setSystemNotifications(notifications);
				});
		}
	}

	clearSystemNotifications(): void {
		this.initialCall = true;
		this.destroy$.next();
		this.destroy$.complete();
	}

	private setSystemNotifications(notifications): void {
		const unreadNotificationsLength = notifications.filter((el: SystemNotification) => !el.read).length;
		this.unreadNotificationsLength.next(unreadNotificationsLength);
		this.systemNotifications.next(notifications);
	}
}
