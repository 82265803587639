<div mat-dialog-title>
	<span *ngIf="!isEditing">
		{{ 'admin.user-management.heading.registration' | translate }}
	</span>
	<span *ngIf="isEditing">
		{{ 'admin.user-management.heading.editing' | translate }}
	</span>
</div>

<button class="close-modal" mat-flat-button mat-dialog-close>
	<mat-icon>close</mat-icon>
</button>

<form [formGroup]="form" class="form" [errorMessages]="errorMessages" appFormValidation>
	<div class="form-fields">
		<mat-form-field appearance="outline" class="form-field">
			<mat-label>{{ 'admin.user-management.label.firstName' | translate }}</mat-label>
			<input
				formControlName="firstName"
				matInput
				placeholder="John"
				(blur)="onBlur($event, 'firstName')"
				(input)="onInputChange($event, 'firstName')"
			/>
			<mat-error>{{ form | error : 'firstName' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="form-field">
			<mat-label>{{ 'admin.user-management.label.lastName' | translate }}</mat-label>
			<input
				formControlName="lastName"
				matInput
				placeholder="Johnson"
				(blur)="onBlur($event, 'lastName')"
				(input)="onInputChange($event, 'lastName')"
			/>
			<mat-error>{{ form | error : 'lastName' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="form-field">
			<mat-label>{{ 'login.label.email' | translate }}</mat-label>
			<input
				(change)="emailChanged()"
				formControlName="email"
				matInput
				placeholder="pat@example.com"
				oninput="this.value = this.value.toLowerCase()"
			/>
			<mat-error>{{ form | error : 'email' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="form-field">
			<mat-label>{{ 'admin.user-management.label.personalId' | translate }}</mat-label>
			<input (change)="personalIdChanged()" formControlName="personalId" matInput placeholder="19XXXXXXXXXX" />
			<mat-error>{{ form | error : 'personalId' | translate }}</mat-error>
		</mat-form-field>
	</div>

	<div class="form-actions">
		<button class="medium" mat-dialog-close mat-stroked-button>
			{{ 'admin.user-management.label.cancel' | translate }}
		</button>
		<button (click)="submitForm()" class="medium" color="primary" mat-raised-button type="submit">
			<span *ngIf="!isEditing">
				{{ 'admin.user-management.label.register' | translate }}
			</span>
			<span *ngIf="isEditing">
				{{ 'admin.user-management.label.confirm' | translate }}
			</span>
		</button>
	</div>
</form>
