<div *ngIf="data.type === 'image' && data?.src" class="media-wrap">
	<img [src]="data.src" alt="" />
</div>

<div *ngIf="data.type === 'video' && data?.src" class="media-wrap">
	<video controls disablePictureInPicture>
		<source [src]="data.src" />
	</video>
</div>

<button (click)="onClose()" mat-button mat-flat-button color="primary">{{ 'faq.close' | translate }}</button>
