import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Post } from '../../interfaces/post.interface';
import { State } from '../../store';
import { loadAdminPostsList } from '../../store/actions/admin-posts.actions';
import {
	selectAdminPostsList,
	selectAdminPostsListLoading,
	selectAdminPostsListPageNumber,
	selectAdminPostsListPagesCount,
	selectAdminPostsListTotalItemsCount,
} from '../../store/selectors/admin-posts.selectors';

@Component({
	selector: 'app-faq-page',
	templateUrl: './faq-page.component.html',
	styleUrls: ['./faq-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqPageComponent implements OnInit {
	readonly posts$: Observable<Post[]>;
	readonly loading$: Observable<boolean>;
	readonly pageNumber$: Observable<number>;
	readonly pagesCount$: Observable<number>;
	readonly totalItemsCount$: Observable<number>;

	readonly MAX_PAGE_SIZE = 20;

	constructor(private router: Router, private route: ActivatedRoute, private store: Store<State>) {
		this.posts$ = this.store.pipe(select(selectAdminPostsList));
		this.loading$ = this.store.pipe(select(selectAdminPostsListLoading));
		this.pageNumber$ = this.store.pipe(select(selectAdminPostsListPageNumber));
		this.pagesCount$ = this.store.pipe(select(selectAdminPostsListPagesCount));
		this.totalItemsCount$ = this.store.pipe(select(selectAdminPostsListTotalItemsCount));
	}

	addFeed(): void {
		this.router.navigate(['../add-post'], { relativeTo: this.route });
	}

	ngOnInit(): void {
		this.store.dispatch(loadAdminPostsList({ pageSize: this.MAX_PAGE_SIZE, pageNumber: 1 }));
	}

	onLoadMore(pageSize: number): void {
		this.store.dispatch(loadAdminPostsList({ pageSize, pageNumber: 1 }));
	}

	onLoadPrev(pageNumber: number): void {
		this.store.dispatch(loadAdminPostsList({ pageSize: this.MAX_PAGE_SIZE, pageNumber }));
	}

	onLoadNext(pageNumber: number): void {
		this.store.dispatch(loadAdminPostsList({ pageSize: this.MAX_PAGE_SIZE, pageNumber }));
	}

	onReset(): void {
		this.store.dispatch(loadAdminPostsList({ pageSize: this.MAX_PAGE_SIZE, pageNumber: 1 }));
	}
}
