import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@auth/services/auth.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { User } from '../../interfaces/user.interface';

@Component({
	selector: 'app-user-list',
	templateUrl: './user-list.component.html',
	styleUrls: ['./user-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserListComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
	@ViewChild(MatPaginator)
	paginator: MatPaginator;

	@ViewChild(MatSort)
	sort: MatSort;

	@Input()
	users: User[];

	@Output()
	loadUsers = new EventEmitter<void>();

	@Output()
	syncVacsUsers = new EventEmitter<void>();

	@Output()
	editUser = new EventEmitter<User>();

	@Output()
	deleteUser = new EventEmitter<User>();

	@Output()
	registerUser = new EventEmitter<void>();

	@Output()
	delegateUser = new EventEmitter<User>();

	isMobile: boolean;
	dataSource: MatTableDataSource<User>;
	filtersForm: FormGroup;
	isActionButtonClick: boolean;
	readonly displayedColumns = [
		{
			name: 'firstName',
			showOnMobile: true,
			filter: true,
		},
		{
			name: 'lastName',
			showOnMobile: true,
			filter: true,
		},
		{
			name: 'email',
			showOnMobile: true,
			filter: true,
		},
		{
			name: 'actions',
			showOnMobile: true,
			filter: false,
		},
	];

	private readonly destroy$ = new Subject<void>();

	constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService) {
		this.dataSource = new MatTableDataSource<User>([]);
		this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
	}

	get resolutionFilteredColumns() {
		return this.isMobile ? this.displayedColumns.filter((c) => c.showOnMobile) : this.displayedColumns;
	}

	get resolutionFilteredColumnNames() {
		return this.resolutionFilteredColumns.map((c) => c.name);
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { users } = changes;

		if (users?.currentValue) {
			this.dataSource.data = this.users;

			const formData = this.filtersForm.getRawValue();
			this.applyFilters(formData);
		}
	}

	ngOnInit(): void {
		this.breakpointObserver
			.observe(Breakpoints.Handset)
			.pipe(
				map((result) => result.matches),
				takeUntil(this.destroy$)
			)
			.subscribe((isMobile) => (this.isMobile = isMobile));

		this.setupFilterForm();

		this.filtersForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((filters) => {
			this.filtersForm.patchValue(filters, { emitEvent: false });
			this.applyFilters(filters);
		});

		this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
			if (typeof data[sortHeaderId] === 'string') {
				return data[sortHeaderId].trim().replace(/\s+/g, ' ').toLocaleLowerCase();
			}

			return data[sortHeaderId];
		};
	}

	ngAfterViewInit(): void {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

	customFilterPredicate(data: User, filters: string): boolean {
		const filterObject = filters;
		let fitsFilter = true;
		Object.keys(filterObject).forEach((column) => {
			if (data[column] && filterObject[column]) {
				const filterIncludesValue = data[column].toString().toLowerCase().includes(filterObject[column].toLowerCase());
				if (!filterIncludesValue) {
					fitsFilter = false;
				}
			}
		});

		return fitsFilter;
	}

	clear(): void {
		this.filtersForm.reset();
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	setDelegateUser(user: User): void {
		if (this.isActionButtonClick) {
			this.isActionButtonClick = false;
			return;
		}

		this.delegateUser.emit(user);
	}

	registerNewUser(): void {
		this.registerUser.emit();
	}

	edit(user: User): void {
		this.isActionButtonClick = true;
		this.editUser.emit(user);
	}

	delete(user: User): void {
		this.isActionButtonClick = true;
		this.deleteUser.emit(user);
	}

	isFullAdminUser() {
		return this.authService.isFullAdmin;
	}

	private setupFilterForm(): void {
		const formGroupObj = this.displayedColumns.reduce((acc, cur) => {
			return { ...acc, ...{ [cur.name]: new FormControl<string | null>(null) } };
		}, {});
		this.filtersForm = new FormGroup(formGroupObj);
	}

	private applyFilters(filters: any): void {
		this.dataSource.filter = filters;
	}
}
