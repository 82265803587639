<div class="feed-tab-title">
	<button (click)="addFeed()" class="large new-feed" color="primary" mat-raised-button>
		{{ 'admin.faq.addPost' | translate }}
	</button>
</div>

<ng-container *ngIf="(loading$ | async) === false; else showLoader">
	<app-post-list [data]="posts$ | async"></app-post-list>

	<app-load-more
		(loadMore)="onLoadMore($event)"
		(loadNext)="onLoadNext($event)"
		(loadPrev)="onLoadPrev($event)"
		(resetPages)="onReset()"
		*ngIf="(posts$ | async).length && (totalItemsCount$ | async) > MAX_PAGE_SIZE"
		[pageNumber]="pageNumber$ | async"
		[pagesCount]="pagesCount$ | async"
		[postsLength]="(posts$ | async).length"
		[totalItemsCount]="totalItemsCount$ | async"
	></app-load-more>
</ng-container>

<ng-template #showLoader>
	<app-waiting-spinner></app-waiting-spinner>
</ng-template>
