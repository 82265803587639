import { Action, createReducer, on } from '@ngrx/store';
import { bankIdLogin, signIn, signInFail, signInSuccess } from '../actions/auth.actions';

export interface AuthState {
	loading: boolean;
	loaded: boolean;
	error: any;
}

export const initialState: AuthState = {
	loading: false,
	loaded: false,
	error: null,
};

const reducer = createReducer(
	initialState,

	on(signIn, bankIdLogin, (state) => ({
		...state,
		loading: true,
		loaded: false,
		error: null,
	})),

	on(signInSuccess, (state) => ({
		...state,
		loading: false,
		loaded: true,
		error: null,
	})),

	on(signInFail, (state, error) => ({
		...state,
		loading: false,
		loaded: false,
		error,
	}))
);

export function authReducer(state: AuthState | undefined, action: Action) {
	return reducer(state, action);
}
