<mat-accordion *ngIf="data.length">
	<mat-expansion-panel *ngFor="let item of data; let index = index">
		<mat-expansion-panel-header>
			<div class="post-item">
				<div class="post-item-title">
					{{ item.title }}
				</div>
				<div class="post-item-actions">
					<mat-icon color="primary" (click)="editPost(item.id, $event)">edit</mat-icon>
					<mat-icon class="delete" color="warn" (click)="deletePost(item.id, $event)">delete</mat-icon>
				</div>
			</div>
		</mat-expansion-panel-header>
		<div *ngIf="item.description" [innerHTML]="item.description | trustHtml" class="post-item-description"></div>

		<div *ngIf="item.keywords" class="post-item-chips-list">
			{{ 'faq.keywords' | translate }}:
			<span class="post-item-chips-item">{{ item.keywords }}</span>
		</div>
		<div *ngIf="item.images" class="post-item-graphics-container post-item-graphics-images">
			<div *ngFor="let img of item.images" class="post-graphics">
				<img (click)="openImage('image', postGraphicURI + img)" [src]="postGraphicURI + img" alt="" />
			</div>
		</div>

		<div *ngIf="item.videos" class="post-item-graphics-container post-item-graphics-videos">
			<div *ngFor="let video of item.videos" class="post-graphics">
				<app-single-video (openMedia)="openVideo($event)" [video]="postGraphicURI + video"></app-single-video>
			</div>
		</div>
	</mat-expansion-panel>
</mat-accordion>
