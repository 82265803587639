import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../../modules/admin/interfaces/user.interface';
import { StorageKeys, StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class DelegatedUserService {
	public _delegatedUser$ = new BehaviorSubject<User>(null);

	constructor(private storageService: StorageService) {
		const user = this.storageService.get(StorageKeys.DelegatedUserInfo);
		if (user) {
			this._delegatedUser$.next(user);
		}
	}

	getDelegatedUser(): User {
		let user = this._delegatedUser$.getValue();
		if (!user) {
			user = this.storageService.get(StorageKeys.DelegatedUserInfo);
		}
		return user;
	}

	setDelegatedUser(user: User) {
		this._delegatedUser$.next(user);
		this.storageService.set(StorageKeys.DelegatedUserInfo, user);
	}

	removeDelegatedUser() {
		this._delegatedUser$.next(null);
		this.storageService.remove(StorageKeys.DelegatedUserInfo);
	}

	delegatedUserIsSelected(): boolean {
		return !!this._delegatedUser$.getValue();
	}
}
