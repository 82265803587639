import { createSelector } from '@ngrx/store';
import { selectAdminSubcategoriesListState } from '../index';
import { adminSubcategoriesAdapter, AdminSubcategoriesState } from '../reducers/admin-subcategories.reducer';

export const selectAdminSubcategoriesListLoading = createSelector(
	selectAdminSubcategoriesListState,
	(state: AdminSubcategoriesState) => state && state.loading
);

export const selectAdminSubcategoriesListLoaded = createSelector(
	selectAdminSubcategoriesListState,
	(state: AdminSubcategoriesState) => state && state.loaded
);

export const selectAdminSubcategoriesListError = createSelector(
	selectAdminSubcategoriesListState,
	(state: AdminSubcategoriesState) => state && state.error
);

export const { selectAll: selectAdminSubcategoriesList } = adminSubcategoriesAdapter.getSelectors(
	selectAdminSubcategoriesListState
);
