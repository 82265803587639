<mat-accordion>
	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>{{ 'admin.customization.group.header' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div *ngFor="let property of groupedProperties.header" class="customization-property-wrap">
			<ng-container *ngIf="property.type === 'bg-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.backgroundColor]="property.formCtrl.value" class="bg-color"></div>
				</div>
			</ng-container>
			<ng-container *ngIf="property.type === 'text-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.color]="property.formCtrl.value" class="text-color">Test text</div>
				</div>
			</ng-container>
			<ng-container *ngIf="property.type === 'logo'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" matInput type="text" />
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example logo-wrap">
					<img [src]="property.formCtrl.value" alt="" class="logo" />
				</div>
			</ng-container>

			<ng-container *ngIf="property.type === 'icon-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.backgroundColor]="property.formCtrl.value" class="bg-color"></div>
				</div>
			</ng-container>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>{{ 'admin.customization.group.footer' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div *ngFor="let property of groupedProperties.footer" class="customization-property-wrap">
			<ng-container *ngIf="property.type === 'bg-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.backgroundColor]="property.formCtrl.value" class="bg-color"></div>
				</div>
			</ng-container>
			<ng-container *ngIf="property.type === 'text-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.color]="property.formCtrl.value" class="text-color">Test text</div>
				</div>
			</ng-container>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>{{ 'admin.customization.group.loginPage' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div *ngFor="let property of groupedProperties.loginPage" class="customization-property-wrap">
			<ng-container *ngIf="property.type === 'logo'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" matInput type="text" />
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example logo-wrap">
					<img [src]="property.formCtrl.value" alt="" class="logo" />
				</div>
			</ng-container>
			<ng-container *ngIf="property.type === 'image'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" matInput type="text" />
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<img [src]="property.formCtrl.value" alt="" class="image" />
				</div>
			</ng-container>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>{{ 'admin.customization.group.homePage' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div *ngFor="let property of groupedProperties.homePage" class="customization-property-wrap">
			<ng-container *ngIf="property.type === 'video'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" matInput type="text" />
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<video
						*ngIf="imageFileFormatValidator(property.formCtrl.value)"
						[poster]="property.formCtrl.value"
						class="video"
						disablePictureInPicture
					></video>
					<video
						*ngIf="videoFileFormatValidator(property.formCtrl.value)"
						[src]="property.formCtrl.value"
						class="video"
						disablePictureInPicture
					></video>
				</div>
			</ng-container>
			<ng-container *ngIf="property.type === 'image'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" matInput type="text" />
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<img [src]="property.formCtrl.value" alt="" class="image" />
				</div>
			</ng-container>
			<ng-container *ngIf="property.type === 'icon'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" matInput type="text" />
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example icon-wrap">
					<img [src]="property.formCtrl.value" alt="" class="icon" />
				</div>
			</ng-container>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>{{ 'admin.customization.group.contractPage' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div *ngFor="let property of groupedProperties.contractPage" class="customization-property-wrap">
			<ng-container *ngIf="property.type === 'bg-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.backgroundColor]="property.formCtrl.value" class="bg-color"></div>
				</div>
			</ng-container>

			<ng-container *ngIf="property.type === 'chart-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.backgroundColor]="property.formCtrl.value" class="bg-color"></div>
				</div>
			</ng-container>

			<ng-container *ngIf="property.type === 'icon-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.backgroundColor]="property.formCtrl.value" class="bg-color"></div>
				</div>
			</ng-container>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>{{ 'admin.customization.group.detailsPage' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div *ngFor="let property of groupedProperties.detailsPage" class="customization-property-wrap">
			<ng-container *ngIf="property.type === 'icon-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.backgroundColor]="property.formCtrl.value" class="bg-color"></div>
				</div>
			</ng-container>
		</div>
	</mat-expansion-panel>

	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>{{ 'admin.customization.group.additional' | translate }}</mat-panel-title>
		</mat-expansion-panel-header>
		<div *ngFor="let property of groupedProperties.additional" class="customization-property-wrap">
			<ng-container *ngIf="property.type === 'text'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" matInput type="text" />
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.color]="property.formCtrl.value" class="text-color">Test text</div>
				</div>
			</ng-container>

			<ng-container *ngIf="property.type === 'bg-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.backgroundColor]="property.formCtrl.value" class="bg-color"></div>
				</div>
			</ng-container>

			<ng-container *ngIf="property.type === 'text-color'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" [ngxMatColorPicker]="colorPicker" matInput />
						<ngx-mat-color-toggle [for]="colorPicker" matSuffix></ngx-mat-color-toggle>
						<ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example">
					<div [style.color]="property.formCtrl.value" class="text-color">Test text</div>
				</div>
			</ng-container>

			<ng-container *ngIf="property.type === 'selector'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<mat-select
							*ngIf="property.name === customizationKeys.TaCodesToExcludeFromAdditions"
							[formControl]="property.formCtrl"
							multiple
							panelClass="operation-types"
						>
							<mat-option [value]="operationType.code" *ngFor="let operationType of operationTypes">
								{{ operationType.code }} - {{ operationType.text }}
							</mat-option>
						</mat-select>

						<mat-select
							*ngIf="property.name === customizationKeys.ClearingNumberFormat"
							[formControl]="property.formCtrl"
						>
							<mat-option [value]="value" *ngFor="let value of clearingNumberValues">
								{{ value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</ng-container>

			<ng-container *ngIf="property.type === 'date'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field color="primary">
						<input matInput [matDatepicker]="picker" [formControl]="property.formCtrl" />
						<mat-datepicker-toggle disableRipple matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
				</div>
			</ng-container>

			<ng-container *ngIf="property.type === 'icon'">
				<div class="customization-property-control">
					<div class="customization-property-control-title">
						{{ 'admin.customization.' + property.name | translate }}
					</div>
					<mat-form-field>
						<input [formControl]="property.formCtrl" matInput type="text" />
					</mat-form-field>
				</div>
				<div *ngIf="property.formCtrl.value" class="customization-property-example icon-wrap">
					<img [src]="property.formCtrl.value" alt="" class="icon" />
				</div>
			</ng-container>
		</div>
	</mat-expansion-panel>
</mat-accordion>

<button (click)="save()" class="large" color="primary" mat-raised-button>
	{{ 'admin.customization.save' | translate }}
</button>
