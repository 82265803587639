import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DEFAULT_THEME_COLOR } from '@shared/constants/style-constants-for-components';
import { CustomizationKeys } from '../../../modules/admin/enums/customization-keys.enum';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnChanges {
	@Input()
	companyInfo: CompanyInterface;

	isSomeOfSocialNetworksExists: boolean;
	defaultColor: string = DEFAULT_THEME_COLOR;
	bgColor: string;
	textColor: string;

	ngOnChanges(changes: SimpleChanges): void {
		const { companyInfo } = changes;

		if (companyInfo?.currentValue) {
			this.bgColor =
				this.companyInfo.styles[CustomizationKeys.FooterBgColor] || this.companyInfo.hexColor || this.defaultColor;
			this.textColor = this.companyInfo.styles[CustomizationKeys.FooterTextColor];
			this.isSomeOfSocialNetworksExists = Boolean(
				this.companyInfo.linkedInUrl ||
					this.companyInfo.facebookUrl ||
					this.companyInfo.instagramUrl ||
					this.companyInfo.twitterUrl
			);
		}
	}
}
