import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthApiService } from '../../services/auth-api.service';
import { bankIdLogin, signIn, signInFail, signInSuccess } from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
	readonly signIn$ = createEffect(() =>
		this.actions$.pipe(
			ofType(signIn),
			switchMap(({ data }) =>
				this.authApiService.login(data).pipe(
					map(() => signInSuccess()),
					catchError((error) => of(signInFail({ error: error.error.error })))
				)
			)
		)
	);

	readonly bankIdLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(bankIdLogin),
			switchMap((data) =>
				this.authApiService.bankIdLogin(data.companyId, data.orderRef, data.redirectUrl, data.completionData).pipe(
					map(() => signInSuccess()),
					catchError((error) => of(signInFail({ error: error.error.error })))
				)
			)
		)
	);

	readonly authSuccess$ = createEffect(() => this.actions$.pipe(ofType(signInSuccess)), { dispatch: false });

	constructor(private actions$: Actions, private authApiService: AuthApiService) {}
}
