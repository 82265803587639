import { Injectable } from '@angular/core';

@Injectable()
export class StringUtilities {
	public static splitInTwo(text: string, separator: string): { firstPart: string; secondPart: string } {
		const separatorIndex = text.indexOf(separator);

		if (separatorIndex === -1) {
			return { firstPart: text, secondPart: null };
		}

		const part1 = text.substr(0, separatorIndex).trim();
		const part2 = text.substr(separatorIndex + 1).trim();

		return { firstPart: part1, secondPart: part2 };
	}

	public static toTitleCase(text: string) {
		return text.replace(/\w\S*/g, (subString) => {
			return subString.charAt(0).toUpperCase() + subString.substr(1).toLowerCase();
		});
	}

	public static toLowerCase(items: string | string[]);
	public static toLowerCase(items: any): string | string[] {
		if (items instanceof Array) {
			const loweredRoles: string[] = [];

			for (let i = 0; i < items.length; i++) {
				loweredRoles[i] = items[i].toLowerCase();
			}

			return loweredRoles;
		} else if (typeof items === 'string' || items instanceof String) {
			return items.toLowerCase();
		}
	}

	public static expandCamelCase(text: string) {
		if (!text) {
			return text;
		}

		return text
			.replace(/([A-Z][a-z]+)/g, ' $1')
			.replace(/([A-Z][A-Z]+)/g, ' $1')
			.replace(/([^A-Za-z ]+)/g, ' $1');
	}
}
