import {
	MAT_COLOR_FORMATS,
	NGX_MAT_COLOR_FORMATS,
	NgxMatColorPickerModule,
} from '@angular-material-components/color-picker';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { RoleGuard } from '@auth/guards/role.guard';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NguCarouselModule } from '@ngu/carousel';
import { SharedModule } from '@shared/shared.module';
import { AdminRoutingModule } from './admin.routing';
import { CustomizationPropertyComponent } from './components/customization-property/customization-property.component';
import { PostCategoriesModalComponent } from './components/post-categories-modal/post-categories-modal.component';
import { PostFormComponent } from './components/post-form/post-form.component';
import { PostListComponent } from './components/post-list/post-list.component';
import { PostSubcategoriesModalComponent } from './components/post-subcategories-modal/post-subcategories-modal.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { UserModalComponent } from './components/user-modal/user-modal.component';
import { AdminInterceptor } from './interceptors/admin.interceptor';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { PostFormPageComponent } from './pages/post-form-page/post-form-page.component';
import { SettingsPageComponent } from './pages/settings-page/settings-page.component';
import { UserManagementPageComponent } from './pages/user-management-page/user-management-page.component';
import { PostDataResolver } from './resolvers/post-data.resolver';
import { AdminApiService } from './services/admin-api.service';
import { FaqApiService } from './services/faq-api.service';
import { adminFeatureKey, reducers } from './store';
import { AdminCategoriesEffects } from './store/effects/admin-categories.effects';
import { AdminPostsEffects } from './store/effects/admin-posts.effects';
import { AdminSubcategoriesEffects } from './store/effects/admin-subcategories.effects';
import { AdminEffects } from './store/effects/admin.effects';

@NgModule({
	declarations: [
		AdminPageComponent,
		UserManagementPageComponent,
		SettingsPageComponent,
		UserListComponent,
		CustomizationPropertyComponent,
		UserModalComponent,
		FaqPageComponent,
		PostListComponent,
		PostFormPageComponent,
		PostFormComponent,
		PostCategoriesModalComponent,
		PostSubcategoriesModalComponent,
	],
	imports: [
		AdminRoutingModule,
		RouterModule,
		SharedModule,
		MatExpansionModule,
		MatIconModule,
		MatTableModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatChipsModule,
		MatTooltipModule,
		StoreModule.forFeature(adminFeatureKey, reducers),
		EffectsModule.forFeature([AdminEffects, AdminPostsEffects, AdminCategoriesEffects, AdminSubcategoriesEffects]),
		NguCarouselModule,
		NgxMatColorPickerModule,
		AngularEditorModule,
		MatProgressBarModule,
		MatSortModule,
	],
	providers: [
		FaqApiService,
		PostDataResolver,
		RoleGuard,
		AdminApiService,
		{ provide: HTTP_INTERCEPTORS, useClass: AdminInterceptor, multi: true },
		{ provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
	],
})
export class AdminModule {}
