import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-medial-modal',
	templateUrl: 'media-modal.component.html',
	styleUrls: ['media-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaModalComponent {
	constructor(
		private dialogRef: MatDialogRef<MediaModalComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: { type: 'image' | 'video'; src: string }
	) {}

	onClose(): void {
		this.dialogRef.close();
	}
}
