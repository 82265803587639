import { Color } from '@angular-material-components/color-picker';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UrlParserService } from '@shared/services/url-parser.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyInterface, CompanySettings } from '../../../company/interfaces/company.interface';
import { CompanyApiService } from '../../../company/services/company-api.service';
import { loadCompany } from '../../../company/store/actions/company.actions';
import { selectCompany } from '../../../company/store/selectors/company.selectors';
import { CustomizationKeys } from '../../enums/customization-keys.enum';
import { OperationType } from '../../interfaces/operation-type.interface';
import { AdminApiService } from '../../services/admin-api.service';
import { State } from '../../store';

@Component({
	selector: 'app-settings-page',
	templateUrl: './settings-page.component.html',
	styleUrls: ['./settings-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPageComponent implements OnInit, OnDestroy {
	readonly operationTypes$ = new BehaviorSubject<OperationType[]>([]);
	readonly loading$ = new BehaviorSubject<boolean>(false);

	keys = CustomizationKeys;
	companySettingsKeys: CustomizationKeys[] = [
		CustomizationKeys.SupportEmail,
		CustomizationKeys.WebsiteTitle,
		CustomizationKeys.TaCodesToExcludeFromAdditions,
		CustomizationKeys.ClearingNumberFormat,
		CustomizationKeys.ContractCutoffDate,
	];

	companySettings: CompanySettings;

	selectedCompany$: Observable<CompanyInterface>;

	constructor(
		private store: Store<State>,
		private companyService: CompanyApiService,
		private urlParserService: UrlParserService,
		private notificationService: NotificationService,
		private translate: TranslateService,
		private adminApiService: AdminApiService
	) {}

	ngOnInit(): void {
		this.selectedCompany$ = this.store.pipe(select(selectCompany));
		this.adminApiService.getOperationTypes().subscribe((operationTypes: OperationType[]) => {
			this.operationTypes$.next(operationTypes);
		});
	}

	onSaveStyles(data): void {
		const settingsKeys = this.companySettingsKeys.map((key) => key.toString());
		const settings = settingsKeys.reduce((acc, cur) => {
			if (data.hasOwnProperty(cur)) {
				acc[cur] = data[cur];
			}
			return acc;
		}, {} as CompanySettings);

		if (settings.contractCutoffDate) {
			const utc = moment.utc(settings.contractCutoffDate).toDate();
			const local = moment(utc).local().format('YYYY-MM-DD');
			settings.contractCutoffDate = local + 'T12:00:00';
		}

		this.loading$.next(true);
		if (Object.keys(settings).length) {
			this.companyService.saveSettings({ ...this.companySettings, ...settings }).subscribe();
		}
		Object.keys(data).forEach((key) => {
			if (data[key] instanceof Color) {
				data[key] = `#${data[key].hex}`;
			}
			if (settingsKeys.includes(key)) {
				delete data[key];
			}
		});
		this.companyService.saveCustomization(data).subscribe(() => {
			this.notificationService.success({
				message: this.translate.instant('notification_messages.settings.edit'),
			});
			this.store.dispatch(loadCompany({ payload: this.urlParserService.companyName }));
			this.loading$.next(false);
		});
	}

	ngOnDestroy(): void {
		this.operationTypes$.complete();
		this.loading$.complete();
	}
}
