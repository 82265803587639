import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CompanyInterface } from '../modules/company/interfaces/company.interface';
import { State } from '../modules/company/store';
import { selectCompany } from '../modules/company/store/selectors/company.selectors';

@Component({
	selector: 'app-layout',
	templateUrl: './layout.component.html',
	styleUrls: ['./layout.components.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
	readonly company$: Observable<CompanyInterface>;

	constructor(private store: Store<State>) {
		this.company$ = this.store.pipe(select(selectCompany));
	}
}
