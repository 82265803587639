import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadFileResponse } from 'src/app/shared/interfaces/upload-file-responce.interface';
import { environment } from 'src/environments/environment';

@Injectable()
export class FileService {
	baseUrl = environment.baseURI;

	constructor(private http: HttpClient) {}

	uploadFile(file: File): Observable<HttpEvent<UploadFileResponse>> {
		const formData = new FormData();
		formData.append('file', file);

		return this.http.post<UploadFileResponse>(`${this.baseUrl}/files/upload`, formData, {
			reportProgress: true,
			observe: 'events',
		});
	}
}
