import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
	declarations: [AppComponent],
	imports: [AppRoutingModule, CoreModule.forRoot(), SharedModule],
	bootstrap: [AppComponent],
})
export class AppModule {}
