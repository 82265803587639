import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AuthRoutingModule } from '@auth/auth-routing.module';
import { AbkarlhedinPrivacyComponent } from '@auth/components/abkarlhedin-privacy/abkarlhedin-privacy.component';
import { DeromePrivacyComponent } from '@auth/components/derome-privacy/derome-privacy.component';
import { ExpiredPasswordFormComponent } from '@auth/components/expired-password-form/expired-password-form.component';
import { JgaPrivacyComponent } from '@auth/components/jga-privacy/jga-privacy.component';
import { MoelvenPrivacyComponent } from '@auth/components/moelven-privacy/moelven-privacy.component';
import { RegistrationConfirmationModalComponent } from '@auth/components/registration-cofirmation-modal/registration-confirmation-modal.component';
import { RegistrationFormComponent } from '@auth/components/registration-form/registration-form.component';
import { ResetPasswordFormComponent } from '@auth/components/reset-password-form/reset-password-form.component';
import { AuthGuard } from '@auth/guards/auth.guard';
import { AuthInterceptor } from '@auth/interceptors/auth.interceptor';
import { AuthPageComponent } from '@auth/pages/auth-page/auth-page.component';
import { ExpiredPasswordPageComponent } from '@auth/pages/expired-password-page/expired-password-page.component';
import { PrivacyPageComponent } from '@auth/pages/privacy-page/privacy-page.component';
import { RegistrationPageComponent } from '@auth/pages/registration-page/registration-page.component';
import { ResetPasswordPageComponent } from '@auth/pages/reset-password-page/reset-password-page.component';
import { AuthService } from '@auth/services/auth.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@shared/shared.module';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AuthApiService } from './services/auth-api.service';
import { authFeatureKey } from './store';
import { AuthEffects } from './store/effects/auth.effects';
import { authReducer } from './store/reducers/auth.reducer';

@NgModule({
	declarations: [
		LoginPageComponent,
		LoginFormComponent,
		RegistrationPageComponent,
		AuthPageComponent,
		RegistrationFormComponent,
		PrivacyPageComponent,
		AbkarlhedinPrivacyComponent,
		MoelvenPrivacyComponent,
		DeromePrivacyComponent,
		JgaPrivacyComponent,
		RegistrationConfirmationModalComponent,
		ExpiredPasswordPageComponent,
		ResetPasswordPageComponent,
		ExpiredPasswordFormComponent,
		ResetPasswordFormComponent,
	],
	imports: [
		EffectsModule.forFeature([AuthEffects]),
		StoreModule.forFeature(authFeatureKey, authReducer),
		SharedModule,
		RouterModule,
		AuthRoutingModule,
		MatCheckboxModule,
		MatDialogModule,
		MatTooltipModule,
	],
	providers: [
		AuthService,
		AuthApiService,
		AuthGuard,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
	],
})
export class AuthModule {
	static forRoot(): ModuleWithProviders<AuthModule> {
		return {
			ngModule: AuthModule,
		};
	}
}
