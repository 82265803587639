<div class="system-notification-header">
	<div class="system-notification-header-title">{{ 'system_notifications.title' | translate }}</div>
	<a
		mat-raised-button
		*ngIf="systemNotifications?.length"
		class="small system-notification-header-action"
		(click)="markAllAsRead.emit(systemNotifications)"
	>
		<mat-icon>done_all</mat-icon>
		{{ 'system_notifications.markAsRead' | translate }}
	</a>
</div>

<div class="system-notifications-list" *ngIf="systemNotifications?.length; else empty">
	<ng-container *ngFor="let notification of systemNotifications; let i = index; trackBy: trackById">
		<div
			class="system-notifications-item"
			*ngIf="i < 10"
			[class.read]="notification.read"
			(click)="openNotification.emit(notification)"
		>
			<ng-container [ngSwitch]="notification?.class">
				<ng-container *ngSwitchCase="notificationEventTypes.NewContractCreated">
					<mat-icon
						[style]="{
							color:
								companyInfo.styles.headerNotificationNewContractCreatedIconColor || newContractCreatedIconDefaultColor
						}"
						class="system-notification-item-icon"
						>note_add</mat-icon
					>
					<div class="system-notification-item-name">
						{{
							'system_notifications.eventType.newContractRegistered'
								| translate
									: {
											assignmentName: notification.title
									  }
						}}
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="notificationEventTypes.ContractStarted">
					<mat-icon
						[style]="{
							color:
								companyInfo.styles.headerNotificationNewContractStartedIconColor || newContractStartedIconDefaultColor
						}"
						class="system-notification-item-icon"
						>description</mat-icon
					>
					<div class="system-notification-item-name">
						{{
							'system_notifications.eventType.startWorkOnAssignment'
								| translate
									: {
											assignmentName: notification.title
									  }
						}}
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="notificationEventTypes.ContractFileAdded">
					<mat-icon
						[style]="{
							color:
								companyInfo.styles.headerNotificationNewContractFileAddedIconColor ||
								newContractFileAddedIconDefaultColor
						}"
						class="system-notification-item-icon"
						>upload_file</mat-icon
					>
					<div class="system-notification-item-name">
						{{
							'system_notifications.eventType.contractFileAdded'
								| translate
									: {
											assignmentName: notification.title
									  }
						}}
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="notificationEventTypes.AvrFileAdded">
					<mat-icon
						[style]="{
							color: companyInfo.styles.headerNotificationNewAvrFileAddedIconColor || newAvrFileAddedIconDefaultColor
						}"
						class="system-notification-item-icon avr-file-added"
						>file_present</mat-icon
					>
					<div class="system-notification-item-name">
						{{
							'system_notifications.eventType.avrFileAdded'
								| translate
									: {
											assignmentName: notification.title,
											docType: documentTypesDictionary?.entries?.get(documentTypes[notification.extra2]) | translate
									  }
						}}
					</div>
				</ng-container>
				<ng-container *ngSwitchCase="notificationEventTypes.NewInformationPosted">
					<mat-icon
						[style]="{
							color: companyInfo.styles.headerNotificationNewPostAddedIconColor || newContractCreatedIconDefaultColor
						}"
						class="system-notification-item-icon avr-file-added"
						>local_post_office</mat-icon
					>
					<div class="system-notification-item-name">
						{{ 'system_notifications.eventType.newInformationPosted' | translate }}
					</div>
				</ng-container>
			</ng-container>

			<div class="system-notification-item-time">{{ notification?.created | notificationsDate }}</div>
		</div>
	</ng-container>
</div>

<div class="system-notification-footer" *ngIf="systemNotifications?.length && systemNotifications?.length > 10">
	<div class="system-notification-footer-action">
		<a mat-raised-button class="small">
			{{ 'system_notifications.showAll' | translate }}
			<mat-icon>open_in_new</mat-icon>
		</a>
	</div>
</div>

<ng-template #empty>
	<div class="no-notifications">
		<mat-icon
			[style]="{ color: companyInfo.styles.headerEmptyNotificationsIconColor || emptyNotificationsIconDefaultColor }"
			>notifications_off</mat-icon
		>
		<div class="no-notifications-text">{{ 'system_notifications.noNotifications' | translate }}</div>
	</div>
</ng-template>
