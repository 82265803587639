import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Notification } from '../../interfaces/notification.interface';

@Component({
	selector: 'app-notification',
	templateUrl: 'notification.component.html',
	styleUrls: ['notification.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
	constructor(
		@Inject(MAT_SNACK_BAR_DATA)
		public data: Notification
	) {}
}
