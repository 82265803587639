import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@core/services/notification.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { DelegatedUserService } from '@shared/services/delegated-user.service';
import { NavigationService } from '@shared/services/navigation.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SystemNotifications } from '../../../system-notificatios/services/system-notifications.service';
import { UserModalComponent } from '../../components/user-modal/user-modal.component';
import { User } from '../../interfaces/user.interface';
import { AdminApiService } from '../../services/admin-api.service';
import { State } from '../../store';
import { loadUsersList } from '../../store/actions/admin.actions';
import { selectUsers, selectUsersLoading } from '../../store/selectors/admin.selectors';

@Component({
	selector: 'app-user-management-page',
	templateUrl: './user-management-page.component.html',
	styleUrls: ['./user-management-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserManagementPageComponent {
	readonly users$: Observable<User[]>;
	readonly loading$: Observable<boolean>;

	constructor(
		private store: Store<State>,
		private adminService: AdminApiService,
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private translate: TranslateService,
		private delegatedUserService: DelegatedUserService,
		private navigationService: NavigationService,
		private systemNotifications: SystemNotifications
	) {
		this.users$ = this.store.pipe(select(selectUsers));
		this.loading$ = this.store.pipe(select(selectUsersLoading));

		this.getUsers();
	}

	getUsers(): void {
		this.store.dispatch(loadUsersList());
	}

	syncVacsUsers(): void {
		this.adminService.syncVacsUsers().subscribe(() => {
			this.getUsers();
		});
	}

	onEditUser(user: User): void {
		this.handleDialog(user);
	}

	onDeleteUser(user: User): void {
		this.dialog
			.open(ConfirmationModalComponent, {
				panelClass: 'confirmation-modal',
				autoFocus: false,
			})
			.afterClosed()
			.pipe(filter((v) => !!v))
			.subscribe(() => {
				this.adminService.deleteUser(user.identityId).subscribe(() => {
					this.notificationService.success({
						message: this.translate.instant('notification_messages.user_management.delete'),
					});
					this.getUsers();
				});
			});
	}

	onRegisterUser(): void {
		const user: User = {} as User;
		this.handleDialog(user);
	}

	onDelegateUser(user: User) {
		this.systemNotifications.clearSystemNotifications();
		this.delegatedUserService.setDelegatedUser(user);
		this.navigationService.navigateTo(['home']);
		this.systemNotifications.initSystemNotifications();
	}

	private handleDialog(user?: User): void {
		const { ...data } = user;

		this.dialog
			.open(UserModalComponent, {
				disableClose: false,
				panelClass: 'user-modal',
				autoFocus: false,
				data,
			})
			.afterClosed()
			.subscribe((result) => {
				if (result) {
					this.getUsers();
				}
			});
	}
}
