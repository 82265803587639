import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class GoogleAnalyticsService {
	constructor(
		@Inject(DOCUMENT)
		private document: Document
	) {}

	public initializeGoogleAnalytics(trackingId: string): void {
		this.document.head.appendChild(this.getGtagScript(trackingId));
		this.document.head.appendChild(this.getGtagConfigScript(trackingId));
	}

	private getGtagScript(trackingId: string): HTMLElement {
		const gtagScript = this.document.createElement('script');
		gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
		gtagScript.async = true;

		return gtagScript;
	}

	private getGtagConfigScript(trackingId: string): HTMLElement {
		const gtagConfigScript = this.document.createElement('script');
		gtagConfigScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', '${trackingId}');
    `;
		gtagConfigScript.async = true;

		return gtagConfigScript;
	}
}
