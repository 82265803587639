import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-load-more',
	templateUrl: 'load-more.component.html',
	styleUrls: ['load-more.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadMoreComponent {
	@Input()
	readonly postsLength: number;

	@Input()
	readonly pageNumber: number;

	@Input()
	readonly pagesCount: number;

	@Input()
	readonly totalItemsCount: number;

	@Input()
	readonly showPrevNext = true;

	@Output()
	readonly loadMore = new EventEmitter<number>();

	@Output()
	readonly loadPrev = new EventEmitter<number>();

	@Output()
	readonly loadNext = new EventEmitter<number>();

	@Output()
	readonly resetPages = new EventEmitter<void>();
}
