<div class="content-container content-container-bottom-padding">
	<h1 appHeadingColor class="heading">{{ 'admin.heading' | translate }}</h1>

	<nav #tabGroup mat-tab-nav-bar color="accent">
		<ng-container *ngFor="let tab of tabs">
			<a
				mat-tab-link
				[routerLink]="tab.link"
				routerLinkActive
				#rla="routerLinkActive"
				[active]="rla.isActive"
				*ngIf="tab.show"
			>
				<mat-icon>{{ tab.icon }}</mat-icon>
				{{ isMobile ? (tab.shortName | translate) : (tab.name | translate) }}
			</a>
		</ng-container>
	</nav>

	<router-outlet></router-outlet>
</div>
