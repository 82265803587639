import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const headers = {
			'Cache-Control': 'no-cache',
			Pragma: 'no-cache',
			Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
		};

		const cacheReq = req.clone({ setHeaders: headers });
		return next.handle(cacheReq);
	}
}
