import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ForgotPasswordDto } from '@auth/interfaces/forgot-password.interface';
import { AuthApiService } from '@auth/services/auth-api.service';
import { State } from '@auth/store';
import { selectAuthLoading } from '@auth/store/selectors/auth.selectors';
import { NotificationService } from '@core/services/notification.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { responseErrorsDictionary } from '@shared/dictionaries/response-errors.dictionary';
import { NavigationService } from '@shared/services/navigation.service';
import { Observable } from 'rxjs';
import { CustomizationKeys } from '../../../modules/admin/enums/customization-keys.enum';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';
import { selectCompany, selectCompanyLoading } from '../../../modules/company/store/selectors/company.selectors';

@Component({
	selector: 'app-reset-password-page',
	templateUrl: 'reset-password-page.component.html',
	styleUrls: ['reset-password-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordPageComponent {
	readonly selectedCompany$: Observable<CompanyInterface>;
	readonly selectedCompanyLoading$: Observable<boolean>;
	readonly loading$: Observable<boolean>;

	customizationKeys = CustomizationKeys;
	token: string;
	email: string;

	constructor(
		private store: Store<State>,
		private authApiService: AuthApiService,
		private navigationService: NavigationService,
		private notificationService: NotificationService,
		private route: ActivatedRoute,
		private translate: TranslateService
	) {
		this.selectedCompany$ = this.store.pipe(select(selectCompany));
		this.selectedCompanyLoading$ = this.store.pipe(select(selectCompanyLoading));
		this.loading$ = this.store.pipe(select(selectAuthLoading));

		this.token = this.route.snapshot.queryParamMap.get('token');
		this.email = this.route.snapshot.queryParamMap.get('email');
	}

	onResetPassword(newPassword: string): void {
		const body: ForgotPasswordDto = {
			newPassword,
			token: this.token.split(' ').join('+'),
			email: this.email,
		};

		this.authApiService.resetPassword(body).subscribe(
			() => {
				this.notificationService.success({
					message: this.translate.instant('resetPassword.passwordSuccessfullyChanged'),
				});
				this.navigationService.navigateTo(['auth', 'login']);
			},
			(response: HttpErrorResponse) => {
				this.notificationService.error({
					message: this.translate.instant(responseErrorsDictionary.get(response.error.Code)),
				});
			}
		);
	}
}
