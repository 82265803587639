import { createAction, props } from '@ngrx/store';
import { Post } from '../../interfaces/post.interface';
import { PostList } from '../../interfaces/post-list.interface';

export const loadAdminPostsList = createAction(
	'[Admin Posts] Load admin posts list',
	props<{ categoryId?: number; subcategoryId?: number; pageSize?: number; pageNumber?: number }>()
);

export const loadAdminPostsListSuccess = createAction(
	'[Admin Posts] Load admin posts list success',
	props<{ data: PostList }>()
);

export const loadAdminPostsListFail = createAction(
	'[Admin Posts] Load admin posts list fail',
	props<{ error: Error }>()
);

export const upsertPost = createAction('[Admin Posts] upsert post', props<{ data: Post }>());

export const upsertPostSuccess = createAction('[Admin Posts] upsert post success', props<{ data: Post }>());

export const upsertPostFail = createAction('[Admin Posts] upsert post fail', props<{ error: string }>());

export const deletePost = createAction('[Admin Posts] delete post', props<{ id: number }>());

export const deletePostSuccess = createAction('[Admin Posts] delete post success', props<{ id: number }>());

export const deletePostFail = createAction('[Admin Posts] delete post fail', props<{ error: string }>());
