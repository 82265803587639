import { createAction, props } from '@ngrx/store';
import { CompanyInterface } from '../../interfaces/company.interface';

export const loadCompany = createAction('[Company] Load company', props<{ payload: string }>());

export const loadCompanySuccess = createAction(
	'[Company] Load company success',
	props<{ payload: CompanyInterface }>()
);

export const loadCompanyFail = createAction('[Company] Load company fail', props<{ error: Error }>());
