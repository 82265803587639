import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { PostCategory } from '../../interfaces/post-category.interface';
import { State } from '../../store';
import { deleteAdminCategory, upsertAdminCategory } from '../../store/actions/admin-categories.actions';

@Component({
	selector: 'app-post-categories-modal',
	templateUrl: 'post-categories-modal.component.html',
	styleUrls: ['post-categories-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostCategoriesModalComponent implements OnInit {
	displayedColumns = ['title', 'order', 'actions'];

	dataSource = new BehaviorSubject<AbstractControl[]>([]);
	rows: FormArray = this.fb.array([]);
	form: FormGroup = this.fb.group({ categories: this.rows });

	isEditingRow: boolean[] = [];

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<PostCategoriesModalComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: PostCategory[],
		private store: Store<State>
	) {}

	get categories(): FormArray {
		return this.form.get('categories') as FormArray;
	}

	ngOnInit() {
		this.data.forEach((item: PostCategory) => this.addRow(item, false));
		this.updateView();
	}

	addRow(item?: PostCategory, noUpdate?: boolean) {
		const row = this.fb.group({
			id: [item && item.id ? item.id : 0, []],
			title: [item && item.title ? item.title : null, [Validators.required]],
			order: [item && item.order ? item.order : null, [Validators.required]],
		});

		this.rows.push(row);
		if (!noUpdate) {
			this.updateView();
		}
	}

	updateView() {
		this.dataSource.next(this.rows.controls);
	}

	deleteRow(row: PostCategory, i: number) {
		if (row) {
			this.store.dispatch(deleteAdminCategory({ id: row.id }));
		}

		this.rows.removeAt(i);
		this.updateView();
	}

	saveRow(index: number) {
		const controls = this.rows.controls[index];

		const formData: PostCategory = {
			id: controls.get('id').value,
			title: controls.get('title').value,
			order: controls.get('order').value,
		};

		if (!formData.title) {
			this.categories.at(index).get('title').setErrors({ required: true });
			this.categories.at(index).get('title').markAllAsTouched();
			return;
		}

		if (!formData.order) {
			this.categories.at(index).get('order').setErrors({ required: true });
			this.categories.at(index).get('order').markAllAsTouched();
			return;
		}

		this.isEditingRow[index] = false;
		this.store.dispatch(upsertAdminCategory({ data: formData }));
	}

	cancelEdit(row: PostCategory, index: number): void {
		this.isEditingRow[index] = false;
		const result = row ? row : {};

		this.categories.at(index).get('title').clearValidators();
		this.categories.at(index).get('order').clearValidators();
		this.categories.at(index).get('title').updateValueAndValidity();
		this.categories.at(index).get('order').updateValueAndValidity();

		this.rows.controls[index].patchValue(result);
	}

	editRow(row: PostCategory, index: number) {
		this.isEditingRow[index] = true;
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
