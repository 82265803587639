import { CompanyNames } from '../../modules/company/enums/company.enum';
import { Company } from '../../modules/company/interfaces/company.interface';

export const COMPANIES: Company[] = [
	{
		name: 'Moelven',
		key: CompanyNames.Moelven,
		nameUrl: CompanyNames.Moelven,
	},
	{
		name: 'AB Karl Hedin',
		key: CompanyNames.KarlHedin,
		nameUrl: CompanyNames.KarlHedin,
		fontFamily: 'Arial, sans-serif',
	},
	{
		name: 'Derome',
		key: CompanyNames.Derome,
		nameUrl: CompanyNames.Derome,
		fontFamily: 'Derome, sans-serif',
	},
	{
		name: 'JGA',
		key: CompanyNames.JGA,
		nameUrl: CompanyNames.JGA,
	},
];
