import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Post } from '../interfaces/post.interface';
import { FaqApiService } from '../services/faq-api.service';

@Injectable()
export class PostDataResolver implements Resolve<Post> {
	constructor(private faqApiService: FaqApiService, private router: Router) {}

	resolve(route: ActivatedRouteSnapshot): Observable<Post> {
		const { id } = route.params;

		return this.faqApiService.getPost(id).pipe(
			catchError((err) => {
				this.router.navigateByUrl('admin');
				throw new Error(err);
			})
		);
	}
}
