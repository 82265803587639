import { createFeatureSelector, createSelector } from '@ngrx/store';
import { companyReducer, CompanyState } from './reducers/company.reducer';

export const companyFeatureKey = 'company';

export interface State {
	company: CompanyState;
}

export const reducers = {
	company: companyReducer,
};

export const selectCompanyDetails = createFeatureSelector<State>(companyFeatureKey);
export const selectCompanyState = createSelector(selectCompanyDetails, (state: State) => state && state.company);
