import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExpiredPasswordPageComponent } from '@auth/pages/expired-password-page/expired-password-page.component';
import { PrivacyPageComponent } from '@auth/pages/privacy-page/privacy-page.component';
import { ResetPasswordPageComponent } from '@auth/pages/reset-password-page/reset-password-page.component';
import { AuthPageComponent } from './pages/auth-page/auth-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { RegistrationPageComponent } from '@auth/pages/registration-page/registration-page.component';

export const routes: Routes = [
	{
		path: '',
		component: AuthPageComponent,
		children: [
			{
				path: '',
				redirectTo: 'login',
				pathMatch: 'full',
			},
			{
				path: 'login',
				component: LoginPageComponent,
			},
			{
				path: 'registration',
				component: RegistrationPageComponent,
			},
			{
				path: 'reset-password',
				component: ResetPasswordPageComponent,
			},
			{
				path: 'expired-password/:email',
				component: ExpiredPasswordPageComponent,
			},
			{
				path: 'privacy',
				component: PrivacyPageComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuthRoutingModule {}
