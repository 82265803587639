export const responseErrorsDictionary = new Map<number | string, string>();
responseErrorsDictionary.set(574654, 'responseErrors.574654');
responseErrorsDictionary.set(448754, 'responseErrors.448754');
responseErrorsDictionary.set(547676, 'responseErrors.547676');
responseErrorsDictionary.set(476423, 'responseErrors.476423');
responseErrorsDictionary.set(476424, 'responseErrors.476424');
responseErrorsDictionary.set(467234, 'responseErrors.467234');
responseErrorsDictionary.set(547235, 'responseErrors.547235');
responseErrorsDictionary.set(965340, 'responseErrors.965340');
responseErrorsDictionary.set(965341, 'responseErrors.965341');
responseErrorsDictionary.set('invalid_grant', 'responseErrors.invalid_grant');
responseErrorsDictionary.set('PasswordExpired', 'responseErrors.password_expired');
