import { Directive, HostListener } from '@angular/core';

@Directive({ selector: '[appViewportWatch]' })
export class ViewportWatchDirective {
	constructor() {
		this.setViewHeight();
	}

	@HostListener('window:scroll', ['$event'])
	onScroll() {
		this.setViewHeight();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.setViewHeight();
	}

	setViewHeight() {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
}
