export const environment = {
	production: true,
	baseURI: 'https://stakeholder-services-dev.azurewebsites.net/api',
	originURI: 'https://stakeholder-dev.azurewebsites.net',
	identityURI: 'https://vacs-identity-dev.azurewebsites.net',
	identityClientId: 'vacs_stakeholder_client',
	identityScope: 'openid vacs_stakeholder_api.full IdentityServerApi',
	filesURI: 'https://stakeholder-services-dev.azurewebsites.net',
	appInsights: {
		instrumentationKey: '4cdd3baa-2414-4e10-9fc9-fcb3ae53862c',
	},
	postGraphicURI: 'https://stakeholder-dev.azureedge.net/',
};
