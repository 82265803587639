import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'app-empty',
	templateUrl: 'empty.component.html',
	styleUrls: ['empty.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyComponent {
	@Input()
	readonly value: string;

	@Input()
	readonly customClass: string;
}
