import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DelegatedUserService } from '@shared/services/delegated-user.service';
import { NavigationService } from '@shared/services/navigation.service';
import { StorageKeys, StorageService } from '@shared/services/storage.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, from, interval, Observable, Subscription } from 'rxjs';
import { UserRole } from '../enums/user-role.enum';

@Injectable()
export class AuthService {
	redirectUrl;

	returnUrl$ = new BehaviorSubject<string>(null);

	private offsetSeconds = 30;
	private refreshSubscription: Subscription;
	private _userRole: UserRole;

	private readonly _isAuthenticated$ = new BehaviorSubject<boolean>(false);

	constructor(
		private oauthService: OAuthService,
		private router: Router,
		private delegatedUserService: DelegatedUserService,
		private http: HttpClient,
		private navigationService: NavigationService,
		private storageService: StorageService
	) {}

	get isAuthenticated$(): Observable<boolean> {
		this._isAuthenticated$.next(this.oauthService.hasValidAccessToken());
		return this._isAuthenticated$.asObservable();
	}

	get isAuthenticated(): boolean {
		return this.oauthService.hasValidAccessToken();
	}

	get isUser(): boolean {
		return this._userRole === UserRole.USER;
	}

	get isCompanyAdmin(): boolean {
		return this._userRole === UserRole.COMPANY_ADMIN;
	}

	get isCompanyAdminLight(): boolean {
		return this._userRole === UserRole.COMPANY_ADMIN_LIGHT;
	}

	get isSystemAdmin(): boolean {
		return this._userRole === UserRole.SYSTEM_ADMIN;
	}
	get isFullAdmin(): boolean {
		return this._userRole === UserRole.SYSTEM_ADMIN || this._userRole === UserRole.COMPANY_ADMIN;
	}
	get returnUrl(): string {
		return this.returnUrl$.value;
	}

	scheduleRefresh(): void {
		const source: Observable<number> = interval(this.calcDelay(this.getAuthTime()));

		this.refreshSubscription = source.subscribe(() => {
			this.oauthService
				.refreshToken()
				.then(() => {})
				.catch(() => {
					this.handleRefreshTokenError();
				});
		});
	}

	checkRole(roles: string[]): void {
		roles.forEach((role: string) => {
			switch (role) {
				case UserRole.USER: {
					this._userRole = role;
					break;
				}
				case UserRole.COMPANY_ADMIN: {
					this._userRole = role;
					break;
				}
				case UserRole.COMPANY_ADMIN_LIGHT: {
					this._userRole = role;
					break;
				}
				case UserRole.SYSTEM_ADMIN: {
					this._userRole = role;
					break;
				}
			}
		});
	}

	init(): void {
		this._isAuthenticated$.next(true);
	}

	refreshToken() {
		return from(this.oauthService.refreshToken());
	}

	handleRefreshTokenError() {
		this.redirectUrl = this.router.url;

		this.unscheduleRefresh();
		this._isAuthenticated$.next(false);
		this.removeUserInfo();

		return this.navigationService.navigateTo(['auth']);
	}

	unscheduleRefresh(): void {
		if (this.refreshSubscription) {
			this.refreshSubscription.unsubscribe();
		}
	}

	calcDelay(time: number): number {
		const expiresAt: number = this.oauthService.getAccessTokenExpiration();
		const delay: number = expiresAt - time - this.offsetSeconds * 1000;
		return delay > 0 ? delay : 0;
	}

	getAuthTime(): number {
		return parseInt(localStorage.getItem('access_token_stored_at'), 10);
	}

	removeUserInfo(): void {
		this.storageService.remove(StorageKeys.UserInfo);
		this.delegatedUserService.removeDelegatedUser();
	}

	logout(): void {
		this.oauthService.logOut(true);
		this._isAuthenticated$.next(false);
		this.returnUrl$.next(null);
		this.unscheduleRefresh();
		this.removeUserInfo();
	}
}
