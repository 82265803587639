import { Injectable } from '@angular/core';
import { COMPANIES } from '@shared/constants/companies';
import { StorageKeys, StorageService } from '@shared/services/storage.service';
import { UrlParserService } from '@shared/services/url-parser.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompanyNames } from '../enums/company.enum';
import { Company, CompanyInterface } from '../interfaces/company.interface';
import { CompanySandboxService } from './company-sandbox.service';

@Injectable()
export class CompanyHelperService {
	readonly maxCompanyCacheLifeTime = 1000 * 60 * 30;

	companyData$: Observable<CompanyInterface> = this.companySandboxService.getCompany();

	constructor(
		private urlParserService: UrlParserService,
		private companySandboxService: CompanySandboxService,
		private storageService: StorageService
	) {}

	get cachedCompany() {
		return this.storageService.get(StorageKeys.CompanyInfo);
	}

	get cachedCompanyTimer() {
		return this.storageService.get(StorageKeys.CachedCompanyTime);
	}

	get isCachedCompanyOld() {
		if (!this.cachedCompanyTimer) {
			return true;
		}
		return Date.now() - this.cachedCompanyTimer > this.maxCompanyCacheLifeTime;
	}

	get currentCompany() {
		switch (true) {
			case this.isMoelvenUrl:
				return this.moelven;
			case this.isDeromeUrl:
				return this.derome;
			case this.isABKHUrl:
				return this.karlHedin;
			case this.isJGAUrl:
				return this.jga;
		}
	}

	get derome(): Company {
		return COMPANIES.find((company) => company.key === CompanyNames.Derome);
	}

	get karlHedin(): Company {
		return COMPANIES.find((company) => company.key === CompanyNames.KarlHedin);
	}

	get moelven(): Company {
		return COMPANIES.find((company) => company.key === CompanyNames.Moelven);
	}

	get jga(): Company {
		return COMPANIES.find((company) => company.key === CompanyNames.JGA);
	}

	get isDeromeUrl() {
		return this.urlParserService.companyName === this.derome.nameUrl;
	}

	get isABKHUrl() {
		return this.urlParserService.companyName === this.karlHedin.nameUrl;
	}

	get isMoelvenUrl() {
		return this.urlParserService.companyName === this.moelven.nameUrl;
	}

	get isJGAUrl() {
		return this.urlParserService.companyName === this.jga.nameUrl;
	}

	async getCompanyData() {
		return this.companyData$.pipe(take(1)).toPromise();
	}
}
