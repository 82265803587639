import { BankIdCheckStatusResult } from '@auth/interfaces/bankid-check-status-result.interface';
import { BankIdInitResult } from '@auth/interfaces/bankid-init-result.interface';
import { AuthApiService } from '@auth/services/auth-api.service';
import { BankIdGetQrResult } from '@auth/interfaces/bankid-get-qr-result.interface';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export abstract class BankidAuthBase {
	readonly qrCodeErrorCode$ = new BehaviorSubject<string>(null);
	readonly qrCodePath$ = new BehaviorSubject<SafeResourceUrl>(null);
	readonly actionResultAvailable$ = new BehaviorSubject<boolean>(false);
	orderRef: string;
	qrStartToken: string;
	count = 0;
	companyId;
	isCanceled = false;
	timerId = 0;

	protected constructor(protected authService: AuthApiService, protected sanitizer: DomSanitizer) {}

	protected onSignInWithTheSameDevice(companyId: number = null): void {
		this.authService.initForBankIdLogin(true, false, '').subscribe((response: BankIdInitResult) => {
			this.orderRef = response.orderRef;
			this.actionResultAvailable$.next(true);
			this.isCanceled = false;
			window.location.assign(response.redirectUri);
			setTimeout(() => {
				this.onSuccessStatus(companyId, response);
			}, 4000);
		});
	}

	protected onSignInWithQr(companyId: number = null): void {
		this.companyId = companyId;
		this.authService.initForBankIdLogin(false, true, '').subscribe((response: BankIdInitResult) => {
			this.orderRef = response.orderRef;
			this.qrStartToken = response.qrStartState;
			this.actionResultAvailable$.next(true);
			this.setQrCode(response.qrCodeAsBase64);
			this.isCanceled = false;
			this.timerId = setTimeout(() => {
				this.checkStatus();
			}, 1000);
		});
	}

	protected checkStatus(): void {
		if (this.orderRef) {
			this.authService.checkStatus(this.orderRef).subscribe((result: BankIdCheckStatusResult) => {
				if (!this.isCanceled) {
					if (result.status == 'pending' && result.hintCode == 'outstandingTransaction') {
						this.refreshQR();
						this.timerId = setTimeout(() => {
							this.checkStatus();
						}, 1000);
					} else if (result.status == 'pending' && result.hintCode == 'userSign') {
						// TODO: show 'waiting' image instead of QR as in bankId test env
						this.timerId = setTimeout(() => {
							this.checkStatus();
						}, 1000);
					} else if (result.status == 'failed' && result.hintCode == 'startFailed') {
						if (this.count < 2) {
							this.count++;
							this.authService.initForBankIdLogin(false, true, '').subscribe((result: BankIdInitResult) => {
								this.orderRef = result.orderRef;
								this.qrStartToken = result.qrStartState;
								this.actionResultAvailable$.next(true);
								this.setQrCode(result.qrCodeAsBase64);
								this.timerId = setTimeout(() => {
									this.checkStatus();
								}, 1000);
							});
						} else {
							this.count = 0;
							this.checkQrCodeError(result.hintCode, result.status);
						}
					} else if (result.status == 'failed') {
						this.checkQrCodeError(result.hintCode, result.status);
					} else {
						this.onSuccessStatus(null, result);
					}
				}
			});
		}
	}

	protected refreshQR(): void {
		this.authService.getQR(this.qrStartToken).subscribe((result: BankIdGetQrResult) => {
			if (!this.isCanceled) {
				this.setQrCode(result.qrCodeAsBase64);
			}
		});
	}

	protected setQrCode(content: string): void {
		if (content) {
			this.qrCodePath$.next(this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/jpg;base64,${content}`));
		} else {
			this.qrCodePath$.next(null);
		}
	}

	protected checkQrCodeError(hintCode, status): void {
		if (hintCode === 'startFailed' && status === 'failed') {
			this.qrCodeErrorCode$.next('RFA17B');
		} else if ((status === 'failed' && hintCode === null) || hintCode === undefined) {
			this.qrCodeErrorCode$.next('RFA22');
		} else {
			this.onCancel();
		}
	}

	protected onCancelCommon(): void {
		clearTimeout(this.timerId);
		this.isCanceled = true;
		this.actionResultAvailable$.next(false);
		this.qrCodeErrorCode$.next(null);
		this.setQrCode(null);

		if (this.orderRef) {
			this.authService.cancelBankIdLogin(this.orderRef).subscribe();
		}

		this.orderRef = null;
	}
	abstract onSuccessStatus(companyId, result: BankIdCheckStatusResult | BankIdInitResult): void;
	abstract onCancel(): void;
}
