export enum CustomizationPropertyType {
	Image = 'image',
	Icon = 'icon',
	Logo = 'logo',
	BgColor = 'bg-color',
	TextColor = 'text-color',
	Video = 'video',
	Text = 'text',
	Checkbox = 'checkbox',
	ChartColor = 'chart-color',
	Selector = 'selector',
	IconColor = 'icon-color',
	Date = 'date',
}
