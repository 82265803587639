import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageKeys, StorageService } from '@shared/services/storage.service';
import { UrlParserService } from '@shared/services/url-parser.service';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyInterceptor implements HttpInterceptor {
	constructor(private storageService: StorageService, private urlParserService: UrlParserService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const company = this.storageService.get(StorageKeys.CompanyInfo);
		const urlCompanyName = this.urlParserService.companyName;

		let headers = {};
		if (company && company.id && urlCompanyName === company.nameUrl) {
			headers = {
				'Company-ID': company.id.toString(),
			};
		}

		const clonedReq = req.clone({ setHeaders: headers });

		return next.handle(clonedReq);
	}
}
