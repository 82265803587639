import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorMessages } from '@shared/interfaces/form-error-messages.interface';
import { CustomValidators } from '@shared/utilities/custom-validators.util';

@Component({
	selector: 'app-reset-password-form',
	templateUrl: 'reset-password-form.component.html',
	styleUrls: ['reset-password-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordFormComponent {
	@Output()
	resetPassword = new EventEmitter<string>();

	form: FormGroup;
	hidePassword = true;
	hideConfirmPassword = true;

	readonly errorMessages: FormErrorMessages = {
		password: {
			required: 'common.form.errors.required',
			hasNumber: 'common.form.errors.hasNumber',
			hasCapitalCase: 'common.form.errors.hasCapitalCase',
			hasSmallCase: 'common.form.errors.hasSmallCase',
			hasSpecialCharacters: 'common.form.errors.hasSpecialCharacters',
			minlength: 'common.form.errors.passwordMinLength',
			noBlancSpace: 'common.form.errors.noBlancSpace',
			englishLettersOnly: 'common.form.errors.englishLettersOnly',
		},
		confirmPassword: {
			required: 'common.form.errors.required',
			noPasswordMatch: 'common.form.errors.noPasswordMatch',
		},
	};

	constructor(private fb: FormBuilder) {
		this.initForm();
	}

	submit(): void {
		if (this.form.invalid) {
			return;
		}

		const { password } = this.form.getRawValue();

		this.resetPassword.emit(password);
	}

	private initForm(): void {
		this.form = this.fb.group(
			{
				password: new FormControl<string | null>(null, [
					Validators.required,
					CustomValidators.patternValidator(/^\S*$/, {
						noBlancSpace: true,
					}),
					CustomValidators.englishLettersOnlyValidator(/^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9\s-]+$/, {
						englishLettersOnly: true,
					}),
					CustomValidators.patternValidator(/\d/, {
						hasNumber: true,
					}),
					CustomValidators.patternValidator(/[A-Z]/, {
						hasCapitalCase: true,
					}),
					CustomValidators.patternValidator(/[a-z]/, {
						hasSmallCase: true,
					}),
					CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
						hasSpecialCharacters: true,
					}),
					Validators.minLength(8),
				]),
				confirmPassword: new FormControl<string | null>(null, Validators.required),
			},
			{
				validators: CustomValidators.passwordMatchValidator,
			}
		);
	}
}
