import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@core/services/notification.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { FileType } from '@shared/enums/file-type.enum';
import { ImageFileFormatValidator } from '@shared/validators/image-file-format.validator';
import { VideoFileFormatValidator } from '@shared/validators/video-file-format.validator';
import { editorConfig } from '../../config/editor.config';
import { PostCategory } from '../../interfaces/post-category.interface';
import { PostSubcategory } from '../../interfaces/post-subcategory.interface';
import { Post } from '../../interfaces/post.interface';
import { PostCategoriesModalComponent } from '../post-categories-modal/post-categories-modal.component';
import { PostSubcategoriesModalComponent } from '../post-subcategories-modal/post-subcategories-modal.component';

@Component({
	selector: 'app-post-form',
	templateUrl: './post-form.component.html',
	styleUrls: ['./post-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostFormComponent implements OnChanges {
	@ViewChild('uploadImage')
	uploadImageTag: ElementRef;

	@ViewChild('uploadVideo')
	uploadVideoTag: ElementRef;

	@Input()
	mode: 'add' | 'edit' = 'add';

	@Input()
	data: Post;

	@Input()
	loading: boolean;

	@Input()
	categoriesList: PostCategory[];

	@Input()
	subcategoriesList: PostSubcategory[];

	@Input()
	selectedImages: string[];

	@Input()
	selectedVideos: string[];

	@Input()
	videoLoading: boolean;

	@Input()
	imageLoading: boolean;

	@Input()
	percentDone: string;

	@Output()
	createPost = new EventEmitter<Post>();

	@Output()
	updatePost = new EventEmitter<Post>();

	@Output()
	updateCategories = new EventEmitter<void>();

	@Output()
	updateSubcategories = new EventEmitter<void>();

	@Output()
	uploadImage = new EventEmitter<File>();

	@Output()
	uploadVideo = new EventEmitter<File>();

	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	readonly editorConfig = editorConfig;
	readonly postGraphicURI = environment.postGraphicURI;

	addOnBlur = true;
	feedForm = new FormGroup({
		title: new FormControl<string | null>(null, [Validators.required]),
		description: new FormControl<string | null>(null),
		images: new FormControl<string[] | null>(null),
		videos: new FormControl<string[] | null>(null),
		categoryId: new FormControl<number | null>(null),
		subcategoryId: new FormControl<number | null>(null),
		keywords: new FormControl<string[] | null>(null),
		sendNotification: new FormControl<boolean>(true),
	});

	fileType = FileType;
	images: string[];
	subcategories: PostSubcategory[];
	private readonly MAX_IMAGE_SIZE = 10;
	private readonly MAX_VIDEO_SIZE = 1000;
	private readonly iMAGE_MIN_HEIGHT = 255;
	private readonly iMAGE_MIN_WIDTH = 446;

	constructor(
		private dialog: MatDialog,
		private notificationService: NotificationService,
		private translate: TranslateService
	) {}

	get keywords(): AbstractControl {
		return this.feedForm.get('keywords');
	}

	get categoryId(): AbstractControl {
		return this.feedForm.get('categoryId');
	}

	ngOnChanges(changes: SimpleChanges): void {
		const { subcategoriesList, mode, selectedImages, selectedVideos } = changes;

		if (mode?.currentValue === 'edit') {
			const normalizedData = this.getNormalizedFormData();
			this.feedForm.patchValue(normalizedData, { emitEvent: true, onlySelf: false });
		}

		if (selectedImages?.currentValue) {
			this.uploadImageTag.nativeElement.value = '';
		}

		if (selectedVideos?.currentValue) {
			this.uploadVideoTag.nativeElement.value = '';
		}

		if (subcategoriesList?.currentValue) {
			this.getSubcategoriesList(this.categoryId.value);
		}

		this.feedForm.get('images').patchValue(this.selectedImages);
		this.feedForm.get('videos').patchValue(this.selectedVideos);
	}

	changeCategory({ value }): void {
		this.getSubcategoriesList(value);
	}

	onFileSelected(files: FileList, type: FileType): void {
		if (type === FileType.Image) {
			for (const file of files) {
				this.checkUploadedImage(file);
			}
		}

		if (type === FileType.Video) {
			for (const file of files) {
				this.checkUploadedVideo(file);
			}
		}
	}

	onSubmit(): void {
		if (this.feedForm.invalid) {
			return;
		}

		const { title, description, images, videos, categoryId, subcategoryId, keywords, sendNotification } =
			this.feedForm.getRawValue();
		const post: Post = {
			id: this.data?.id ? this.data.id : 0,
			title,
			description,
			images,
			videos,
			categoryId,
			subcategoryId,
			keywords: keywords?.length ? keywords.join(', ') : null,
			sendNotification: sendNotification,
		};

		this.mode === 'add' ? this.createPost.emit(post) : this.updatePost.emit(post);
	}

	removeFile(type: FileType, index: number) {
		if (type === FileType.Image) {
			this.selectedImages.splice(index, 1);
		}

		if (type === FileType.Video) {
			this.selectedVideos.splice(index, 1);
		}
	}

	add(event: MatChipInputEvent): void {
		const { input, value } = event;

		if ((value || '').trim()) {
			if (this.keywords.value) {
				this.keywords.setValue([...this.feedForm.get('keywords').value, value.trim()]);
			} else {
				this.keywords.setValue([value.trim()]);
			}
			this.keywords.updateValueAndValidity();
		}

		if (input) {
			input.value = '';
		}
	}

	remove(keyword: string): void {
		const index = this.keywords.value.indexOf(keyword);

		if (index >= 0) {
			this.keywords.value.splice(index, 1);
			this.keywords.updateValueAndValidity();
		}
	}

	editCategories(): void {
		this.dialog
			.open(PostCategoriesModalComponent, {
				data: this.categoriesList,
				panelClass: 'categories-modal',
				autoFocus: false,
			})
			.afterClosed()
			.subscribe(() => {
				this.updateCategories.emit();
			});
	}

	editSubcategories(): void {
		const subcategoriesData = {
			categoriesList: this.categoriesList.map((category: PostCategory) => ({ id: category.id, title: category.title })),
			subcategoriesList: this.subcategoriesList,
		};
		this.dialog
			.open(PostSubcategoriesModalComponent, {
				data: subcategoriesData,
				panelClass: 'subcategories-modal',
				autoFocus: false,
			})
			.afterClosed()
			.subscribe(() => {
				this.updateSubcategories.emit();
			});
	}

	private getSubcategoriesList(categoryId: number): void {
		this.subcategories = this.subcategoriesList.filter((el) => el.categoryId === categoryId);

		if (!this.subcategories.length) {
			this.feedForm.get('subcategoryId').disable();
		} else {
			this.feedForm.get('subcategoryId').enable();
		}
	}

	private getNormalizedFormData() {
		let normalizedData = {};

		for (const key of Object.keys(this.data)) {
			switch (this.data[key]) {
				case this.data.keywords: {
					normalizedData = {
						...normalizedData,
						[key]: this.data.keywords ? this.data.keywords.split(', ') : '',
					};
					break;
				}
				default: {
					normalizedData = { ...normalizedData, [key]: this.data[key] };
				}
			}
		}

		return normalizedData;
	}

	private checkUploadedImage(file: File): void {
		if (ImageFileFormatValidator(file.type)) {
			if (this.getFileSize(file.size) < this.MAX_IMAGE_SIZE) {
				this.validateImageDimensions(file);
			} else {
				this.notificationService.error({
					message: this.translate.instant('notification_messages.faq.image_size', { value: this.MAX_IMAGE_SIZE }),
				});
			}
		} else {
			this.notificationService.error({ message: this.translate.instant('notification_messages.faq.image_format') });
		}

		if (this.uploadImageTag) {
			this.uploadImageTag.nativeElement.value = '';
		}
	}

	private validateImageDimensions(file: File): void {
		const img: HTMLImageElement = new Image();
		img.src = URL.createObjectURL(file);

		img.onload = () => {
			const width: number = img.naturalWidth;
			const height: number = img.naturalHeight;

			if (width < this.iMAGE_MIN_HEIGHT || height < this.iMAGE_MIN_WIDTH) {
				this.notificationService.error({
					message: this.translate.instant('notification_messages.faq.image_dimensions', { value: this.MAX_IMAGE_SIZE }),
				});
			} else {
				this.uploadImage.emit(file);
			}
		};
	}

	private checkUploadedVideo(file: File): void {
		if (VideoFileFormatValidator(file.type)) {
			if (this.getFileSize(file.size) < this.MAX_VIDEO_SIZE) {
				this.uploadVideo.emit(file);
			} else {
				this.notificationService.error({
					message: this.translate.instant('notification_messages.faq.video_size', { value: this.MAX_VIDEO_SIZE }),
				});
			}
		} else {
			this.notificationService.error({ message: this.translate.instant('notification_messages.faq.video_format') });
		}

		if (this.uploadVideoTag) {
			this.uploadVideoTag.nativeElement.value = '';
		}
	}

	private getFileSize(size): number {
		return Math.round(size / (1024 * 1024));
	}
}
