<ng-container *ngIf="(selectedCompanyLoading$ | async) === false; else showLoader">
	<ng-container *ngIf="selectedCompany$ | async as selectedCompany">
		<div
			[ngStyle]="{ 'background-image': 'url(' + selectedCompany?.styles?.loginPageBg + ')' }"
			class="auth registration"
		>
			<div class="auth-container">
				<div
					[style.backgroundImage]="
						selectedCompany?.styles[customizationKeys?.LoginPageLogo] || selectedCompany?.logoUrl | cssSrc
					"
					class="company-logo"
				></div>
				<div class="auth-content">
					<div class="registration-title">{{ 'registration.title' | translate }}</div>
					<p class="registration-text">{{ 'registration.text' | translate }}</p>

					<p class="registration-text">
						{{ 'registration.support' | translate }}:
						<a href="mailto:{{ selectedCompany?.settings?.supportEmail || selectedCompany?.email }}">{{
							selectedCompany?.settings?.supportEmail || selectedCompany?.email
						}}</a>
					</p>

					<app-registration-form
						(registerUser)="registerUser($event)"
						[selectedCompany]="selectedCompany"
					></app-registration-form>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>

<ng-template #showLoader>
	<app-waiting-spinner></app-waiting-spinner>
</ng-template>
