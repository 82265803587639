import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { HttpUtilities } from '@shared/utilities/http-utilities';
import { Observable } from 'rxjs';
import { CompanyHelperService } from '../../company/services/company-helper.service';
import { OperationType } from '../interfaces/operation-type.interface';
import { User } from '../interfaces/user.interface';

@Injectable({
	providedIn: 'root',
})
export class AdminApiService {
	baseUrl = environment.baseURI;

	constructor(
		private http: HttpClient,
		private translateService: TranslateService,
		private companyHelperService: CompanyHelperService
	) {}

	getUsers(): Observable<User[]> {
		return this.http.get<User[]>(`${this.baseUrl}/admin/getusers`);
	}

	getUserByEmail(email: string): Observable<User> {
		const params = HttpUtilities.genParams({ email });
		return this.http.get<User>(`${this.baseUrl}/admin/getuserbyemail`, { params });
	}

	checkPersonalId(personalId: string): Observable<boolean> {
		const params = HttpUtilities.genParams({ personalId });
		return this.http.get<boolean>(`${this.baseUrl}/admin/checkPersonalId`, { params });
	}

	updateUser(user: User): Observable<void> {
		return this.http.post<void>(`${this.baseUrl}/admin/updateuser`, user);
	}

	registerUser(user: User): Observable<void> {
		const postData: any = user;
		postData.returnUrl = window.location.href;
		postData.emailOptions = {
			companyId: this.companyHelperService.cachedCompany.id,
			cultureCode: this.translateService.currentLang || 'en',
			application: 'Stakeholder',
		};
		const params = HttpUtilities.genParams({ validateEmail: true });
		return this.http.post<void>(`${this.baseUrl}/user/register`, user, { params });
	}

	syncVacsUsers(): Observable<any> {
		const body = { returnUrl: window.location.href };
		return this.http.post<any>(`${this.baseUrl}/admin/syncvacsusers`, { body });
	}

	deleteUser(id: string): Observable<void> {
		const params = HttpUtilities.genParams({ id });
		return this.http.delete<void>(`${this.baseUrl}/admin/deleteuser`, { params });
	}

	getOperationTypes(): Observable<OperationType[]> {
		return this.http.get<OperationType[]>(`${this.baseUrl}/contract/getoperationtypes`);
	}
}
