import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { responseErrorsDictionary } from '@shared/dictionaries/response-errors.dictionary';
import { FormErrorMessages } from '@shared/interfaces/form-error-messages.interface';
import { Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User } from '../../interfaces/user.interface';
import { AdminApiService } from '../../services/admin-api.service';

@Component({
	selector: 'app-user-modal',
	templateUrl: './user-modal.component.html',
	styleUrls: ['./user-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserModalComponent implements OnInit, OnDestroy {
	form: FormGroup;
	isEditing: boolean;

	readonly errorMessages: FormErrorMessages = {
		email: {
			required: 'common.form.errors.required',
			pattern: 'common.form.errors.invalidEmailFormat',
		},
		firstName: {
			required: 'common.form.errors.required',
		},
		lastName: {
			required: 'common.form.errors.required',
		},
		personalId: {
			required: 'common.form.errors.required',
			pattern: 'admin.user-management.error.personalIdInvalidFormat',
			notFoundInVacs: 'admin.user-management.error.personalIdNotFoundInVacs',
		},
	};

	private readonly destroy$: Subject<void> = new Subject<void>();

	constructor(
		private adminService: AdminApiService,
		private dialogRef: MatDialogRef<UserModalComponent>,
		@Inject(MAT_DIALOG_DATA) private data: User,
		private notificationService: NotificationService,
		private translate: TranslateService
	) {
		this.isEditing = !!this.data && Object.keys(this.data).length !== 0;
	}

	get firstNameCtrl(): AbstractControl {
		return this.form.get('firstName');
	}

	get lastNameCtrl(): AbstractControl {
		return this.form.get('lastName');
	}

	get emailCtrl(): AbstractControl {
		return this.form.get('email');
	}

	get personalIdCtrl(): AbstractControl {
		return this.form.get('personalId');
	}

	ngOnInit(): void {
		this.initForm();

		if (this.isEditing) {
			this.form.patchValue(this.data, { emitEvent: true, onlySelf: false });
		}
	}

	onBlur(event: any, control: string): void {
		let value = event.target.value;
		value = value.trim();
		value = value.replace(/\s+/g, ' ');

		if (control === 'firstName') {
			this.firstNameCtrl.setValue(value);
		}

		if (control === 'lastName') {
			this.lastNameCtrl.setValue(value);
		}
	}

	onInputChange(event: any, control: string): void {
		const value = event.target.value;
		const modifiedValue = this.removeExtraWhitespaces(value);

		if (control === 'firstName') {
			this.firstNameCtrl.setValue(modifiedValue);
		}

		if (control === 'lastName') {
			this.lastNameCtrl.setValue(modifiedValue);
		}
	}

	removeExtraWhitespaces(text: string): string {
		return text.replace(/\s{2,}/g, ' ');
	}

	submitForm(): void {
		if (this.form.invalid) {
			return;
		}

		const formData = this.form.getRawValue();
		formData.identityId = this.data.identityId;

		if (this.isEditing) {
			this.adminService.updateUser(formData).subscribe(() => {
				this.notificationService.success({
					message: this.translate.instant('notification_messages.user_management.edit'),
				});
				this.dialogRef.close(true);
			});
		} else {
			this.adminService.registerUser(formData).subscribe(
				() => {
					this.notificationService.success({
						message: this.translate.instant('notification_messages.user_management.create'),
					});
					this.dialogRef.close(true);
				},
				(response: HttpErrorResponse) => {
					this.notificationService.error({
						message: this.translate.instant(responseErrorsDictionary.get(response.error.Code)),
					});
				}
			);
		}
	}

	emailChanged(): void {
		if (this.emailCtrl.valid) {
			this.adminService
				.getUserByEmail(this.emailCtrl.value)
				.pipe(
					catchError((err) => {
						if (err.status === 404) {
							this.emailCtrl.setErrors({ alreadyUsed: true });
						}
						return throwError(err);
					})
				)
				.subscribe();
		}
	}

	personalIdChanged(): void {
		if (this.personalIdCtrl.valid) {
			this.adminService
				.checkPersonalId(this.personalIdCtrl.value)
				.pipe(
					catchError((err) => {
						if (err.status === 404) {
							this.personalIdCtrl.setErrors({ notFoundInVacs: true });
						}
						return throwError(err);
					})
				)
				.subscribe();
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private initForm(): void {
		this.form = new FormGroup({
			email: new FormControl<string | null>({ value: null, disabled: this.isEditing }, [
				Validators.required,
				Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
			]),
			firstName: new FormControl<string | null>(null, [Validators.required]),
			lastName: new FormControl<string | null>(null, [Validators.required]),
			personalId: new FormControl<string | null>(null, [Validators.required, Validators.pattern('^([0-9]{12})$')]),
		});
	}
}
