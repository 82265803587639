import { Injectable } from '@angular/core';
import { HttpResponseBase, HttpParams } from '@angular/common/http';

import { StringUtilities } from './string-utilities';

@Injectable()
export class HttpUtilities {
	public static checkNoNetwork(response: HttpResponseBase) {
		if (response instanceof HttpResponseBase) {
			return response.status === 0;
		}

		return false;
	}

	public static checkAccessDenied(response: HttpResponseBase) {
		if (response instanceof HttpResponseBase) {
			return response.status === 403;
		}

		return false;
	}

	public static checkNotFound(response: HttpResponseBase) {
		if (response instanceof HttpResponseBase) {
			return response.status === 404;
		}

		return false;
	}

	public static checkIsLocalHost(url: string, base?: string) {
		if (url) {
			const location = new URL(url, base);
			return location.hostname === 'localhost' || location.hostname === '127.0.0.1';
		}

		return false;
	}

	public static genParams(params: object, httpParams = new HttpParams()): HttpParams {
		const keys = Object.getOwnPropertyNames(params);
		if (keys.length === 0) {
			return httpParams;
		}
		keys.forEach((key) => {
			if (params[key] !== undefined) {
				if (params[key] instanceof Date) {
					httpParams = httpParams.set(key, params[key].toISOString());
				} else {
					httpParams = httpParams.set(key, params[key]);
				}
			}
		});
		return httpParams;
	}

	public static getQueryParamsFromString(paramString: string) {
		if (!paramString) {
			return null;
		}

		const params: { [key: string]: string } = {};

		for (const param of paramString.split('&')) {
			const keyValue = StringUtilities.splitInTwo(param, '=');
			params[keyValue.firstPart] = keyValue.secondPart;
		}

		return params;
	}

	public static baseUrl() {
		let base = '';

		if (window.location.origin) {
			base = window.location.origin;
		} else {
			base =
				window.location.protocol +
				'//' +
				window.location.hostname +
				(window.location.port ? ':' + window.location.port : '');
		}

		return base.replace(/\/$/, '');
	}

	public static testIsAbsoluteUrl(url: string) {
		const r = new RegExp('^(?:[a-z]+:)?//', 'i');
		return r.test(url);
	}

	public static convertToAbsoluteUrl(url: string) {
		return HttpUtilities.testIsAbsoluteUrl(url) ? url : '//' + url;
	}
}
