<ng-container *ngIf="(selectedCompanyLoading$ | async) === false; else showLoader">
	<ng-container *ngIf="selectedCompany$ | async as selectedCompany">
		<div
			[ngStyle]="{ 'background-image': 'url(' + selectedCompany.styles[customizationKeys.LoginPageBg] + ')' }"
			class="auth privacy"
		>
			<div class="auth-container">
				<div
					[style.backgroundImage]="
						selectedCompany.styles[customizationKeys.LoginPageLogo] || selectedCompany.logoUrl | cssSrc
					"
					class="company-logo"
				></div>
				<div class="auth-content">
					<app-abkarlhedin-privacy *ngIf="selectedCompany.nameUrl === companyNames.KarlHedin"></app-abkarlhedin-privacy>
					<app-moelven-privacy *ngIf="selectedCompany.nameUrl === companyNames.Moelven"></app-moelven-privacy>
					<app-derome-privacy *ngIf="selectedCompany.nameUrl === companyNames.Derome"></app-derome-privacy>
					<app-jga-privacy *ngIf="selectedCompany.nameUrl === companyNames.JGA"></app-jga-privacy>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>

<ng-template #showLoader>
	<app-waiting-spinner></app-waiting-spinner>
</ng-template>
