<div class="modal-title" mat-dialog-title>{{ 'admin.faq.categoriesModal.title' | translate }}</div>

<button (click)="onClose()" class="close-modal" mat-flat-button>
	<mat-icon>close</mat-icon>
</button>

<form [formGroup]="form">
	<table [dataSource]="dataSource" formArrayName="categories" mat-table>
		<ng-container matColumnDef="title">
			<th *matHeaderCellDef mat-header-cell>{{ 'admin.faq.categoriesModal.tableTitleColumn' | translate }}</th>
			<td *matCellDef="let element; let index = index" [formGroup]="element" mat-cell>
				<mat-form-field [appearance]="!isEditingRow[index] ? 'none' : 'legacy'" floatLabel="never">
					<input [readonly]="!isEditingRow[index]" formControlName="title" matInput required />
					<mat-error>{{ 'required' | translate }}</mat-error>
				</mat-form-field>
			</td>
		</ng-container>

		<ng-container matColumnDef="order">
			<th *matHeaderCellDef mat-header-cell>{{ 'admin.faq.categoriesModal.tableOrderColumn' | translate }}</th>
			<td *matCellDef="let element; let index = index" [formGroup]="element" mat-cell>
				<mat-form-field [appearance]="!isEditingRow[index] ? 'none' : 'legacy'" floatLabel="never">
					<input [readonly]="!isEditingRow[index]" formControlName="order" matInput required />
					<mat-error>{{ 'required' | translate }}</mat-error>
				</mat-form-field>
			</td>
		</ng-container>
		<ng-container matColumnDef="actions">
			<th *matHeaderCellDef mat-header-cell>{{ 'admin.faq.categoriesModal.tableActionsColumn' | translate }}</th>
			<td *matCellDef="let element; let index = index" [formGroup]="element" mat-cell>
				<div class="row-actions">
					<mat-icon
						(click)="editRow(data[index], index)"
						*ngIf="!isEditingRow[index]"
						color="primary"
						matTooltip="{{ 'admin.faq.categoriesModal.tooltipEdit' | translate }}"
						matTooltipPosition="above"
						>edit
					</mat-icon>
					<mat-icon
						(click)="deleteRow(data[index], index)"
						*ngIf="!isEditingRow[index]"
						color="primary"
						matTooltip="{{ 'admin.faq.categoriesModal.tooltipDelete' | translate }}"
						matTooltipPosition="above"
						>delete
					</mat-icon>
					<mat-icon
						(click)="saveRow(index)"
						*ngIf="isEditingRow[index]"
						color="primary"
						matTooltip="{{ 'admin.faq.categoriesModal.tooltipSave' | translate }}"
						matTooltipPosition="above"
						>save
					</mat-icon>
					<mat-icon
						(click)="cancelEdit(data[index], index)"
						*ngIf="isEditingRow[index]"
						color="primary"
						matTooltip="{{ 'admin.faq.categoriesModal.tooltipCancel' | translate }}"
						matTooltipPosition="above"
						>close
					</mat-icon>
				</div>
			</td>
		</ng-container>

		<tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
		<tr *matRowDef="let row; let i = index; columns: displayedColumns" mat-row></tr>
	</table>

	<div class="add-row">
		<button (click)="addRow()" class="medium" color="primary" mat-raised-button type="button">
			{{ 'admin.faq.categoriesModal.addRow' | translate }}
		</button>
	</div>
</form>
