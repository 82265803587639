import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { SystemNotificationsApi } from '../modules/system-notificatios/services/system-notifications-api.service';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SystemNotificationsContainerComponent } from './components/system-notifications-container/system-notifications-container.component';
import { LayoutComponent } from './layout.component';

@NgModule({
	declarations: [LayoutComponent, FooterComponent, HeaderComponent, SystemNotificationsContainerComponent],
	imports: [RouterModule, BrowserModule, SharedModule, MatBadgeModule],
	providers: [SystemNotificationsApi],
})
export class LayoutModule {}
