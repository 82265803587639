import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'cssSrc',
})
export class CssSrcPipe implements PipeTransform {
	public transform(value: string): string {
		return value ? `url(${value})` : '';
	}
}
