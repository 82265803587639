import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RegistrationDto } from '@auth/interfaces/registration-dto.interface';
import { FormErrorMessages } from '@shared/interfaces/form-error-messages.interface';
import { NavigationService } from '@shared/services/navigation.service';
import { UrlParserService } from '@shared/services/url-parser.service';
import { CustomValidators } from '@shared/utilities/custom-validators.util';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';

@Component({
	selector: 'app-registration-form',
	templateUrl: 'registration-form.component.html',
	styleUrls: ['registration-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationFormComponent {
	@Input()
	readonly selectedCompany: CompanyInterface;

	@Output()
	readonly registerUser = new EventEmitter<RegistrationDto>();

	form: FormGroup;
	hidePassword = true;
	hideConfirmPassword = true;
	checked = false;

	readonly errorMessages: FormErrorMessages = {
		firstName: {
			required: 'common.form.errors.required',
		},
		lastName: {
			required: 'common.form.errors.required',
		},
		email: {
			required: 'common.form.errors.required',
			pattern: 'common.form.errors.invalidEmailFormat',
		},
		password: {
			required: 'common.form.errors.required',
			hasNumber: 'common.form.errors.hasNumber',
			hasCapitalCase: 'common.form.errors.hasCapitalCase',
			hasSmallCase: 'common.form.errors.hasSmallCase',
			hasSpecialCharacters: 'common.form.errors.hasSpecialCharacters',
			minlength: 'common.form.errors.passwordMinLength',
			noBlancSpace: 'common.form.errors.noBlancSpace',
			englishLettersOnly: 'common.form.errors.englishLettersOnly',
		},
		confirmPassword: {
			required: 'common.form.errors.required',
			noPasswordMatch: 'common.form.errors.noPasswordMatch',
		},
		acceptTerms: {
			required: 'common.form.errors.required',
		},
	};

	get firstNameCtrl(): AbstractControl {
		return this.form.get('firstName');
	}

	get lastNameCtrl(): AbstractControl {
		return this.form.get('lastName');
	}

	constructor(private fb: FormBuilder, private navigationService: NavigationService) {
		this.initForm();
	}

	openTerms(): void {
		const link = this.navigationService.getLinkPath(['/auth/privacy']);
		let result;

		if (UrlParserService.isOwnOrigin(window.location)) {
			result = '/auth/privacy';
		} else {
			result = link.join('');
		}
		window.open(result, '_blank');
	}

	onBlur(event: any, control: string): void {
		let value = event.target.value;
		value = value.trim();
		value = value.replace(/\s+/g, ' ');

		if (control === 'firstName') {
			this.firstNameCtrl.setValue(value);
		}

		if (control === 'lastName') {
			this.lastNameCtrl.setValue(value);
		}
	}

	onInputChange(event: any, control: string): void {
		const value = event.target.value;
		const modifiedValue = this.removeExtraWhitespaces(value);

		if (control === 'firstName') {
			this.firstNameCtrl.setValue(modifiedValue);
		}

		if (control === 'lastName') {
			this.lastNameCtrl.setValue(modifiedValue);
		}
	}

	removeExtraWhitespaces(text: string): string {
		return text.replace(/\s{2,}/g, ' ');
	}

	submit(): void {
		if (this.form.invalid) {
			return;
		}

		const { firstName, lastName, email, password } = this.form.getRawValue();

		const data: RegistrationDto = {
			firstName,
			lastName,
			email,
			password,
			returnUrl: null,
			emailOptions: {
				companyId: this.selectedCompany.id,
				companyName: this.selectedCompany.name,
				cultureCode: null,
				application: 'Stakeholder',
			},
		};

		this.registerUser.emit(data);
	}

	private initForm(): void {
		this.form = this.fb.group(
			{
				firstName: new FormControl('', Validators.required),
				lastName: new FormControl<string | null>(null, Validators.required),
				email: new FormControl<string | null>(null, [
					Validators.required,
					Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
				]),
				password: new FormControl<string | null>(null, [
					Validators.required,
					CustomValidators.englishLettersOnlyValidator(/^[~`!@#$%^&*()_+=[\]\{}|;':",.\/<>?a-zA-Z0-9\s-]+$/, {
						englishLettersOnly: true,
					}),
					CustomValidators.patternValidator(/^\S*$/, {
						noBlancSpace: true,
					}),
					CustomValidators.patternValidator(/\d/, {
						hasNumber: true,
					}),
					CustomValidators.patternValidator(/[A-Z]/, {
						hasCapitalCase: true,
					}),
					CustomValidators.patternValidator(/[a-z]/, {
						hasSmallCase: true,
					}),
					CustomValidators.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {
						hasSpecialCharacters: true,
					}),
					Validators.minLength(8),
				]),
				confirmPassword: new FormControl<string | null>(null, Validators.required),
			},
			{
				validators: CustomValidators.passwordMatchValidator,
			}
		);
	}
}
