import { AngularEditorConfig } from '@kolkov/angular-editor';

export const editorConfig: AngularEditorConfig = {
	editable: true,
	spellcheck: true,
	height: 'auto',
	minHeight: '300px',
	maxHeight: 'auto',
	width: 'auto',
	minWidth: '0',
	translate: 'yes',
	enableToolbar: true,
	showToolbar: true,
	defaultParagraphSeparator: '',
	defaultFontName: '',
	defaultFontSize: '',
	fonts: [
		{ class: 'arial', name: 'Arial' },
		{ class: 'times-new-roman', name: 'Times New Roman' },
		{ class: 'calibri', name: 'Calibri' },
		{ class: 'comic-sans-ms', name: 'Comic Sans MS' },
	],
	sanitize: false,
	toolbarPosition: 'top',
	toolbarHiddenButtons: [['insertImage'], ['insertVideo'], ['toggleEditorMode'], ['customClasses']],
};
