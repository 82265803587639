import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'notificationsDate',
})
export class NotificationsDate implements PipeTransform {
	transform(date: Date): string {
		if (this.isToday(date)) {
			return new DatePipe('en-US').transform(date, 'HH:mm');
		} else if (this.isCurrentYearOlder(date)) {
			return new DatePipe('en-US').transform(date, 'd MMM yyyy');
		} else {
			return new DatePipe('en-US').transform(date, 'd MMM');
		}
	}

	private isToday(date: Date): boolean {
		return new Date().toDateString() === new Date(date).toDateString();
	}

	private isCurrentYearOlder(date: Date): boolean {
		const currentDate: Date = new Date();
		const prevDate: Date = new Date(date);

		return currentDate.getFullYear() > prevDate.getFullYear();
	}
}
