import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CompanyEffects } from './store/effects/company.effects';
import { CompanyInfoGuard } from './guard/company.guard';
import { CompanyApiService } from './services/company-api.service';
import { CompanyInterceptor } from './interceptors/company.interceptor';
import { CompanyHelperService } from './services/company-helper.service';
import { CompanySandboxService } from './services/company-sandbox.service';
import { companyFeatureKey, reducers } from './store';

@NgModule({
	imports: [StoreModule.forFeature(companyFeatureKey, reducers), EffectsModule.forFeature([CompanyEffects])],
	providers: [
		CompanyInfoGuard,
		CompanyApiService,
		CompanyHelperService,
		CompanySandboxService,
		{ provide: HTTP_INTERCEPTORS, useClass: CompanyInterceptor, multi: true },
	],
})
export class CompanyModule {}
