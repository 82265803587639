<ng-container *ngIf="(selectedCompanyLoading$ | async) === false; else showLoader">
	<ng-container *ngIf="selectedCompany$ | async as selectedCompany">
		<div
			[ngStyle]="{ 'background-image': 'url(' + selectedCompany.styles[customizationKeys.LoginPageBg] + ')' }"
			class="auth login"
		>
			<div class="auth-container">
				<div
					[style.backgroundImage]="
						selectedCompany.styles[customizationKeys.LoginPageLogo] || selectedCompany.logoUrl | cssSrc
					"
					class="company-logo"
				></div>
				<div class="auth-content">
					<div class="login-title">{{ 'login.welcome' | translate }}</div>

					<ng-container *ngIf="!loginMethod; else password">
						<div class="login-subtitle">{{ 'login.method' | translate }}:</div>
						<div class="login-actions">
							<button
								(click)="selectLoginMethod('password')"
								class="large"
								color="primary"
								mat-raised-button
								type="button"
							>
								{{ 'login.password' | translate }}
								<mat-icon>password</mat-icon>
							</button>
							<button
								(click)="selectLoginMethod('qrcode')"
								class="large"
								color="primary"
								mat-raised-button
								type="submit"
							>
								{{ 'login.bankId' | translate }}
								<mat-icon>qr_code</mat-icon>
							</button>
						</div>
						<div class="login-navigate">
							<a [routerLink]="['auth', 'registration'] | routerLink">
								{{ 'login.register' | translate }}
								<mat-icon>chevron_right</mat-icon>
							</a>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>

<ng-template #showLoader>
	<app-waiting-spinner></app-waiting-spinner>
</ng-template>

<ng-template #password>
	<ng-container *ngIf="loginMethod !== 'qrcode'; else bankId">
		<app-login-form
			(goBack)="goBack()"
			(signIn)="onSignIn($event)"
			(sendEmailToResetPassword)="sendEmailToResetPassword($event)"
			[selectedCompany]="selectedCompany$ | async"
			[errors]="errorMessages$ | async"
			[loading]="loading$ | async"
		></app-login-form>
	</ng-container>
</ng-template>

<ng-template #bankId>
	<app-bankid-login
		(cancel)="onCancel()"
		(goBack)="goBack()"
		(signInWithQr)="onSignInWithQr($event)"
		(signInWithTheSameDevice)="onSignInWithTheSameDevice($event)"
		[actionResultAvailable]="actionResultAvailable$ | async"
		[qrCodePath]="qrCodePath$ | async"
		[selectedCompany]="selectedCompany$ | async"
		[qrCodeErrorCode]="qrCodeErrorCode$ | async"
	></app-bankid-login>
</ng-template>
