import { createFeatureSelector, createSelector } from '@ngrx/store';
import { adminPostsReducer, AdminPostsState } from './reducers/admin-posts.reducer';
import { adminCategoriesReducer, AdminCategoriesState } from './reducers/admin-categories.reducer';
import { adminSubcategoriesReducer, AdminSubcategoriesState } from './reducers/admin-subcategories.reducer';
import { usersReducer, UserState } from './reducers/admin.reducer';

export const adminFeatureKey = 'admin';

export interface State {
	adminPosts: AdminPostsState;
	adminCategories: AdminCategoriesState;
	adminSubcategories: AdminSubcategoriesState;
	users: UserState;
}

export const reducers = {
	adminPosts: adminPostsReducer,
	adminCategories: adminCategoriesReducer,
	adminSubcategories: adminSubcategoriesReducer,
	users: usersReducer,
};

export const selectAdminState = createFeatureSelector<State>(adminFeatureKey);
export const selectAdminPostsListState = createSelector(selectAdminState, (state: State) => state && state.adminPosts);
export const selectAdminCategoriesListState = createSelector(
	selectAdminState,
	(state: State) => state && state.adminCategories
);
export const selectAdminSubcategoriesListState = createSelector(
	selectAdminState,
	(state: State) => state && state.adminSubcategories
);
export const selectUsersListState = createSelector(selectAdminState, (state: State) => state && state.users);
