import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { RegistrationConfirmationModal } from '@auth/interfaces/registration-confirmation-modal.interface';
import { AuthApiService } from '@auth/services/auth-api.service';
import { NotificationService } from '@core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { BankidAuthBase } from '@shared/components/bankid-login/bankid-auth-base';
import { responseErrorsDictionary } from '@shared/dictionaries/response-errors.dictionary';
import { NavigationService } from '@shared/services/navigation.service';
import { StorageService } from '@shared/services/storage.service';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';

@Component({
	selector: 'app-registration-confirmation-modal',
	templateUrl: 'registration-confirmation-modal.component.html',
	styleUrls: ['registration-confirmation-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationConfirmationModalComponent extends BankidAuthBase implements OnDestroy {
	constructor(
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: RegistrationConfirmationModal,
		protected sanitizer: DomSanitizer,
		private storageService: StorageService,
		private dialogRef: MatDialogRef<RegistrationConfirmationModalComponent>,
		private notificationService: NotificationService,
		private translate: TranslateService,
		private navigationService: NavigationService,
		protected authService: AuthApiService
	) {
		super(authService, sanitizer);
		const { id } = this.storageService.get('company') as CompanyInterface;
		this.companyId = id;
	}

	onCancel(): void {
		this.onCancelCommon();
	}

	onSuccessStatus(companyId, result): void {
		const { body } = this.data;
		if (result && result.completionData) {
			body.personalNumber = result.completionData.user.personalNumber;
		} else {
			body.orderRef = this.orderRef;
		}
		this.authService.registerByOrderRef(body).subscribe(
			() => {
				this.notificationService.success({
					message: this.translate.instant('registration.notifications.accountCreated'),
				});
				this.navigationService.navigateTo(this.data.navigateTo);
				this.onClose();
			},
			(response: HttpErrorResponse) => {
				this.notificationService.error({
					message: this.translate.instant(responseErrorsDictionary.get(response.error.Code)),
				});

				if (response.error.Code === 547235) {
					this.navigationService.navigateTo(this.data.navigateTo);
				}
				this.onClose();
			}
		);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	ngOnDestroy(): void {
		this.qrCodeErrorCode$.complete();
		this.qrCodePath$.complete();
		this.actionResultAvailable$.complete();
	}
}
