import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegistrationConfirmationModalComponent } from '@auth/components/registration-cofirmation-modal/registration-confirmation-modal.component';
import { RegistrationDto } from '@auth/interfaces/registration-dto.interface';
import { State } from '@auth/store';
import { selectAuthLoading } from '@auth/store/selectors/auth.selectors';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { CustomizationKeys } from '../../../modules/admin/enums/customization-keys.enum';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';
import { selectCompany, selectCompanyLoading } from '../../../modules/company/store/selectors/company.selectors';

@Component({
	selector: 'app-registration-page',
	templateUrl: 'registration-page.component.html',
	styleUrls: ['registration-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationPageComponent {
	readonly selectedCompany$: Observable<CompanyInterface>;
	readonly selectedCompanyLoading$: Observable<boolean>;
	readonly loading$: Observable<boolean>;

	customizationKeys = CustomizationKeys;

	constructor(private store: Store<State>, private dialog: MatDialog, private translate: TranslateService) {
		this.selectedCompany$ = this.store.pipe(select(selectCompany));
		this.selectedCompanyLoading$ = this.store.pipe(select(selectCompanyLoading));
		this.loading$ = this.store.pipe(select(selectAuthLoading));
	}

	registerUser(data: RegistrationDto): void {
		let url = window.location.href;
		url = url.replace(/\/[^\/]*$/, '/login');

		data.emailOptions.cultureCode = this.translate.currentLang || 'en';
		data.returnUrl = url;

		this.dialog.open(RegistrationConfirmationModalComponent, {
			panelClass: 'confirmation-modal',
			autoFocus: false,
			disableClose: true,
			data: {
				title: this.translate.instant('confirmationModal.registration.title'),
				text: this.translate.instant('confirmationModal.registration.text'),
				body: data,
				navigateTo: ['auth', 'login'],
			},
		});
	}
}
