import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
	providedIn: 'root',
})
export class AppInsightsService {
	appInsights: ApplicationInsights;

	constructor() {
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: environment.appInsights.instrumentationKey,
				enableAutoRouteTracking: true,
			},
		});
		this.appInsights.loadAppInsights();
	}

	logPageView(name?: string, url?: string) {
		this.appInsights.trackPageView({
			name,
			uri: url,
		});
	}

	logEvent(name: string, properties?: { [key: string]: any }) {
		this.appInsights.trackEvent({ name }, properties);
	}

	logMetric(name: string, average: number, properties?: { [key: string]: any }) {
		this.appInsights.trackMetric({ name, average }, properties);
	}

	logException(exception: Error, severityLevel?: number) {
		this.appInsights.trackException({ exception, severityLevel });
	}

	logTrace(message: string, properties?: { [key: string]: any }) {
		this.appInsights.trackTrace({ message }, properties);
	}
}
