import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '@shared/services/navigation.service';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PostList } from '../../interfaces/post-list.interface';
import { FaqApiService } from '../../services/faq-api.service';
import {
	deletePost,
	deletePostFail,
	deletePostSuccess,
	loadAdminPostsList,
	loadAdminPostsListFail,
	loadAdminPostsListSuccess,
	upsertPost,
	upsertPostFail,
	upsertPostSuccess,
} from '../actions/admin-posts.actions';

@Injectable()
export class AdminPostsEffects {
	readonly getAdminPosts$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadAdminPostsList),
			switchMap(({ categoryId, subcategoryId, pageSize, pageNumber }) =>
				this.faqApiService.getAllPosts(categoryId, subcategoryId, pageSize, pageNumber).pipe(
					map((data: PostList) => loadAdminPostsListSuccess({ data })),
					catchError((error: Error) => of(loadAdminPostsListFail({ error })))
				)
			)
		)
	);

	readonly upsertPost$ = createEffect(() =>
		this.actions$.pipe(
			ofType(upsertPost),
			switchMap((payload) =>
				this.faqApiService.upsertPost(payload.data).pipe(
					map((data) => upsertPostSuccess({ data })),
					tap(() => {
						this.notificationService.success({ message: this.translate.instant('notification_messages.faq.save') });
						this.navagation.navigateTo(['admin', 'faq']);
					}),
					catchError((error) => of(upsertPostFail(error)))
				)
			)
		)
	);

	readonly deletePost$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deletePost),
			switchMap(({ id }) =>
				this.faqApiService.deletePost(id).pipe(
					map(() => deletePostSuccess({ id })),
					tap(() => {
						this.notificationService.success({ message: this.translate.instant('notification_messages.faq.delete') });
						this.navagation.navigateTo(['admin', 'faq']);
					}),
					catchError((error) => of(deletePostFail(error)))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private faqApiService: FaqApiService,
		private navagation: NavigationService,
		private notificationService: NotificationService,
		private translate: TranslateService
	) {}
}
