import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NotificationComponent } from '../components/notification/notification.component';
import { NotificationTypes } from '../enums/notification-types.enum';
import { Notification } from '../interfaces/notification.interface';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	constructor(private snackBar: MatSnackBar) {}

	public success(data: Notification): void {
		this.showNotification(NotificationTypes.SUCCESS, data);
	}

	public error(data: Notification): void {
		this.showNotification(NotificationTypes.ERROR, data);
	}

	public warning(data: Notification): void {
		this.showNotification(NotificationTypes.WARNING, data);
	}

	public info(data: Notification): void {
		this.showNotification(NotificationTypes.INFO, data);
	}

	private showNotification(type: NotificationTypes, data: Notification): void {
		this.snackBar.openFromComponent(NotificationComponent, this.notificationsConfig(type, data));
	}

	private notificationsConfig(type: NotificationTypes, data: Notification): MatSnackBarConfig<Notification> {
		const config = new MatSnackBarConfig<Notification>();

		config.verticalPosition = 'top';
		config.horizontalPosition = 'right';
		config.duration = 4000;

		switch (type) {
			case NotificationTypes.SUCCESS: {
				config.panelClass = 'success';
				config.data = { message: 'Success', icon: 'check', ...data };
				break;
			}
			case NotificationTypes.ERROR: {
				config.panelClass = 'error';
				config.data = { message: 'Error', icon: 'error', ...data };
				break;
			}
			case NotificationTypes.WARNING: {
				config.panelClass = 'warning';
				config.data = { message: 'Warning', icon: 'warning', ...data };
				break;
			}
			case NotificationTypes.INFO: {
				config.panelClass = 'info';
				config.data = { message: 'Info', icon: 'info', ...data };
				break;
			}
		}

		return config;
	}
}
