import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
	AfterViewInit,
	ANIMATION_MODULE_TYPE,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Renderer2,
	ViewChild,
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { AuthService } from '@auth/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface NavigationTab {
	link: string;
	name: string;
	shortName: string;
	index: number;
	icon: string;
	show: boolean;
}

@Component({
	selector: 'app-admin-page',
	templateUrl: './admin-page.component.html',
	styleUrls: ['./admin-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: ANIMATION_MODULE_TYPE, useValue: 'NoopAnimations' }],
})
export class AdminPageComponent implements AfterViewInit {
	@ViewChild('tabGroup', { static: false }) tabGroup: MatTabGroup;

	private readonly destroyed$ = new Subject<void>();

	readonly tabs: NavigationTab[] = [
		{
			name: 'admin.tabs.userManagement',
			shortName: 'admin.tabs.userManagementShort',
			link: 'user-management',
			index: 0,
			icon: 'group',
			show: true,
		},
		{
			name: 'admin.tabs.settings',
			shortName: 'admin.tabs.settingsShort',
			link: 'settings',
			index: 1,
			icon: 'settings',
			show: this.isFullAdminUser(),
		},
		{
			name: 'admin.tabs.faq',
			shortName: 'admin.tabs.faqShort',
			link: 'faq',
			index: 2,
			icon: 'info',
			show: this.isFullAdminUser(),
		},
	];

	isMobile: boolean;

	constructor(
		private authService: AuthService,
		private cdRef: ChangeDetectorRef,
		private breakpointObserver: BreakpointObserver,
		private renderer: Renderer2,
		private el: ElementRef
	) {
		this.breakpointObserver
			.observe(['(max-width: 500px)'])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(({ matches }: BreakpointState) => {
				this.isMobile = matches;
				this.cdRef.markForCheck();
			});
	}

	ngAfterViewInit(): void {
		const arrows = this.el.nativeElement.querySelectorAll('.mat-tab-header-pagination');
		arrows.forEach((arrow, index) => {
			this.renderer.listen(arrow, 'click', (event: Event) => {
				this.handleArrowClick(index === 0);
			});
		});
	}

	isFullAdminUser() {
		return this.authService.isFullAdmin;
	}

	private handleArrowClick(isLeftArrow: boolean): void {
		if (isLeftArrow && this.tabGroup.selectedIndex > 0) {
			this.tabGroup.selectedIndex = this.tabGroup.selectedIndex - 1;
			this.cdRef.detectChanges();
		} else if (!isLeftArrow && this.tabGroup.selectedIndex < this.tabGroup['_items'].length - 1) {
			this.tabGroup.selectedIndex = this.tabGroup.selectedIndex + 1;
			this.cdRef.detectChanges();
		}
	}
}
