import { Directive, DoCheck, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FormErrorMessages } from '../interfaces/form-error-messages.interface';

@Directive({
	selector: '[appFormValidation]',
})
export class FormValidationDirective implements DoCheck {
	@Input()
	public readonly formGroup: FormGroup;

	@Input()
	public readonly errorMessages: FormErrorMessages;

	private readonly defaultErrorKey = 'default';

	public ngDoCheck(): void {
		this.setValidationErrors(this.formGroup, this.errorMessages);
	}

	private setValidationErrors(formGroup: FormGroup, errorMessages): void {
		if (!formGroup || formGroup.untouched || formGroup.valid || !errorMessages) {
			return;
		}

		Object.keys(formGroup.controls).forEach((field: string) => {
			const ctrl: AbstractControl = formGroup.controls[field];

			if (ctrl.invalid && ctrl instanceof FormGroup) {
				const messages = { entities: errorMessages[field] };
				return this.setValidationErrors(ctrl, messages);
			}

			if (!ctrl || ctrl.untouched || !ctrl.errors) {
				return;
			}

			const key = Object.keys(ctrl.errors)[0];
			const fieldErrors = errorMessages[field];

			if (!fieldErrors || key === field) {
				return;
			}

			const error: string = fieldErrors[key] || fieldErrors[this.defaultErrorKey];

			if (error) {
				ctrl.setErrors({ [field]: error });
			}
		});
	}
}
