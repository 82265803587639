import { Injectable } from '@angular/core';

export enum StorageKeys {
	CompanyInfo = 'company',
	CachedCompanyTime = 'cachedCompanyTime',
	DelegatedUserInfo = 'delegatedUserInfo',
	UserInfo = 'userInfo',
	Language = 'language',
}

@Injectable()
export class StorageService {
	storage: Storage;

	keys = StorageKeys;

	constructor() {
		this.storage = localStorage;
	}

	get(key) {
		const data = this.storage.getItem(key);
		try {
			return JSON.parse(data);
		} catch (e) {
			return data;
		}
	}

	set(key, data) {
		this.storage.setItem(key, JSON.stringify(data));
	}

	remove(key) {
		this.storage.removeItem(key);
	}
}
