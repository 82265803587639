import { createSelector } from '@ngrx/store';
import { selectAdminPostsListState } from '../index';
import { adminPostsAdapter, AdminPostsState } from '../reducers/admin-posts.reducer';

export const selectAdminPostsListLoading = createSelector(
	selectAdminPostsListState,
	(state: AdminPostsState) => state && state.loading
);

export const selectAdminPostsListLoaded = createSelector(
	selectAdminPostsListState,
	(state: AdminPostsState) => state && state.loaded
);

export const selectAdminPostsListError = createSelector(
	selectAdminPostsListState,
	(state: AdminPostsState) => state && state.error
);

export const selectAdminPostsListPageNumber = createSelector(
	selectAdminPostsListState,
	(state: AdminPostsState) => state && state.pageNumber
);

export const selectAdminPostsListPagesCount = createSelector(
	selectAdminPostsListState,
	(state: AdminPostsState) => state && state.pagesCount
);

export const selectAdminPostsListTotalItemsCount = createSelector(
	selectAdminPostsListState,
	(state: AdminPostsState) => state && state.totalItemsCount
);

export const { selectAll: selectAdminPostsList } = adminPostsAdapter.getSelectors(selectAdminPostsListState);
