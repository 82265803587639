<div class="feed-form-wrap">
	<form [formGroup]="feedForm">
		<div class="feed-form-item">
			<div class="form-field-wrap">
				<div class="form-field-name">{{ 'admin.faq.form.fields.title' | translate }}</div>
				<mat-form-field appearance="outline" class="form-field">
					<mat-label>{{ 'admin.faq.form.labels.title' | translate }}</mat-label>
					<input formControlName="title" matInput />
					<mat-error>{{ 'required' | translate }}</mat-error>
				</mat-form-field>
			</div>
			<div class="form-field-wrap description-editor">
				<div class="form-field-name">{{ 'admin.faq.form.fields.description' | translate }}</div>
				<angular-editor [config]="editorConfig" formControlName="description"></angular-editor>
			</div>

			<div class="form-field-wrap">
				<div class="form-field-name">{{ 'admin.faq.form.fields.images' | translate }}</div>
				<div class="preview-images-list">
					<div *ngFor="let img of selectedImages; let i = index" class="preview-item">
						<img [src]="postGraphicURI + img" alt="" />
						<div class="preview-item-close">
							<button (click)="removeFile(fileType.Image, i)" class="close-modal" mat-flat-button>
								<mat-icon>close</mat-icon>
							</button>
						</div>
					</div>
					<div (click)="uploadImage.click()" class="add-image add-block">
						<mat-icon>add_box</mat-icon>
					</div>
					<div class="preview-item-loader">
						<app-waiting-spinner *ngIf="imageLoading" [onlySpinner]="true"></app-waiting-spinner>
					</div>
				</div>
			</div>

			<div class="form-field-wrap">
				<div class="form-field-name">{{ 'admin.faq.form.fields.videos' | translate }}</div>
				<div class="preview-videos-list">
					<div *ngFor="let video of selectedVideos; let i = index" class="preview-item">
						<video controls disablePictureInPicture>
							<source [src]="postGraphicURI + video" />
						</video>
						<div class="preview-item-close">
							<button (click)="removeFile(fileType.Video, i)" class="close-modal" mat-flat-button>
								<mat-icon>close</mat-icon>
							</button>
						</div>
					</div>
					<div (click)="uploadVideo.click()" *ngIf="!videoLoading" class="add-video add-block">
						<mat-icon>add_box</mat-icon>
					</div>
					<div class="preview-item-loader">
						<app-waiting-spinner
							*ngIf="videoLoading"
							[onlySpinner]="true"
							[percentDone]="percentDone"
						></app-waiting-spinner>
					</div>
				</div>
			</div>

			<div class="form-field-wrap">
				<div class="form-field-name">
					{{ 'admin.faq.form.fields.category' | translate }}
					<mat-icon (click)="editCategories()" class="edit-btn" matTooltip="Edit categories" matTooltipPosition="right"
						>mode_edit
					</mat-icon>
				</div>
				<mat-form-field appearance="outline" class="form-field">
					<mat-label>{{ 'admin.faq.form.labels.category' | translate }}</mat-label>
					<mat-select
						(selectionChange)="changeCategory($event)"
						disableOptionCentering
						formControlName="categoryId"
						panelClass="form-select"
					>
						<mat-option *ngFor="let category of categoriesList" [value]="category.id">
							{{ category.title }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="form-field-wrap">
				<div class="form-field-name">
					{{ 'admin.faq.form.fields.subcategory' | translate }}
					<mat-icon
						(click)="editSubcategories()"
						class="edit-btn"
						matTooltip="Edit subcategories"
						matTooltipPosition="right"
						>mode_edit
					</mat-icon>
				</div>
				<mat-form-field appearance="outline" class="form-field">
					<mat-label>{{ 'admin.faq.form.labels.subcategory' | translate }}</mat-label>
					<mat-select disableOptionCentering formControlName="subcategoryId" panelClass="form-select">
						<mat-option *ngFor="let subcategory of subcategories" [value]="subcategory.id">
							{{ subcategory.title }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="form-field-wrap">
				<div class="form-field-name">{{ 'admin.faq.form.fields.keywords' | translate }}</div>
				<mat-form-field appearance="outline" class="form-field">
					<mat-label>{{ 'admin.faq.form.labels.keywords' | translate }}</mat-label>
					<mat-chip-list #chipList aria-label="Keywords selection" formControlName="keywords">
						<mat-chip
							(removed)="remove(name)"
							*ngFor="let name of feedForm.get('keywords').value"
							color="primary"
							selectable
							removable
							selected
						>
							{{ name }}
							<mat-icon matChipRemove>cancel</mat-icon>
						</mat-chip>
						<input
							(matChipInputTokenEnd)="add($event)"
							[matChipInputAddOnBlur]="addOnBlur"
							[matChipInputFor]="chipList"
							[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
							matInput
						/>
					</mat-chip-list>
				</mat-form-field>
			</div>

			<div class="form-field-wrap" *ngIf="mode === 'add'">
				<div class="form-field-name">{{ 'admin.faq.form.fields.sendNotificationHeader' | translate }}</div>
				<mat-checkbox color="primary" class="notification-item" formControlName="sendNotification">
					{{ 'admin.faq.form.fields.sendNotificationsDescription' | translate }}
				</mat-checkbox>
			</div>

			<div class="feed-form-footer">
				<button [routerLink]="['admin', 'faq'] | routerLink" class="large" mat-stroked-button type="button">
					{{ 'admin.faq.form.cancel' | translate }}
				</button>
				<button (click)="onSubmit()" class="large" color="primary" mat-raised-button type="submit">
					{{ 'admin.faq.form.submit' | translate }}
				</button>
			</div>
		</div>
	</form>
</div>

<input #uploadImage (change)="onFileSelected(uploadImage.files, fileType.Image)" hidden multiple type="file" />
<input #uploadVideo (change)="onFileSelected(uploadVideo.files, fileType.Video)" hidden type="file" />
