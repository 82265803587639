import { Action, createReducer, on } from '@ngrx/store';
import { CompanyInterface } from '../../interfaces/company.interface';
import { loadCompany, loadCompanyFail, loadCompanySuccess } from '../actions/company.actions';

export interface CompanyState {
	data: CompanyInterface;
	loading: boolean;
	loaded: boolean;
	error: Error;
}

export const initialState: CompanyState = {
	data: null,
	loading: false,
	loaded: false,
	error: null,
};

const reducer = createReducer(
	initialState,

	on(loadCompany, (state) => ({
		...state,
		loading: true,
		loaded: false,
		error: null,
	})),

	on(loadCompanySuccess, (state, action) => ({
		...state,
		data: action.payload,
		loading: false,
		loaded: true,
		error: null,
	})),

	on(loadCompanyFail, (state, { error }) => ({
		...state,
		loading: false,
		loaded: false,
		error,
	}))
);

export function companyReducer(state: CompanyState | undefined, action: Action) {
	return reducer(state, action);
}
