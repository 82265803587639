import { Directive, ElementRef, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { CustomizationKeys } from '../../modules/admin/enums/customization-keys.enum';
import { CompanyHelperService } from '../../modules/company/services/company-helper.service';

@Directive({ selector: '[appHeadingColor]' })
export class HeadingColorDirective implements OnInit {
	constructor(private el: ElementRef, private companyHelperService: CompanyHelperService) {}

	ngOnInit() {
		this.companyHelperService.companyData$.pipe(take(1)).subscribe((company) => {
			if (!company || !company.styles || !company.styles[CustomizationKeys.HeadingTextColor]) {
				return;
			}
			this.el.nativeElement.style.color = company.styles[CustomizationKeys.HeadingTextColor];
		});
	}
}
