import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-single-video',
	templateUrl: 'single-video.component.html',
	styleUrls: ['single-video.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleVideoComponent {
	@Input()
	video: string;

	@Output()
	openMedia = new EventEmitter<{ type: string; src: string }>();

	isPlayed: boolean;
}
