import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BankIdCompletionData } from '@auth/interfaces/bankid-completion-data.interface';
import { ForgotPasswordInitializeDto } from '@auth/interfaces/forgot-password.interface';
import { NotificationService } from '@core/services/notification.service';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BankidAuthBase } from '@shared/components/bankid-login/bankid-auth-base';
import { responseErrorsDictionary } from '@shared/dictionaries/response-errors.dictionary';
import { NavigationService } from '@shared/services/navigation.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CustomizationKeys } from '../../../modules/admin/enums/customization-keys.enum';
import { CompanyInterface } from '../../../modules/company/interfaces/company.interface';
import { selectCompany, selectCompanyLoading } from '../../../modules/company/store/selectors/company.selectors';
import { LoginCredentials } from '../../interfaces/login-credentials.interface';
import { AuthApiService } from '../../services/auth-api.service';
import { State } from '../../store';
import { bankIdLogin, signIn } from '../../store/actions/auth.actions';
import { selectAuthError, selectAuthLoading } from '../../store/selectors/auth.selectors';

@Component({
	selector: 'app-login',
	templateUrl: './login-page.component.html',
	styleUrls: ['./login-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent extends BankidAuthBase implements OnDestroy {
	readonly selectedCompany$: Observable<CompanyInterface>;
	readonly selectedCompanyLoading$: Observable<boolean>;
	readonly loading$: Observable<boolean>;
	readonly errors$: Observable<any>;
	readonly errorMessages$ = new BehaviorSubject<any[]>([]);
	destroy$ = new Subject();
	customizationKeys = CustomizationKeys;
	loginMethod: 'password' | 'qrcode' = null;
	count = 0;

	constructor(
		private store: Store<State>,
		protected authService: AuthApiService,
		private navigationService: NavigationService,
		protected sanitizer: DomSanitizer,
		private translate: TranslateService,
		private notificationService: NotificationService
	) {
		super(authService, sanitizer);
		this.selectedCompany$ = this.store.pipe(select(selectCompany));
		this.selectedCompanyLoading$ = this.store.pipe(select(selectCompanyLoading));
		this.loading$ = this.store.pipe(select(selectAuthLoading));
		this.errors$ = this.store.pipe(select(selectAuthError));
	}

	onSuccessStatus(companyId, result) {
		if (result.completionData) {
			this.onBankIdLogin(this.companyId, result.orderRef, '/home', result.completionData);
			this.onCancel();
		} else {
			this.onBankIdLogin(companyId, result.orderRef);
		}
	}

	onCancel() {
		this.onCancelCommon();
	}

	onSignIn(credentials: LoginCredentials): void {
		this.store.dispatch(signIn({ data: credentials }));

		this.store.subscribe((state) => {
			if (state.auth.error) {
				const { error } = state.auth.error;

				this.errorMessages$.next([{ description: this.translate.instant(responseErrorsDictionary.get(error)) }]);

				if (error === 'PasswordExpired') {
					this.navigationService.navigateTo(['auth', 'expired-password', credentials.email]);
				}
				return;
			}
		});
	}

	sendEmailToResetPassword(body: ForgotPasswordInitializeDto): void {
		this.authService.sendEmailToResetPassword(body).subscribe(
			() => {
				this.notificationService.success({
					message: this.translate.instant('login.forgotPasswordRequestSentSuccessfully'),
				});
			},
			(response: HttpErrorResponse) => {
				this.notificationService.error({
					message: this.translate.instant(responseErrorsDictionary.get(response.error.Code)),
				});
			}
		);
	}

	onBankIdLogin(
		companyId: number,
		orderRef: string,
		redirectUrl?: string,
		completionData: BankIdCompletionData = null
	) {
		this.store.dispatch(bankIdLogin({ companyId, orderRef, redirectUrl, completionData }));
	}

	selectLoginMethod(value: 'password' | 'qrcode'): void {
		this.loginMethod = value;
	}

	goBack(): void {
		this.loginMethod = null;
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.errorMessages$.complete();
		this.qrCodeErrorCode$.complete();
	}
}
