import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'app-waiting-spinner',
	templateUrl: './waiting-spinner.component.html',
	styleUrls: ['./waiting-spinner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WaitingSpinnerComponent {
	@Input()
	onlySpinner = false;

	@Input()
	percentDone: string;
}
