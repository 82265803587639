import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PostSubcategory } from '../../interfaces/post-subcategory.interface';
import { FaqApiService } from '../../services/faq-api.service';
import {
	deleteAdminSubcategory,
	deleteAdminSubcategoryFail,
	deleteAdminSubcategorySuccess,
	loadAdminSubcategoriesList,
	loadAdminSubcategoriesListFail,
	loadAdminSubcategoriesListSuccess,
	upsertAdminSubcategory,
	upsertAdminSubcategoryFail,
	upsertAdminSubcategorySuccess,
} from '../actions/post-subcategories.actions';

@Injectable()
export class AdminSubcategoriesEffects {
	readonly getAdminSubcategories$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadAdminSubcategoriesList),
			switchMap(() =>
				this.faqApiService.getPostSubcategories().pipe(
					map((data: PostSubcategory[]) => loadAdminSubcategoriesListSuccess({ data })),
					catchError((error: Error) => of(loadAdminSubcategoriesListFail({ error })))
				)
			)
		)
	);

	readonly upsertAdminSubcategory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(upsertAdminSubcategory),
			switchMap((payload) =>
				this.faqApiService.upsertPostSubcategory(payload.data).pipe(
					map((data) => upsertAdminSubcategorySuccess({ data })),
					tap(() =>
						this.notificationService.success({
							message: this.translate.instant('notification_messages.subcategory.save'),
						})
					),
					catchError((error) => of(upsertAdminSubcategoryFail(error)))
				)
			)
		)
	);

	readonly deleteAdminSubcategory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(deleteAdminSubcategory),
			switchMap(({ id }) =>
				this.faqApiService.deletePostSubcategory(id).pipe(
					map(() => deleteAdminSubcategorySuccess({ id })),
					tap(() =>
						this.notificationService.success({
							message: this.translate.instant('notification_messages.subcategory.delete'),
						})
					),
					catchError((error) => of(deleteAdminSubcategoryFail(error)))
				)
			)
		)
	);

	constructor(
		private actions$: Actions,
		private faqApiService: FaqApiService,
		private notificationService: NotificationService,
		private translate: TranslateService
	) {}
}
