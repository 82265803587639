import { DictionaryBase } from '@core/dictionaries/dictionary-base';
import { DocumentTypes } from '../enums/document-types.enum';

export class DocumentTypesDictionary extends DictionaryBase<DocumentTypes> {
	public readonly entries = new Map<DocumentTypes, string>([
		[DocumentTypes.O, 'document.list.selfBill'],
		[DocumentTypes.L, 'document.list.deliverySelfBill'],
		[DocumentTypes.K, 'document.list.invoice'],
		[DocumentTypes.M, 'document.list.misc'],
		[DocumentTypes.A, 'document.list.annualreport'],
	]);
}
