import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Post } from '../../interfaces/post.interface';
import {
	deletePost,
	deletePostFail,
	deletePostSuccess,
	loadAdminPostsList,
	loadAdminPostsListFail,
	loadAdminPostsListSuccess,
	upsertPost,
	upsertPostFail,
	upsertPostSuccess,
} from '../actions/admin-posts.actions';

export interface AdminPostsState extends EntityState<Post> {
	loading: boolean;
	loaded: boolean;
	error: Error | string;
	pageNumber: number;
	pagesCount: number;
	totalItemsCount: number;
}

export const adminPostsAdapter: EntityAdapter<Post> = createEntityAdapter<Post>({
	selectId: (post: Post) => post.id || null,
});

const initialState: AdminPostsState = adminPostsAdapter.getInitialState({
	loading: false,
	loaded: false,
	error: null,
	pageNumber: 0,
	pagesCount: 0,
	totalItemsCount: 0,
});

const reducer = createReducer(
	initialState,

	on(loadAdminPostsList, upsertPost, deletePost, (state) => ({
		...state,
		loading: true,
		loaded: false,
		error: null,
	})),

	on(loadAdminPostsListSuccess, (state, { data }) => {
		return adminPostsAdapter.setAll(data.items || [], {
			...state,
			pageNumber: data.pageNumber,
			pagesCount: data.pagesCount,
			totalItemsCount: data.totalItemsCount,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(upsertPostSuccess, (state, payload) => {
		return adminPostsAdapter.upsertOne(payload.data, {
			...state,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(deletePostSuccess, (state, payload) => {
		return adminPostsAdapter.removeOne(payload.id, {
			...state,
			loading: false,
			loaded: true,
			error: null,
		});
	}),

	on(loadAdminPostsListFail, upsertPostFail, deletePostFail, (state, { error }) => ({
		...state,
		loading: false,
		loaded: false,
		error,
	}))
);

export function adminPostsReducer(state: AdminPostsState | undefined, action: Action) {
	return reducer(state, action);
}
