<video #videoPlayer disablePictureInPicture preload="metadata">
	<!--  https://developer.apple.com/forums/thread/129377
			  By simply adding #t=0.001 at the end of the video file url, we are telling the browser to skip the first
        millisecond of the video. When you do this, even iOS Safari will preload and show that specific frame to the user.	-->
	<source [src]="[video + '#t=0.001']" />
</video>

<div class="video-actions">
	<mat-icon (click)="videoPlayer.play(); isPlayed = true" *ngIf="!isPlayed">play_circle</mat-icon>
	<mat-icon (click)="videoPlayer.pause(); isPlayed = false" *ngIf="isPlayed">pause</mat-icon>
	<mat-icon
		class="modal-video"
		(click)="openMedia.emit({ type: 'video', src: video }); videoPlayer.pause(); isPlayed = false"
		>launch
	</mat-icon>
</div>
