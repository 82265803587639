<div class="content-container content-container-bottom-padding">
	<ng-container *ngIf="(newPostLoading$ | async) === false; else showLoader">
		<app-post-form
			(createPost)="onCreatePost($event)"
			(updateCategories)="loadCategories()"
			(updatePost)="onUpdatePost($event)"
			(updateSubcategories)="loadSubcategories()"
			(uploadImage)="onUploadImage($event)"
			(uploadVideo)="onUploadVideo($event)"
			[categoriesList]="categoriesList$ | async"
			[data]="postData"
			[imageLoading]="imageLoading$ | async"
			[loading]="loading$ | async"
			[mode]="mode"
			[percentDone]="percentDone$ | async"
			[selectedImages]="selectedImages$ | async"
			[selectedVideos]="selectedVideos$ | async"
			[subcategoriesList]="subcategoriesList$ | async"
			[videoLoading]="videoLoading$ | async"
		></app-post-form>
	</ng-container>
	<ng-template #showLoader>
		<app-waiting-spinner></app-waiting-spinner>
	</ng-template>
</div>
