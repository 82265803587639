import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

export const oAuthConfig: AuthConfig = {
	clientId: environment.identityClientId,
	scope: environment.identityScope,
	oidc: false,
	issuer: environment.identityURI,
	requireHttps: true,
};

/**
 * angular-oauth2-oidc configuration.
 */
@Injectable()
export class OAuthConfig {
	constructor(private oAuthService: OAuthService) {}

	load(): Promise<object> {
		this.oAuthService.configure(oAuthConfig);
		const url = `${environment.identityURI}/.well-known/openid-configuration`;

		// Defines the storage.
		this.oAuthService.setStorage(localStorage);

		// Loads Discovery Document.
		return this.oAuthService.loadDiscoveryDocument(url);
	}
}
