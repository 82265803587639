import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpUtilities } from '@shared/utilities/http-utilities';
import { Observable } from 'rxjs';
import { CompanyInterface, CompanySettings } from '../interfaces/company.interface';

@Injectable({
	providedIn: 'root',
})
export class CompanyApiService {
	baseUrl = environment.baseURI;

	constructor(private http: HttpClient) {}

	getCompany(companyName: string): Observable<CompanyInterface> {
		const params = HttpUtilities.genParams({ nameUrl: companyName });

		return this.http.get<CompanyInterface>(`${this.baseUrl}/company/getbynameurl`, { params });
	}

	saveCustomization(styles: any): Observable<CompanyInterface> {
		return this.http.post<CompanyInterface>(`${this.baseUrl}/company/setstyles`, styles);
	}

	saveSettings(settings: CompanySettings): Observable<CompanyInterface> {
		return this.http.post<CompanyInterface>(`${this.baseUrl}/company/setsettings`, settings);
	}
}
