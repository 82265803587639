import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { User } from '../../interfaces/user.interface';
import { AdminApiService } from '../../services/admin-api.service';
import { loadUsersList, loadUsersListFail, loadUsersListSuccess } from '../actions/admin.actions';

@Injectable()
export class AdminEffects {
	getUsers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(loadUsersList),
			mergeMap(() =>
				this.adminService.getUsers().pipe(
					map((users: User[]) => loadUsersListSuccess({ payload: users })),
					catchError((error) => of(loadUsersListFail({ error })))
				)
			)
		)
	);

	constructor(private actions$: Actions, private adminService: AdminApiService) {}
}
