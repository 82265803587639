import { ChangeDetectionStrategy, Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface ConfirmationModal {
	title: string;
	confirmation: string | string[];
	confirmationQuestion?: string;
	confirmButtonText: string;
	cancelButtonText: string;
}

@Component({
	selector: 'app-confirmation-modal',
	templateUrl: 'confirmation-modal.component.html',
	styleUrls: ['confirmation-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent implements OnInit {
	modalContent: ConfirmationModal = {
		title: this.translate.instant('confirmationModal.default.title'),
		confirmation: this.translate.instant('confirmationModal.default.confirmation'),
		confirmButtonText: this.translate.instant('confirmationModal.default.confirmButtonText'),
		cancelButtonText: this.translate.instant('confirmationModal.default.cancelButtonText'),
	};

	constructor(
		private dialogRef: MatDialogRef<ConfirmationModalComponent>,
		@Optional()
		@Inject(MAT_DIALOG_DATA)
		public data: Partial<ConfirmationModal>,
		private translate: TranslateService
	) {}

	ngOnInit(): void {
		this.modalContent = { ...this.modalContent, ...this.data };

		if (!Array.isArray(this.modalContent.confirmation)) {
			this.modalContent = { ...this.modalContent, confirmation: [this.modalContent.confirmation] };
		}
	}

	onCancel(): void {
		this.dialogRef.close(false);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	onConfirm(): void {
		this.dialogRef.close(true);
	}
}
