import { Color } from '@angular-material-components/color-picker';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ColorsService } from '@shared/services/colors.service';
import * as moment from 'moment';
import { CompanyInterface } from '../../../company/interfaces/company.interface';
import { CustomizationKeys } from '../../enums/customization-keys.enum';
import { CustomizationPropertyType } from '../../enums/customization-property-type.enum';
import { SettingsGroup } from '../../enums/settings-group.enum';
import { CustomizationProperty } from '../../interfaces/customization-property.interface';
import { GroupedProperties } from '../../interfaces/grouped-properties.interface';
import { OperationType } from '../../interfaces/operation-type.interface';
import { ImageFileFormatValidator } from '@shared/validators/image-file-format.validator';
import { VideoFileFormatValidator } from '@shared/validators/video-file-format.validator';

const DATE_FORMAT = {
	parse: {
		dateInput: 'YYYY-MM-DD',
	},
	display: {
		dateInput: 'YYYY-MM-DD',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'YYYY-MM-DD',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'app-customization-property',
	templateUrl: './customization-property.component.html',
	styleUrls: ['./customization-property.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},

		{ provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
	],
})
export class CustomizationPropertyComponent implements OnChanges {
	@Input()
	selectedCompany: CompanyInterface;

	@Input()
	operationTypes: OperationType[];

	@Output()
	saveStyles = new EventEmitter();

	customizationKeys = CustomizationKeys;
	customizationForm: FormGroup;
	groupedProperties: GroupedProperties;
	clearingNumberValues: number[] = [4, 5];
	customizationProperties: CustomizationProperty[] = [
		{
			group: SettingsGroup.Header,
			name: CustomizationKeys.HeaderBgColor,
			type: CustomizationPropertyType.BgColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Header,
			name: CustomizationKeys.HeaderTextColor,
			type: CustomizationPropertyType.TextColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Header,
			name: CustomizationKeys.HeaderLogo,
			type: CustomizationPropertyType.Logo,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Header,
			name: CustomizationKeys.HeaderNotificationNewContractCreatedIconColor,
			type: CustomizationPropertyType.IconColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Header,
			name: CustomizationKeys.HeaderNotificationNewContractStartedIconColor,
			type: CustomizationPropertyType.IconColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Header,
			name: CustomizationKeys.HeaderNotificationNewContractFileAddedIconColor,
			type: CustomizationPropertyType.IconColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Header,
			name: CustomizationKeys.HeaderNotificationNewAvrFileAddedIconColor,
			type: CustomizationPropertyType.IconColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Header,
			name: CustomizationKeys.HeaderNotificationNewPostAddedIconColor,
			type: CustomizationPropertyType.IconColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Header,
			name: CustomizationKeys.HeaderNoNotificationsIconColor,
			type: CustomizationPropertyType.IconColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Footer,
			name: CustomizationKeys.FooterBgColor,
			type: CustomizationPropertyType.BgColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Footer,
			name: CustomizationKeys.FooterTextColor,
			type: CustomizationPropertyType.TextColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.LoginPage,
			name: CustomizationKeys.LoginPageLogo,
			type: CustomizationPropertyType.Logo,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.LoginPage,
			name: CustomizationKeys.LoginPageBg,
			type: CustomizationPropertyType.Image,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.HomePage,
			name: CustomizationKeys.HomepageBg,
			type: CustomizationPropertyType.Video,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.HomePage,
			name: CustomizationKeys.DocumentIcon,
			type: CustomizationPropertyType.Icon,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.HomePage,
			name: CustomizationKeys.ContractIcon,
			type: CustomizationPropertyType.Icon,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.HomePage,
			name: CustomizationKeys.PurchaserIcon,
			type: CustomizationPropertyType.Icon,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.HomePage,
			name: CustomizationKeys.EstateIcon,
			type: CustomizationPropertyType.Icon,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.ContractPage,
			name: CustomizationKeys.NotStartedStatusColor,
			type: CustomizationPropertyType.BgColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.ContractPage,
			name: CustomizationKeys.CompletedStatusColor,
			type: CustomizationPropertyType.BgColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.ContractPage,
			name: CustomizationKeys.ChartColorFirst,
			type: CustomizationPropertyType.ChartColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.ContractPage,
			name: CustomizationKeys.ChartColorSecond,
			type: CustomizationPropertyType.ChartColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.ContractPage,
			name: CustomizationKeys.ChartColorThird,
			type: CustomizationPropertyType.ChartColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.DetailsPage,
			name: CustomizationKeys.DetailsPageIconsColor,
			type: CustomizationPropertyType.IconColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Additional,
			name: CustomizationKeys.SupportEmail,
			type: CustomizationPropertyType.Text,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Additional,
			name: CustomizationKeys.WebsiteTitle,
			type: CustomizationPropertyType.Text,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Additional,
			name: CustomizationKeys.HeadingTextColor,
			type: CustomizationPropertyType.TextColor,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Additional,
			name: CustomizationKeys.TaCodesToExcludeFromAdditions,
			type: CustomizationPropertyType.Selector,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Additional,
			name: CustomizationKeys.ClearingNumberFormat,
			type: CustomizationPropertyType.Selector,
			formCtrl: this.fb.control(null),
		},
		{
			group: SettingsGroup.Additional,
			name: CustomizationKeys.ContractCutoffDate,
			type: CustomizationPropertyType.Date,
			formCtrl: this.fb.control(moment()),
		},
		{
			group: SettingsGroup.Additional,
			name: CustomizationKeys.Favicon,
			type: CustomizationPropertyType.Icon,
			formCtrl: this.fb.control(null),
		},
	];

	imageFileFormatValidator = ImageFileFormatValidator;
	videoFileFormatValidator = VideoFileFormatValidator;

	constructor(private fb: FormBuilder, private colorsService: ColorsService) {}

	ngOnChanges(changes: SimpleChanges): void {
		const { selectedCompany } = changes;

		if (selectedCompany?.currentValue) {
			this.customizationForm = this.createCustomizationForm();
			const companyParams = { ...this.selectedCompany.styles, ...this.selectedCompany.settings };
			const companyParamsWithRGBColors = this.mapColorsToRGB(companyParams);
			this.customizationForm.patchValue(companyParamsWithRGBColors, { onlySelf: true, emitEvent: true });

			if (this.selectedCompany.settings.contractCutoffDate) {
				const date = moment(this.selectedCompany.settings.contractCutoffDate.split('T')[0], 'YYYY-MM-DD');
				this.customizationForm.patchValue({ contractCutoffDate: date });
			}

			if (!this.selectedCompany.settings.clearingNumberFormat) {
				this.customizationForm.patchValue({ clearingNumberFormat: this.clearingNumberValues[0] });
			}

			this.groupedProperties = this.customizationProperties.reduce((acc, item) => {
				(acc[item.group] = acc[item.group] || []).push(item);
				return acc;
			}, {}) as GroupedProperties;
		}
	}

	mapColorsToRGB(companyParams: any) {
		Object.keys(companyParams)
			.filter((key) => this.customizationProperties.find((prop) => prop.name === key && prop.type.includes('color')))
			.forEach((key) => {
				if (!companyParams[key]) {
					return;
				}
				const temp = this.colorsService.hexToRgb(companyParams[key]);
				companyParams[key] = new Color(temp.r, temp.g, temp.b);
			});
		return { ...companyParams };
	}

	createCustomizationForm() {
		return this.customizationProperties.reduce((form, property) => {
			form.addControl(property.name, property.formCtrl);
			return form;
		}, this.fb.group({}));
	}

	save(): void {
		const data = this.customizationForm.getRawValue();
		this.saveStyles.emit(data);
	}
}
