import { createSelector } from '@ngrx/store';
import { selectAdminCategoriesListState } from '../index';
import { adminCategoriesAdapter, AdminCategoriesState } from '../reducers/admin-categories.reducer';

export const selectAdminCategoriesListLoading = createSelector(
	selectAdminCategoriesListState,
	(state: AdminCategoriesState) => state && state.loading
);

export const selectAdminCategoriesListLoaded = createSelector(
	selectAdminCategoriesListState,
	(state: AdminCategoriesState) => state && state.loaded
);

export const selectAdminCategoriesListError = createSelector(
	selectAdminCategoriesListState,
	(state: AdminCategoriesState) => state && state.error
);

export const { selectAll: selectAdminCategoriesList } =
	adminCategoriesAdapter.getSelectors(selectAdminCategoriesListState);
