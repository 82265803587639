<form [errorMessages]="errorMessages" [formGroup]="form" appFormValidation class="form" autocomplete="off">
	<div class="form-fields">
		<mat-form-field appearance="outline" class="form-field">
			<mat-icon class="form-icon prefix-icon" matPrefix>person</mat-icon>
			<mat-label>{{ 'registration.form.firstName' | translate }}</mat-label>
			<input
				formControlName="firstName"
				matInput
				(blur)="onBlur($event, 'firstName')"
				(input)="onInputChange($event, 'firstName')"
			/>
			<mat-error>{{ form | error : 'firstName' | translate }}</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" class="form-field">
			<mat-icon class="form-icon prefix-icon" matPrefix>person</mat-icon>
			<mat-label>{{ 'registration.form.lastName' | translate }}</mat-label>
			<input
				formControlName="lastName"
				matInput
				(blur)="onBlur($event, 'lastName')"
				(input)="onInputChange($event, 'lastName')"
			/>
			<mat-error>{{ form | error : 'lastName' | translate }}</mat-error>
		</mat-form-field>
		<mat-form-field appearance="outline" class="form-field form-field-full-width">
			<mat-icon class="form-icon prefix-icon" matPrefix>local_post_office</mat-icon>
			<mat-label>{{ 'registration.form.email' | translate }}</mat-label>
			<input formControlName="email" matInput autocomplete="off" oninput="this.value = this.value.toLowerCase()" />
			<mat-error>{{ form | error : 'email' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field
			appearance="outline"
			class="form-field"
			#passTooltip="matTooltip"
			(click)="passTooltip.toggle()"
			matTooltip="{{ 'registration.form.passwordHint' | translate }}"
			matTooltipPosition="above"
		>
			<mat-icon class="form-icon prefix-icon" matPrefix>lock</mat-icon>
			<mat-label>{{ 'registration.form.password' | translate }}</mat-label>
			<input [type]="hidePassword ? 'password' : 'text'" formControlName="password" matInput autocomplete="off" />
			<button
				(click)="hidePassword = !hidePassword"
				[attr.aria-label]="'Hide password'"
				[attr.aria-pressed]="hidePassword"
				mat-icon-button
				matSuffix
				type="button"
			>
				<mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
			</button>
			<mat-error>{{ form | error : 'password' | translate }}</mat-error>
		</mat-form-field>

		<mat-form-field appearance="outline" class="form-field">
			<mat-icon class="form-icon prefix-icon" matPrefix>lock</mat-icon>
			<mat-label>{{ 'registration.form.passwordConfirmation' | translate }}</mat-label>
			<input
				[type]="hideConfirmPassword ? 'password' : 'text'"
				formControlName="confirmPassword"
				matInput
				autocomplete="off"
			/>
			<button
				(click)="hideConfirmPassword = !hideConfirmPassword"
				[attr.aria-label]="'Hide password'"
				[attr.aria-pressed]="hideConfirmPassword"
				mat-icon-button
				matSuffix
				type="button"
			>
				<mat-icon>{{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
			</button>
			<mat-error>{{ form | error : 'confirmPassword' | translate }}</mat-error>
		</mat-form-field>
		<div class="terms">
			<mat-checkbox color="primary" (change)="checked = !checked"></mat-checkbox>
			<span
				>{{ 'registration.form.agreement' | translate }}&nbsp;
				<a (click)="openTerms()">{{ 'registration.form.terms' | translate }}</a></span
			>
		</div>
	</div>

	<div class="form-actions">
		<a [routerLink]="['auth', 'login'] | routerLink" class="large" mat-raised-button>
			<mat-icon>chevron_left</mat-icon>
			{{ 'login.back' | translate }}
		</a>
		<button
			(click)="submit()"
			[disabled]="form.invalid || form.pristine || !checked"
			class="large"
			color="primary"
			mat-raised-button
			type="button"
		>
			{{ 'registration.form.confirm' | translate }}
			<mat-icon>login</mat-icon>
		</button>
	</div>
</form>
